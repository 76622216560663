import React from "react";
import { colors } from 'assets/js/theme';

// reactstrap components
import {
    Collapse,
    Navbar,
    Nav,
    NavItem
} from "reactstrap";

import SearchInput from 'components/Navbars/NavbarItems/SearchInput'
import RegisterButton from 'components/Navbars/NavbarItems/PublicNavbar/RegisterButton'
import LoginButton from 'components/Navbars/NavbarItems/PublicNavbar/LoginButton'
import NavbarTranslate from "components/Navbars/NavbarItems/NavbarTranslate";

function PublicNavbar({ toggleTheme }) {
    const lightMode = localStorage.getItem("theme") === "light";
    const desktopView = window.innerWidth > 1300
    const [collapsed, setCollapsed] = React.useState(false);
    const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [rightFocus, setRightFocus] = React.useState(false);
    const [borderColor, setBorderColor] = React.useState('transparent')

    return (
        <>
            {collapsed ? (
                <div
                    id="bodyClick"
                    onClick={() => {
                        document.documentElement.classList.toggle("nav-open");
                        setCollapsed(false);
                    }}
                />
            ) : null}
            <Navbar className={"fixed-top " + navbarColor}
                expand={desktopView ? true : false}
                style={!lightMode ? {
                    backgroundColor: colors.darkGray,
                    borderBottom: `1px solid ${borderColor}`,
                } : {
                    backgroundColor: colors.lightGray,
                    borderBottom: `1px solid ${borderColor}`
                }} container='fluid'>
                <NavbarTranslate 
                    toggleTheme={toggleTheme} 
                    setNavbarColor={setNavbarColor} 
                    setBorderColor={setBorderColor} 
                    collapsed={collapsed} 
                    setCollapsed={setCollapsed} 
                />
                <Collapse isOpen={collapsed} navbar style={ desktopView ? 
                    {marginRight: "2vw", marginTop: 9} 
                : {}} className="justify-content-end">
                    <Nav navbar>
                        {collapsed || desktopView ?
                            <>
                                <NavItem>
                                    <SearchInput
                                        rightFocus={rightFocus} 
                                        setRightFocus={setRightFocus} 
                                        isHomePage={false}
                                    />
                                </NavItem>
                                <RegisterButton />
                                <LoginButton />
                            </>
                            :
                            <>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                            </>
                        }
                    </Nav>
                </Collapse>
            </Navbar>
        </>
    );
}

export default PublicNavbar;