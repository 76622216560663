import React from "react";
import { colors } from 'assets/js/theme';

// core components
import MainFooter from "components/Footers/MainFooter.js"
import PublicNavbar from "components/Navbars/PublicNavbar.js";
import UserNavbar from "components/Navbars/UserNavbar.js";
import ContactButtons from "components/Buttons/CourseButtons/ContactButtons";
import { UKFlag, AzFlag, GoogleMeet } from "components/SVG/Emojis";

// request components
import {
    getUser
} from "shared/request.js"

// sections for this page 
import {
    Card,
    CardImg,
    CardImgOverlay,
    CardTitle,
    CardText
} from "reactstrap"

function Course() {
    const [authorized, setAuthorized] = React.useState(false)
    const [done, setDone] = React.useState(false)
    React.useEffect(() => {
        window.scrollTo({
            top: -1,
            left: -1,
            behavior: 'smooth',
        });
        
        if (localStorage.getItem("theme") !== "dark" && localStorage.getItem("theme") !== "light") {
            localStorage.setItem("theme", "light")
        }
        const userGetter = async () => {
            await getUser()
                .then((result) => {
                    if (result.data.success) { setAuthorized(true); setDone(true) };
                }).catch((err) => {
                    console.log(err)
                    setAuthorized(false)
                    setDone(true)
                });
        }
        userGetter();
    }, [])
    
    const [lightMode, setLightMode] = React.useState(localStorage.getItem("theme") === "light")
    React.useEffect(() => {
        const bgColor = lightMode ? colors.white : colors.darkGray;
        document.documentElement.style.backgroundColor = bgColor;
    }, [lightMode]);

    const desktopView = window.innerWidth > 810
    const bgColor = lightMode ? colors.white : colors.darkGray
    const fontColor = lightMode ? colors.darkGray : colors.white
    const defFontSize = desktopView ? Math.min(window.innerWidth/15, 105) : 50;
    console.log(defFontSize)
    function toggleTheme() {
        const newTheme = lightMode ? "dark" : "light";
        localStorage.setItem("theme", newTheme);
        setLightMode(!lightMode);
    }
    return (
        <>
            {done ? (!authorized ? <PublicNavbar toggleTheme={toggleTheme} /> : <UserNavbar toggleTheme={toggleTheme} />) : <></>}
            <div className="wrapper animateme" style={{
                backgroundColor: bgColor,
            }}>
                <div className="main animateme" style={{
                    maxWidth: desktopView && window.innerWidth > 1500 ? "1500px" : "100vw",
                    marginLeft: desktopView && window.innerWidth > 1500 ? "calc(50vw - 750px)" : "0vw",
                    backgroundColor: bgColor,
                    paddingTop: desktopView ? "12vh" : "7vh"
                }}>
                    {desktopView ? <>
                        <h1 style={{
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            color: fontColor,
                            fontSize: defFontSize/1.5
                        }}
                            className={"animateme"}
                        >
                            Kiber Təhlükəsizlik Öyrən
                        </h1>
                        </> : <></>}
                    <br></br><br></br>
                    <div id={"left"}
                        style={{
                            width: desktopView ? "50%" : "80%",
                            paddingLeft: desktopView ? "5%" : 0,
                            marginLeft: desktopView ? 0 : "10%",
                            float: "left",
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            paddingRight: desktopView ? '5%' : ''
                        }}
                    >
                        <h2 className="animateme"
                            style={{ 
                                fontSize: defFontSize/3.2, 
                                // textAlign: desktopView ? '' : 'justify',
                                color: lightMode ? colors.darkGray : colors.white,
                                marginBottom: desktopView ? defFontSize/3 : ''
                            }}
                        >
                            Dövrün ən əhəmiyyətli sahəsini, <b>Kibertəhlükəsizliyi</b> öyrənmək istəyirsən? 🔎
                            <br></br><br></br>
                            Cəmi <b>3 ay</b> vaxtın var? 😱
                            <br></br><br></br>
                            Onda tam da <b>ünvanına</b> gəlmisən! 📍
                            <br></br><br></br>
                            Sənə ölkənin ən miqyaslı Kibertəhlükəsizlik yarışı - <b>CIDC-2023 qalibi</b> tərəfindən kibertəhlükəsizlik dərsləri təklif edirik! 🎉
                        </h2>
                        {desktopView ? 
                            <>
                                <hr className="animateme"
                                    style={{
                                        height: '5px',
                                        backgroundColor: fontColor,
                                        border: 'none'
                                    }}
                                />
                                <h1 className="animateme"
                                    style={{ 
                                        textAlign: desktopView ? '' : 'justify',
                                        color: fontColor,
                                        marginBottom: desktopView ? defFontSize/3 : '',
                                        fontSize: defFontSize/2
                                    }}
                                >
                                    <b>Maraqlıdır? 🤔</b>
                                </h1>
                                <h2 className="animateme"
                                    style={{ 
                                        fontSize: defFontSize/3.2, 
                                        textAlign: desktopView ? '' : 'justify',
                                        color: fontColor,
                                    }}
                                >
                                    Tədris dilləri: <AzFlag size={defFontSize/2} /> | <UKFlag size={defFontSize/2}/> 
                                    <br></br>
                                    Dərs formatı: online <GoogleMeet size={defFontSize/2}/>
                                    <br></br>
                                    Dərs tezliyi: həftədə 3 dəfə
                                </h2>
                                <ContactButtons /> 
                            </>:<></>}
                    </div>
                    <div id={"right"}
                        style={{
                            width: desktopView ? "50%" : "80%",
                            paddingRight: desktopView ? "5%" : 0,
                            marginRight: desktopView ? 0 : "10%",
                            float: "right",
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Card className="animateme" inverse style={{
                                width: '100%',
                                borderRadius: 20
                        }}>
                            <CardImg className="animateme"
                                alt="Mentor"
                                src={require("assets/img/profile/mentor.jpg")}
                                style={{borderRadius: 20}}
                            />
                            <CardImgOverlay className="animateme">
                                <CardTitle tag="h5" className="animateme" style={{
                                    backgroundColor: bgColor,
                                    color: fontColor,
                                    fontSize: defFontSize/3.2,
                                    borderRadius: 10,
                                    opacity: '85%',
                                    textAlign: 'center'
                                }}>
                                    İsmayıl Eyyub
                                </CardTitle>
                                <CardText className="animateme" style={{
                                    backgroundColor: bgColor,
                                    color: fontColor,
                                    fontSize: defFontSize/5,
                                    borderRadius: 10,
                                    opacity: '85%',
                                    textAlign: 'center',
                                    fontWeight: 500
                                }}>
                                    ISO 27001 Lead Auditor, OSCP, CIDC-2023 CTF Qalibi
                                </CardText>
                            </CardImgOverlay>
                        </Card>
                        {!desktopView ? 
                            <>
                                <h1 className="animateme"
                                    style={{ 
                                        // textAlign: desktopView ? '' : 'justify',
                                        color: fontColor,
                                        marginBottom: desktopView ? defFontSize/3 : '',
                                        fontSize: defFontSize/2
                                    }}
                                >
                                    <b>Maraqlıdır? 🤔</b>
                                </h1>
                                <h2 className="animateme"
                                    style={{ 
                                        fontSize: defFontSize/3.2, 
                                        // textAlign: desktopView ? '' : 'justify',
                                        color: fontColor,
                                    }}
                                >
                                    Tədris dilləri: <AzFlag size={defFontSize/2} /> | <UKFlag size={defFontSize/2}/> 
                                    <br></br>
                                    Dərs formatı: online <GoogleMeet size={defFontSize/2}/>
                                    <br></br>
                                    Dərs tezliyi: həftədə 3 dəfə
                                </h2>
                                <ContactButtons /> 
                            </>
                            :<></>}
                    </div>
                </div>
                <MainFooter style={{ position: "fixed", bottom: 0, maxHeight: "3vh", minHeight: "3vh" }} id="footer" />
            </div>
        </>
    );
}

export default Course;
