import React from "react";
import { useNavigate } from 'react-router-dom';


import { Button } from 'reactstrap';

function Key({ letter, term, mode }) {
    const navigate = useNavigate()
    const [lightMode, setLightMode] = React.useState(mode)
    const active = letter === term
    const [activeKeyClass, setActiveKeyClass] = React.useState(lightMode ? 'lActiveKey' : 'dActiveKey')
    const [passiveKeyClass, setPassiveKeyClass] = React.useState(lightMode ? 'lPassiveKey' : 'dPassiveKey')
    if (lightMode !== mode) {
        setLightMode(mode)
        setActiveKeyClass(mode ? 'lActiveKey' : 'dActiveKey')
        setPassiveKeyClass(mode ? 'lPassiveKey' : 'dPassiveKey')
    }

    return (
        <Button type="button"
            style={{
                margin: "auto",
                fontWeight: "bold",
                padding: "11px 11px",
                fontSize: 20,
                width: "13.5%"
            }}
            className={`animateme ${active ? activeKeyClass : passiveKeyClass}`}
            onClick={() => {
                navigate(`/search/?query=${letter}`)
                window.location.reload();
                window.scrollTo({
                    top: -1,
                    left: -1,
                    behavior: 'smooth',
                })
            }}
        >{letter}</Button>
    )
}

export default Key;