import React from 'react';
import { colors } from 'assets/js/theme';
import ReactDiffViewer from 'react-diff-viewer';

// core components
import MainFooter from "components/Footers/MainFooter.js"
import AdminCard from "components/Cards/Profile/AdminCard"
import CardHeaderComponent from "components/Cards/CardHeaderComponent.js"
import Identicon from "components/SVG/Identicon"
import Loading from "components/SVG/Loading"
import ArticleFullCard from 'components/Cards/ArticleFullCard';
import PaginationTable from "components/Pagination/PaginationTable";
import MobileHeader from "components/Profiles/ProfileItems/AdminProfile/MobileHeader";
import ChangePasswordForm from "components/Forms/ChangePasswordForm.js"

// backend components
import {
    getUser,
    getSuggestions,
    getUpdates,
    approveSuggestion,
    declineSuggestion,
    approveUpdate,
    declineUpdate,
    getArticles,
    getNumberOfUsers
} from "shared/request"

// sections for this page
import {
    Button,
    Card,
    CardBody,
    TabContent,
    TabPane,
    Alert,
    Container
} from "reactstrap";

export default function AdminProfile() {
    const [done, setDone] = React.useState(false)
    const desktopView = window.innerWidth > 810
    const [page, setPage] = React.useState(1)
    const [page1, setPage1] = React.useState(1)
    const [suggestions, setSuggestions] = React.useState([])
    const [updates, setUpdates] = React.useState([])
    const [updatesPageAmount, setUpdatesPageAmount] = React.useState(1)
    const [suggestionsPageAmount, setSuggestionsPageAmount] = React.useState(1)
    const [username, setUsername] = React.useState('')
    const [height, setHeight] = React.useState(0)
    const curUpdates = updates.slice((page - 1) * 10, page * 10)
    const curSuggestions = suggestions.slice((page1 - 1) * 10, page1 * 10)
    const [mode, setMode] = React.useState("suggested")
    const [term, setTerm] = React.useState('')
    const [definition, setDefinition] = React.useState('');
    const [pubDate, setPubDate] = React.useState(null);
    const [author, setAuthor] = React.useState('')
    const [users, setUsers] = React.useState({})
    const [articles, setArticles] = React.useState({})

    React.useEffect(() => {
        window.scrollTo({
            top: -1,
            left: -1,
            behavior: 'smooth',
        })
        const process = async () => {
            await getArticles().then(res => setArticles(res.data.result))
            await getNumberOfUsers().then(res => setUsers(res.data.result))
            await getSuggestions().then(res => {
                var suggestions = res.data.result
                suggestions.forEach((suggestion) => {
                    suggestion["status"] = "suggested"
                })
                setSuggestions(suggestions)
                setSuggestionsPageAmount(Math.floor(suggestions.length / 11) + 1)
            })
            await getUpdates().then(res => {
                var updates = res.data.result
                updates.forEach(update => {
                    update["status"] = "updated"
                })
                setUpdates(updates)
                setUpdatesPageAmount(Math.floor(updates.length / 11) + 1)
            })
            setDone(true);
        }
        const userGetter = async () => {
            await getUser()
                .then((res) => {
                    setUsername(res.data.result.username)
                })
        }
        process();
        userGetter();
    }, [])

    React.useEffect((deps = [mode]) => {
        var totalHeight = 50
        let cardBody = document.getElementsByClassName('card-body')[0]
        if (cardBody)
            totalHeight += cardBody.clientHeight
        setHeight(totalHeight)
    })



    if (localStorage.getItem("theme") !== "dark" && localStorage.getItem("theme") !== "light") {
        localStorage.setItem("theme", "light")
    }

    const defWidth = "80%"
    const defMargin = "10%"
    const lightMode = localStorage.getItem("theme") === "light"
    const borderColor = lightMode ? colors.darkGray : colors.lightGray
    const bgColor = lightMode ? colors.white : colors.darkGray
    const fontColor = lightMode ? colors.darkGray : colors.white
    const defFontSize = desktopView ? 70 : 50;
    const cardBgColor = lightMode ? colors.lightGray : colors.darkGray
    const newStyles = {
        variables: {
            dark: {
                diffViewerColor: colors.white,
                diffViewerBackground: colors.darkGray,
                gutterColor: colors.white,
                gutterBackground: colors.darkGray,
                gutterBackgroundDark: '#222627',
                removedColor: colors.white,
                removedBackground: "#60393d",
                removedGutterColor: colors.white,
                removedGutterBackground: '#402629',
                wordRemovedBackground: '#7D373F',
                addedColor: colors.white,
                addedBackground: '#056105',
                addedGutterColor: colors.white,
                addedGutterBackground: '#044904',
                wordAddedBackground: '#067906',
                emptyLineBackground: '#222627',
            },
            light: {
                diffViewerBackground: colors.lightGray,
                emptyLineBackground: colors.lightGray,
            },
        },
        line: {
            padding: '10px 2px',
        },
        wordAdded: {
            transition: 'background 0.3s ease !important'
        },
        wordRemoved: {
            transition: 'background 0.3s ease !important'
        }
    };

    const [curMode, setCurMode] = React.useState(false)
    const [Old, setOld] = React.useState('')
    const [New, setNew] = React.useState('')
    const [id, setTheId] = React.useState('')
    const [success, setSuccess] = React.useState(false)
    const [successText, setSuccessText] = React.useState('')
    const [error, setError] = React.useState(false)
    const [errorText, setErrorText] = React.useState('')

    function setTheOld(text) {
        setOld(text)
    }

    function setTheNew(text) {
        setNew(text)
    }

    function setId(id) {
        setTheId(id)
    }

    const [show, setShow] = React.useState(false)

    var addRule = (function (style) {
        var sheet = document.head.appendChild(style).sheet;
        return function (selector, css) {
            var propText = typeof css === "string" ? css : Object.keys(css).map(function (p) {
                return p + ":" + (p === "content" ? "'" + css[p] + "'" : css[p]);
            }).join(";");
            sheet.insertRule(selector + "{" + propText + "}", sheet.cssRules.length);
        };
    })(document.createElement("style"));

    addRule('*', {
        transition: 'all 0.3s ease 0s !important'
    })
    addRule('span', {
        transition: 'none !important'
    })
    function setThePage(pagenum) {
        setPage(pagenum)
    }

    function setThePage1(pagenum) {
        setPage1(pagenum)
    }

    function setTheMode(mode) {
        setMode(mode)
    }

    function succeeded(text) {
        setError(false)
        setSuccess(true)
        setSuccessText(text)
        window.scrollTo({
            top: -1,
            left: -1,
            behavior: 'smooth',
        })
        setTimeout(() => {
            setSuccess(false)
            window.location.reload()
        }, 2000)
    }

    function failed(text) {
        setSuccess(false)
        setError(true)
        setErrorText(text)
        window.scrollTo({
            top: -1,
            left: -1,
            behavior: 'smooth',
        })
        setTimeout(() => {
            setError(false)
        }, 2000)
    }

    const errorHandler =  (error) => {
        if (error.response){
            let e = error.response.data.error
            if (e === 'Something went wrong') {
                failed("Xəta baş verdi!")
            }
            else if (e === 'jwt malformed' || e === 'Internal Server Error') {
                failed("Bunu etmək hüququnuz yoxdur!")
            }
            else if (e === "Suggestion with this ID does not exist") {
                failed("Bu təklif daha mövcud deyil!")
            }
        }
    }

    async function Aupdate() {
        await approveUpdate(id).then(res => {
            succeeded("Siz uğurla redaktəni təsdiq etdiniz!")
        }).catch(error => {errorHandler(error.response.data.error)})
    }

    async function Dupdate() {
        await declineUpdate(id).then(res => {
            succeeded("Siz uğurla redaktəni ləğv etdiniz!")
        }).catch(error => {errorHandler(error.response.data.error)})
    }

    async function Asuggestion() {
        await approveSuggestion(id).then(res => {
            succeeded("Siz uğurla yeni məqaləni təsdiq etdiniz!")
        }).catch(error => {errorHandler(error.response.data.error)})
    }

    async function Dsuggestion() {
        await declineSuggestion(id).then(res => {
            succeeded("Siz uğurla yeni məqaləni ləğv etdiniz!")
        }).catch(error => {errorHandler(error.response.data.error)})
    }


    return (
        <>
            <div className="wrapper animateme" style={{
                backgroundColor: bgColor,
            }}>
                <Alert color="success" isOpen={success && !error}
                    style={{
                        position: "absolute",
                        minWidth: desktopView ? 810 : "100%",
                        zIndex: 1040,
                        top: "0px",
                        textAlign: "center",
                        left: desktopView ? "calc(50vw - 405px)" : 0,
                        backgroundColor: "#28A745",
                        textAlign: "center"
                    }}>
                    <Container>
                        <div className="alert-icon" style={{
                            marginTop: desktopView ? "auto" : 0
                        }}>
                            <i className="now-ui-icons ui-2_like"></i>
                        </div>
                        {successText}
                        <button
                            type="button"
                            className="close" style={{ float: 'right', backgroundColor: 'transparent', border: 'none' }}
                            onClick={() => setSuccess(false)}
                        >
                            <span aria-hidden="true">
                                <i className="now-ui-icons ui-1_simple-remove"></i>
                            </span>
                        </button>
                    </Container>
                </Alert>
                <Alert color="danger" isOpen={error && !success} style={{
                    position: "absolute",
                    minWidth: desktopView ? 810 : "100%",
                    zIndex: 1040,
                    top: "0px",
                    textAlign: "center",
                    left: desktopView ? "calc(50vw - 405px)" : 0,
                    backgroundColor: "#DC3545",
                }} >
                    <Container>
                        <div className="alert-icon" style={{
                            marginTop: desktopView ? "auto" : 0
                        }}>
                            <i className="now-ui-icons ui-1_bell-53"></i>
                        </div>
                        {errorText}
                        <button
                            type="button"
                            className="close" style={{ float: 'right', backgroundColor: 'transparent', border: 'none' }}
                            onClick={() => setError(false)}
                        >
                            <span aria-hidden="true">
                                <i className="now-ui-icons ui-1_simple-remove"></i>
                            </span>
                        </button>
                    </Container>
                </Alert>
                <div className='animateme' id='cover' style={{
                    height: show ? "calc(100vh - 100px)" : 0,
                    overflow: 'hidden',
                    width: "90vw",
                    position: 'fixed',
                    top: "80px",
                    left: '5vw',
                    backgroundColor: cardBgColor,
                    color: fontColor,
                    border: `${show ? '1' : '0'}px solid ${borderColor}`,
                    borderRadius: 30,
                    zIndex: "1040",
                    padding: show ? '15px' : 0,
                }}>
                    <div style={{ width: '100%', paddingBottom: 20 }}>
                        <Button style={{
                            marginLeft: 20,
                            marginRight: 20,
                            borderRadius: 30,
                            backgroundColor: fontColor,
                            padding: !desktopView ? '7px 17px' : '',
                            cursor: 'pointer'
                        }} className={'animateme'} onClick={() => setShow(false)}>
                            <i className={"fas fa-times button animateme"} style={{
                                color: bgColor,
                                cursor: 'pointer'
                            }} onClick={() => setShow(false)}></i>
                        </Button>
                        <Button
                            className="btn button success animateme"
                            color="success"
                            id='acceptBut'
                            outline
                            type="button"
                            style={{
                                borderRadius: 30,
                                marginRight: desktopView ? 20 : 7,
                                float: 'right',
                            }}
                            onClick={() => {
                                curMode ? Aupdate() : Asuggestion()
                                setShow(false)
                            }}
                        >
                            {desktopView ? 'Təsdiqlə' : '✔'}
                        </Button>
                        <Button
                            className="btn button danger animateme"
                            color="danger"
                            id='acceptBut'
                            outline
                            type="button"
                            style={{
                                borderRadius: 30,
                                marginRight: desktopView ? 20 : 7,
                                float: 'right',
                            }}
                            onClick={() => {
                                curMode ? Dupdate() : Dsuggestion()
                                setShow(false)
                            }}
                        >
                            {desktopView ? "Ləğv Et" : "X"}
                        </Button>
                    </div>
                    <div style={{ maxHeight: '75vh', overflowY: 'auto' }}>
                        {curMode ? <ReactDiffViewer oldValue={Old} newValue={New} 
                                        splitView={true} styles={newStyles} 
                                        useDarkTheme={lightMode ? false : true} /> :
                            <ArticleFullCard term={term} author={author} pubDate={pubDate} definition={definition} />}
                    </div>
                </div>
                <div className="main animateme" style={{
                    maxWidth: desktopView && window.innerWidth > 1250 ? "1250px" : "100vw",
                    marginLeft: desktopView && window.innerWidth > 1250 ? "calc(50vw - 625px)" : "0vw",
                    backgroundColor: bgColor,
                    minHeight: "100vh",
                    height: desktopView ? (updates.length < 2 && suggestions.length < 2 && height < window.innerHeight - 341 ? "100vh" :
                        height + 341 + "px") : (height > window.innerHeight - 750 ?
                            750 + height : "100vh"),
                    paddingTop: desktopView ? "76px" : "55px"
                }}>
                    <div id="left" className='animateme' style={{
                        width: desktopView ? "50%" : "100%",
                        float: "left"
                    }}>
                        {!done ? <Loading page='profile' /> :
                            <>
                                {done && !desktopView ?
                                    <MobileHeader
                                        username={username}
                                        users={users}
                                        articles={articles}
                                    />
                                    :
                                    <></>
                                }
                                <h3 style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    paddingTop: desktopView ? "3vh" : "0",
                                    color: fontColor,
                                    fontSize: defFontSize / 2,
                                    marginLeft: defMargin,
                                    overflow: "auto",
                                    maxWidth: defWidth,
                                    textAlign: desktopView ? "left" : "center"
                                }}
                                    className={"animateme"}
                                > {suggestions.length + updates.length > 0 ? `Aktiv təkliflər:` : `Təəssüf ki, heç bir təklif yoxdur!`}
                                </h3>
                                <Card className={"animateme"}
                                    style={{
                                        width: "80%",
                                        marginLeft: "10%",
                                        backgroundColor: lightMode ? colors.lightGray : colors.darkGray,
                                        boxShadow: "none",
                                        border: `1px solid ${borderColor}`,
                                        borderRadius: "25px"
                                    }}
                                    id={"card"}>
                                    <CardHeaderComponent
                                        page="profile"
                                        mode={mode}
                                        modes={["suggested", "updated"]}
                                        texts={["Təklif Edilmiş", "Redaktə Edilmiş"]}
                                        setMode={setTheMode}
                                    />
                                    <CardBody>
                                        <TabContent
                                            className="text-center animateme"
                                            activeTab={mode}
                                        >
                                            <TabPane tabId="suggested">
                                                {curSuggestions.map(result => 
                                                    <AdminCard article={result} mode={lightMode}
                                                        setShow={setShow}
                                                        setMode={setCurMode}
                                                        setOld={null} 
                                                        setNew={null}
                                                        setId={setId}
                                                        setAuthor={setAuthor}
                                                        setDefinition={setDefinition}
                                                        setPubDate={setPubDate}
                                                        setTerm={setTerm} 
                                                    />)}
                                                    <PaginationTable currentPage={page} 
                                                        setPage={setPage} 
                                                        totalPageCount={suggestionsPageAmount} 
                                                    />
                                            </TabPane>
                                            <TabPane tabId="updated">
                                                {curUpdates.map(result => 
                                                    <AdminCard article={result} mode={lightMode}
                                                        setShow={setShow}
                                                        setOld={setTheOld} 
                                                        setNew={setTheNew}
                                                        setMode={setCurMode}
                                                        setId={setId}
                                                        setAuthor={null}
                                                        setDefinition={null}
                                                        setPubDate={null}
                                                        setTerm={null} 
                                                    />)}
                                                    <PaginationTable currentPage={page1} 
                                                        setPage={setPage1} 
                                                        totalPageCount={updatesPageAmount} 
                                                    />
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </>
                        }
                    </div>
                    <div id="right" className='animateme' style={{
                        width: desktopView ? "50%" : "100%",
                        float: "right",
                        marginTop: "4vh"
                    }}>
                        {done ? desktopView ?
                            <>
                                <div style={{
                                    border: `1px solid ${borderColor}`,
                                    borderRadius: 30,
                                    backgroundColor: cardBgColor,
                                    width: "90%",
                                    marginLeft: "5%"
                                }} className="animateme">
                                    <div className="animateme"
                                        style={{
                                            backgroundColor: borderColor, 
                                            height: 220,
                                            borderRadius: "30px 30px 0px 0px",
                                        }}>
                                            <div
                                                style={{
                                                    width: "40%",
                                                    float: "left",
                                                    borderRadius: "30px 0px 0px 0px",
                                                    backgroundColor: borderColor,
                                                    height: 189,
                                                    paddingTop: 37
                                                }} className='animateme'>
                                                <Identicon username={username} />
                                            </div>
                                            <div
                                                style={{
                                                    width: "60%",
                                                    float: "right",
                                                    borderRadius: "0px 30px 0px 0px",
                                                    backgroundColor: borderColor
                                                }} className='animateme'>
                                                <h3
                                                    className={"animateme notme"}
                                                    style={{
                                                        color: bgColor,
                                                        textAlign: "center",
                                                        width: "100%",
                                                        marginBottom: "0px",
                                                        paddingTop: "15px",
                                                        marginTop: "0px",
                                                        paddingBottom: "15px",
                                                        textTransform: "uppercase",
                                                        fontFamily: 'Montserrat sans-serif',
                                                        fontWeight: 400
                                                    }}>
                                                        {username}
                                                </h3>
                                                <h4
                                                    className={"animateme"}
                                                    style={{
                                                        color: bgColor,
                                                        width: "80%",
                                                        margin: "0 0 20px 10%",
                                                        backgroundColor: borderColor,
                                                        paddingBottom: 10,
                                                        textAlign: "center",
                                                    }}>
                                                        {`Ümumi İstifadəçi: ${users.number_of_users}`} <br></br> 
                                                        {`Yeni İstifadəçi: ${users.number_of_today_registered}`} <br></br> 
                                                        {`Ümumi Məqalə: ${articles.number_of_articles}`} <br></br> 
                                                        {`Yeni Məqalə: ${articles.number_of_today_published}`}
                                                </h4>
                                            </div>
                                        </div>
                                    <div
                                        style={{
                                            width: "100%",
                                        }} className='animateme'>
                                        <h3
                                            className={"animateme"}
                                            style={{
                                                width: "100%",
                                                color: fontColor,
                                                marginBottom: 20,
                                                marginTop: 40,
                                                textAlign: "center"
                                            }}>Şifrəni Dəyiş
                                        </h3>
                                        <ChangePasswordForm user='ADMIN'/>
                                    </div>
                                </div>
                            </>
                            :
                            <div style={{
                                border: `1px solid ${borderColor}`,
                                borderRadius: "30px",
                                backgroundColor: cardBgColor,
                                width: "80%",
                                marginLeft: "10%",
                                paddingBottom: "10px",
                                marginBottom: 50
                            }} className="animateme">
                                <h3
                                    className={"animateme"}
                                    style={{
                                        color: fontColor,
                                        marginBottom: 20,
                                        marginTop: 20,
                                        textAlign: "center"
                                    }}>Şifrəni Dəyiş
                                </h3>
                                <ChangePasswordForm user='ADMIN'/>
                            </div> : <></>
                        }
                    </div>
                </div>
                <MainFooter style={{ position: "fixed", bottom: 0, height: "3vh" }} />
            </div>
        </>
    )
}