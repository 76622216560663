import React from "react";
import { colors } from 'assets/js/theme';

// backend components
import {
    getUser
} from "shared/request"

import SearchInput from 'components/Navbars/NavbarItems/SearchInput'
import AddArticleButton from 'components/Navbars/NavbarItems/UserNavbar/AddArticleButton'
import ProfileButton from 'components/Navbars/NavbarItems/UserNavbar/ProfileButton'
import CombinedButtons from 'components/Navbars/NavbarItems/UserNavbar/CombinedButtons'
import NavbarTranslate from "components/Navbars/NavbarItems/NavbarTranslate";

// reactstrap components
import {
    Collapse,
    Navbar,
    Nav,
    Alert,
    Container,
    NavItem
} from 'reactstrap';

function PublicNavbar({ toggleTheme }) {
    const lightMode = localStorage.getItem("theme") === "light";
    const desktopView = window.innerWidth > 1300
    const [username, setUsername] = React.useState('')
    const [collapsed, setCollapsed] = React.useState(false);
    const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [rightFocus, setRightFocus] = React.useState(false);
    const [borderColor, setBorderColor] = React.useState('transparent')
    const [logSuccess, setLogSuccess] = React.useState(false)
    React.useEffect(() => {
        const userGetter = async () => {
            await getUser()
                .then((res) => {
                    setUsername(res.data.result.username)
                })
        }
        userGetter();
    });
    
    return (
        <div>
            {collapsed ? (
                <div
                    id="bodyClick"
                    onClick={() => {
                        document.documentElement.classList.toggle("nav-open");
                        setCollapsed(false);
                    }}
                />
            ) : null}
            <Navbar className={"fixed-top " + navbarColor}
                expand={desktopView ? true : false}
                style={!lightMode ? {
                    backgroundColor: colors.darkGray,
                    borderBottom: `1px solid ${borderColor}`,
                } : {
                    backgroundColor: colors.lightGray,
                    borderBottom: `1px solid ${borderColor}`
                }} container='fluid'>
                    <Alert color="success" isOpen={logSuccess}
                        style={{
                            position: "absolute",
                            minWidth: window.innerWidth > 810 ? "810px" : "100%",
                            zIndex: 1,
                            left: window.innerWidth > 810 ? window.innerWidth * 0.5 - 405 : 0,
                            backgroundColor: "#28A745",
                            textAlign: "center"
                        }}>
                        <Container>
                            <div className="alert-icon">
                                <i className="now-ui-icons ui-2_like"></i>
                            </div>
                            Siz uğurla hesabdan çıxdınız!
                            <button
                                type="button"
                                className="close" style={{ float: 'right', backgroundColor: 'transparent', border: 'none' }}
                                onClick={() => setLogSuccess(false)}
                            >
                                <span aria-hidden="true">
                                    <i className="now-ui-icons ui-1_simple-remove"></i>
                                </span>
                            </button>
                        </Container>
                    </Alert>
                    <NavbarTranslate 
                        toggleTheme={toggleTheme} 
                        setNavbarColor={setNavbarColor} 
                        setBorderColor={setBorderColor} 
                        collapsed={collapsed} 
                        setCollapsed={setCollapsed}
                    />
                    <Collapse isOpen={collapsed} navbar style={ desktopView ? 
                        {marginRight: "2vw", marginTop: 9} 
                    : {}} className="justify-content-end">
                        <Nav navbar>
                            {collapsed || desktopView ?
                                <>
                                    <NavItem>
                                        <SearchInput
                                            rightFocus={rightFocus} 
                                            setRightFocus={setRightFocus} 
                                            isHomePage={false}
                                        />
                                    </NavItem>
                                    <AddArticleButton />
                                    { desktopView ? 
                                        <ProfileButton 
                                            username={username} 
                                            setLogSuccess={setLogSuccess} 
                                        /> :
                                        <CombinedButtons setLogSuccess={setLogSuccess} />
                                    }
                                </>
                                :
                                <>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                </>
                            }
                        </Nav>
                    </Collapse>
            </Navbar>
        </div>
    );
}

export default PublicNavbar;