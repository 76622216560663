import React from "react";
import { colors } from 'assets/js/theme';
import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/avatars-identicon-sprites';
import SVG from 'react-inlinesvg';

export default function identicon({ username }) {
    return (
        username.toLowerCase() === "gyulism" ?
            <svg width="90" height="90" xmlns="http://www.w3.org/2000/svg" style={{
                marginLeft: "calc(50% - 45px)",
                marginTop: "12.5px",
                backgroundColor: "transparent"
            }}>
                <g>
                    <rect fill="#ff0000" x="18" y="0.3871" width="18" height="18" />
                    <rect fill="#ff0000" x="54" y="0.3871" width="18" height="18" />
                    <rect fill="#ff0000" x="36" y="71.22581" width="18" height="18" />
                    <rect height="36" width="90" y="18" x="0" fill="#ff0000" />
                    <rect height="18" width="54" y="53.6129" x="18" fill="#ff0000" />
                </g>
            </svg> :
            <SVG src={createAvatar(style, {
                seed: username
            })} style={{
                width: "90px",
                height: "90px",
                marginLeft: "calc(50% - 45px)",
                marginTop: "12.5px"
            }} className={"onlyme1"} />
    )
}