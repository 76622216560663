import React from "react";
import { colors } from 'assets/js/theme';
import { useNavigate } from 'react-router-dom';

// sections for this page
import {
    Button,
} from "reactstrap"

function PublicButtons() {
    const navigate = useNavigate()
    const defWidth = "80%"
    const defMargin = "10%"
    return (
        <>
            <Button
                className="btn-round button danger animateme"
                color="danger"
                outline
                type="button"
                style={{
                    width: defWidth,
                    marginLeft: defMargin,
                    margin: "0 auto"
                }}
                onClick={() => {
                    navigate(
                        '/authentication',
                        {
                            state: {
                                mode: "register"
                            }
                        }
                    )
                }}
            >
                Qeydiyyatdan Keç
            </Button>
            <Button
                className="btn-round button success animateme"
                color="success"
                outline
                type="button"
                style={{
                    width: defWidth,
                    marginLeft: defMargin,
                    margin: "0 auto"
                }}
                onClick={() => {
                    navigate('/authentication')
                }}
            >
                Daxil Ol
            </Button>
        </>
    )
}

export default PublicButtons