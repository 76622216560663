import React from "react";
import { colors } from 'assets/js/theme';

// core components
import MainFooter from "components/Footers/MainFooter.js"
import Keyboard from "components/Keys/Keyboard"
import PublicButtons from "components/Buttons/HomeButtons/PublicButtons"
import UserButtons from "components/Buttons/HomeButtons/UserButtons"
import CourseButton from 'components/Buttons/HomeButtons/CourseButton'
import SearchInput from 'components/Navbars/NavbarItems/SearchInput'

// request components
import {
    getUser
} from "shared/request.js"

// sections for this page 
import {
    Button
} from "reactstrap"
import Loading from "components/SVG/Loading";

function Home() {
    const [authorized, setAuthorized] = React.useState(false)
    const [done, setDone] = React.useState(false)
    React.useEffect(() => {
        window.scrollTo({
            top: -1,
            left: -1,
            behavior: 'smooth',
        });
        
        if (localStorage.getItem("theme") !== "dark" && localStorage.getItem("theme") !== "light") {
            localStorage.setItem("theme", "light")
        }
        const userGetter = async () => {
            await getUser()
                .then((result) => {
                    if (result.data.success) { setAuthorized(true); setDone(true) };
                }).catch((err) => {
                    console.log(err)
                    setAuthorized(false)
                    setDone(true)
                });
        }
        userGetter();
    }, [])
    
    const [lightMode, setTheLightMode] = React.useState(localStorage.getItem("theme") === "light")
    React.useEffect(() => {
        const bgColor = lightMode ? colors.white : colors.darkGray;
        document.documentElement.style.backgroundColor = bgColor;
    }, [lightMode]);

    const desktopView = window.innerWidth > 810
    const [rightFocus, setRightFocus] = React.useState(false);
    const bgColor = lightMode ? colors.white : colors.darkGray
    const fontColor = lightMode ? colors.darkGray : colors.white
    const defFontSize = desktopView ? 100 : 50;
    const setLightMode = (value) => {
        localStorage.removeItem("theme")
        localStorage.setItem("theme", value ? "light" : "dark")
        setTheLightMode(!lightMode)
    }
    return (
        <>
            <div className="wrapper animateme" style={{
                backgroundColor: bgColor,
            }}>
                <div className="main animateme" style={{
                    maxWidth: desktopView && window.innerWidth > 1500 ? "1500px" : "100vw",
                    marginLeft: desktopView && window.innerWidth > 1500 ? "calc(50vw - 750px)" : "0vw",
                    backgroundColor: bgColor,
                    paddingTop: desktopView ? "12vh" : "10vh"
                }}>
                    {!desktopView ? lightMode ?
                        <Button className="btn-icon btn-round animateme" type="button"
                            style={{
                                backgroundColor: colors.darkGray,
                                marginLeft: "10px",
                                float: "left",
                                position: "absolute",
                                top: "0px"
                            }}
                            onClick={() => { setLightMode(false) }}>
                            <i className="fas fa-moon animateme" style={{ fontSize: 24 }}></i>
                        </Button>
                        :
                        <Button className="btn-icon btn-round animateme" type="button"
                            style={{
                                backgroundColor: colors.lightGray,
                                marginLeft: "10px",
                                float: "left",
                                position: "absolute",
                                top: "0px"
                            }}
                            onClick={() => { setLightMode(true) }}>
                            <i className="fas fa-sun animateme"
                                style={{
                                    color: colors.darkGray,
                                    fontSize: 24,
                                }}
                            ></i>
                        </Button> : <></>}
                    <h1 style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        color: fontColor,
                        fontSize: defFontSize
                    }}
                        className={"animateme"}
                    >KiberLüğət</h1>
                    <h3 style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        paddingTop: "1vh",
                        paddingBottom: "2vh",
                        color: fontColor,
                        fontSize: defFontSize / 3
                    }}
                        className={"animateme"}
                    >
                        {desktopView ? lightMode ?
                            <Button className="btn-icon btn-round animateme" type="button"
                                style={{
                                    backgroundColor: colors.darkGray,
                                    marginLeft: "10px",
                                    float: "left",
                                    marginRight: "110px"
                                }}
                                onClick={() => { setLightMode(false) }}>
                                <i className="fas fa-moon animateme" style={{ fontSize: 24 }}></i>
                            </Button>
                            :
                            <Button className="btn-icon btn-round animateme" type="button"
                                style={{
                                    backgroundColor: colors.white,
                                    marginLeft: "10px",
                                    float: "left",
                                    marginRight: "110px"
                                }}
                                onClick={() => { setLightMode(true) }}>
                                <i className="fas fa-sun animateme"
                                    style={{
                                        color: colors.darkGray,
                                        fontSize: 24,
                                    }}
                                ></i>
                            </Button> : <></>}
                        Azərbaycanda ilk və tək!
                    </h3>
                    <div id={"left"}
                        style={{
                            width: desktopView ? "50%" : "100%",
                            float: "left",
                            paddingLeft: desktopView ? "5%" : "0px",
                            height: desktopView ? 300 : 200,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}
                    >
                        <SearchInput
                            rightFocus={rightFocus} 
                            setRightFocus={setRightFocus} 
                            isHomePage={true}
                        />
                        {done ? (!authorized ? 
                            <PublicButtons /> : 
                            <UserButtons />) : 
                        <Loading page='home' />}
                        {done ? <CourseButton /> : <></>}
                    </div>
                    <div id={"right"}
                        style={{
                            width: desktopView ? "50%" : "100%",
                            paddingRight: desktopView ? "5%" : "0px",
                            float: "right",
                            height: desktopView ? 300 : 400
                        }}
                    >
                        {!desktopView ? (<h3
                            style={{
                                color: fontColor,
                                marginTop: 30,
                                textAlign: "center"
                            }}
                            className={"animateme"}
                        >İlk hərfə görə axtar:
                        </h3>) : <></>}
                        <Keyboard term={''} mode={lightMode} />
                    </div>
                </div>
                <MainFooter style={{ position: "fixed", bottom: 0, maxHeight: "3vh", minHeight: "3vh" }} id="footer" />
            </div>
        </>
    );
}

export default Home;
