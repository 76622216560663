import React from "react";
import WhatsApp from "components/SVG/WhatsApp";
import LinkedIn from "components/SVG/LinkedIn";

// sections for this page
import {
    Button,
    ButtonGroup
} from "reactstrap"

export default function ContactButtons() {
    const wideScreen = window.innerWidth > 1200
    const defFontSize = window.innerWidth > 810 ? Math.min(window.innerWidth/15, 105) : 50;
    return (
        <ButtonGroup className="animateme" style={{height: defFontSize/1.5, marginBottom: 30}}>
            <Button className="animateme linkedin" outline color="info"
                style={{
                    margin: '0 2% 0 0',
                    borderRadius: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    textAlign: 'center',
                    justifyContent: wideScreen ? '' : 'center',
                    backgroundColor: wideScreen ? '' : '#0A66C2',
                    width: '46%',
                }}
                onClick={() => window.open('https://www.linkedin.com/in/ismail-eyyub/', '_blank')}
                >
                <LinkedIn size={defFontSize/2}/>
                {wideScreen ? 
                    <h3 style={{ margin: 0, marginLeft: 25 }}>
                        LinkedIn
                    </h3> : <></>
                }
            </Button>
            <Button className="animateme whatsapp" outline color="success"
                style={{
                    margin: '0 0 0 2%',
                    borderRadius: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    textAlign: 'center',
                    justifyContent: wideScreen ? '' : 'center',
                    backgroundColor: wideScreen ? '' : '#2CB742',
                    width: '46%',
                }}
                onClick={() => window.open('https://wa.me/994556421029', '_blank')}
                >
                <WhatsApp size={defFontSize/2} />
                {wideScreen ? 
                    <h3 style={{ margin: 0, marginLeft: 25 }}>
                        WhatsApp
                    </h3> : <></>
                }
            </Button>
        </ButtonGroup>
    )
}