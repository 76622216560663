import React from "react";
import { colors } from 'assets/js/theme';
import { useNavigate } from 'react-router-dom';

import {
    NavItem,
    Button
} from "reactstrap";

export default function AddArticleButton() {
    const navigate = useNavigate()
    const desktopView = window.innerWidth > 1300
    return (
        <NavItem>
            <Button
                className="btn-round button danger animateme"
                color="danger"
                outline
                type="button"
                style={{
                    margin: "0px",
                    width: desktopView ? "fit-content" : '100%',
                    fontSize: desktopView ? 20 : 'auto',
                    marginBottom: desktopView ? 'auto' : "10px"
                }}
                onClick={() => {
                    navigate(`/post`)
                }}
            >
                Lüğətə Əlavə Et
            </Button>
        </NavItem>
    )
}