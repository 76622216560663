import axios from 'axios'
import ROUTES from './constants';

const route = ROUTES.backendURL;

// ! USER

// ? ACCOUNTS
export function registerUser(data) {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).post('/auth/user/register', data)
}

export function loginUser(data) {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).post('/auth/user/login', data)
}

export function verifyUser(token) {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).get(`/auth/user/confirm/${token}`)
}

export function getUser() {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).get(`/auth/user/getdata/`)
}

export function signOut() {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).post('/auth/user/signout')
}

export function changePassword(data) {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).post('/auth/user/changepassword', data)
}

export function forgetPassword(email) {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).post('/auth/forget', email)
}

export function resetPassword(data) {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).post(`/auth/reset`, data);
}


// ? ARTICLES
export function getArticle(name) {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).get(`/article/${name}`);
}

export function createArticle(data) {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).post('/article/new', data);
}

export function updateArticle(term, data) {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).post(`/article/update/${term}`, data)
}

export function searchArticle(term) {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',

        }
    }).get(`/search/`, { params: { query: term } });
}

export function searchLetterArticles(letter) {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).get(`/search/${letter}/`);
}

export function searchDigitArticles() {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).get('/search/0-9/');
}

export function getMyArticles() {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).get('/profile/myarticles');
}


// ? NOTIFICATIONS
export function getMyNotifications() {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).get('/profile/notifications');
}

export function deleteNotification(id) {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).delete(`/profile/delete/notifications/${id}`);
}





// ! ADMIN

// ? ARTCILES
export function getArticleByID(id) {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).get(`/article/id/${id}`);
}

export function getArticles() {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).get('/admin/articles');
}


// ? ACCOUNTS
export function loginAdmin(data) {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).post(`/auth/admin/login`, data);
}

export function adminSignOut() {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).post(`/auth/admin/signout`);
}

export function verifyAdmin(token) {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).get(`/auth/admin/confirm/${token}`);
}

export function getNumberOfUsers() {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).get(`/admin/users`);
}

export function changeAdminPassword(data) {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).post('/auth/admin/changepassword', data)
}


// ? SUGGESTIONS
export function getSuggestions() {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).get('/admin/suggestions');
}

export function approveSuggestion(id) {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).post(`/admin/approve/${id}`);
}

export function declineSuggestion(id) {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).delete(`/admin/decline/${id}`);
}


// ? UPDATES
export function getUpdates() {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).get('/admin/updates');
}

export function approveUpdate(id) {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).put(`/admin/approveupdate/${id}`);
}

export function declineUpdate(id) {
    return axios.create({
        baseURL: route,
        timeout: 10000,
        withCredentials: true,
        headers: {
            "Authorization": localStorage.getItem("authorization") ?
                localStorage.getItem("authorization") : '',
        }
    }).delete(`/admin/declineupdate/${id}`);
}
