import React from "react";
import { colors } from 'assets/js/theme';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import {
    NavbarBrand,
    Tooltip,
    Button
} from "reactstrap";


export default function TitleElement({ toggleTheme }) {
    const lightMode = localStorage.getItem("theme") === "light"
    const fontColor = lightMode ? colors.darkGray : colors.white
    const bgColor = lightMode ? colors.white : colors.darkGray
    const desktopView = window.innerWidth > 1300
    const defFontSize = desktopView ? 28 : 24;
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    React.useEffect(() => {
        let spans = document.getElementsByTagName("span")
        if (spans.length > 0) {
            for (let i = 0; i < spans.length; i++) {
                if (spans[i].classList[1] == "tooltip-arrow") {
                    spans[i].classList.add('animateme')
                }
            }
        }
    }, [])
    var addRule = (function (style) {
        var sheet = document.head.appendChild(style).sheet;
        return function (selector, css) {
            var propText = typeof css === "string" ? css : Object.keys(css).map(function (p) {
                return p + ":" + (p === "content" ? "'" + css[p] + "'" : css[p]);
            }).join(";"); 
            sheet.insertRule(selector + "{" + propText + "}", sheet.cssRules.length);
        };
    })(document.createElement("style"));

    addRule(".tooltip-arrow", {
        '--bs-tooltip-bg': fontColor 
    });
    addRule(".tooltip-inner", {
        color: `${bgColor} !important`,
        '--bs-tooltip-color': bgColor 
    })

    return (
        <div style={{marginTop: '14px'}}>
            <Button
                className="btn-icon btn-round"
                type="button"
                style={{
                    backgroundColor: lightMode ? colors.darkGray : colors.lightGray,
                    marginTop: -5,
                    marginLeft: desktopView ? 0 : 10,
                    float: "left",
                }}
                onClick={toggleTheme}
                >
                <i className={lightMode ? "fas fa-moon" : "fas fa-sun"} 
                    style={{ color: lightMode ? "" : colors.darkGray, fontSize: 24 }}></i>
            </Button>
            <NavbarBrand
                className={"animateme"}
                id="navbar-brand"
                style={{ 
                    marginLeft: desktopView ? '2vw' : -30,
                    marginBottom: "0px",
                    fontSize: defFontSize,
                    color: fontColor,
                }}
                href="/home"
            >
                KİBERLÜĞƏT
            </NavbarBrand>
            <Tooltip target={"navbar-brand"}
                style={{
                    backgroundColor: fontColor,
                }}
                className={"animateme"}
                isOpen={tooltipOpen}
                toggle={toggle}
                placement="bottom"  
                autohide={true}
            >
                Azərbaycanda ilk və tək!
            </Tooltip>
        </div>
    )
}