import React from "react";
import { colors } from 'assets/js/theme';
import { Navigate } from 'react-router-dom';

// core components
import UserProfile from "components/Profiles/UserProfile";
import AdminProfile from "components/Profiles/AdminProfile"
import UserNavbar from "components/Navbars/UserNavbar.js";
import LoadingPage from "views/LoadingPage";

// backend components
import {
    getUser,
} from "shared/request"

function Profile() {
    const [status, setStatus] = React.useState('')
    const [user, setUser] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    React.useEffect(() => {
        window.scrollTo({
            top: -1,
            left: -1,
            behavior: 'smooth',
        })
        
        const checkUser = async () => {
            try {
                const userData = await getUser().then((result) => {
                    if (result.data.success) {
                        setStatus(result.data.result.status)
                    };
                });
                setUser(userData);
            } catch (err) {
                setUser(false);
            } finally {
                setIsLoading(false);
            }
        };
        checkUser();
    }, [])

    const [lightMode, setLightMode] = React.useState(localStorage.getItem("theme") === "light")
    React.useEffect(() => {
        const bgColor = lightMode ? colors.white : colors.darkGray;
        document.documentElement.style.backgroundColor = bgColor;
    }, [lightMode]);
    
    function toggleTheme() {
        const newTheme = lightMode ? "dark" : "light";
        localStorage.setItem("theme", newTheme);
        setLightMode(!lightMode);
    }

    if (isLoading) return <LoadingPage />;
    if (user == false) return <Navigate to='/authentication' />;  
    return (
        <>
            <UserNavbar toggleTheme={toggleTheme} />
            {status === 'USER' ? <UserProfile /> : <AdminProfile />}
        </>
    )
}

export default Profile;
