import React from "react";
import { colors } from 'assets/js/theme';
import { useNavigate, useParams } from 'react-router-dom';

// core components
import MainFooter from "components/Footers/MainFooter.js";
import Keyboard from "components/Keys/Keyboard";
import PublicNavbar from "components/Navbars/PublicNavbar.js";
import UserNavbar from "components/Navbars/UserNavbar.js";
import ArticleFullCard from "components/Cards/ArticleFullCard";

// backend components
import {
    getArticle,
    getUser
} from "shared/request"

// sections for this page
import {
    Alert,
    Container,
} from "reactstrap"
import Loading from "components/SVG/Loading";

function Article() {
    const navigate = useNavigate()
    const [authorized, setAuthorized] = React.useState(false)
    const [done, setDone] = React.useState(false)
    const [term, setTerm] = React.useState('')
    const [definition, setDefinition] = React.useState('');
    const [pubDate, setPubDate] = React.useState(null);
    const [author, setAuthor] = React.useState('')
    const params = useParams(0)
    const [error, setError] = React.useState(false)
    React.useEffect(() => {
        window.scrollTo({
            top: -1,
            left: -1,
            behavior: 'smooth',
        })
        
        if (localStorage.getItem("theme") !== "dark" && localStorage.getItem("theme") !== "light") {
            localStorage.setItem("theme", "light")
        }

        if (!params.term) {
            navigate('/home')
        }
        const process = async () => {
            await getUser()
                .then((result) => {
                    if (result.data.success) { setAuthorized(true); setDone(true) };
                }).catch((err) => {
                    setAuthorized(false)
                    setDone(true)
                });
    
            await getArticle(params.term)
                .then((res) => {
                    setTerm(res.data.result.term)
                    setDefinition(res.data.result.definition)
                    setPubDate(new Date(res.data.result.published_date))
                    setAuthor(res.data.result.author)
                }).catch(err => {
                    if (err.response) {
                        if (err.response.data.error) {
                            setError(true)
                        }
                    }
                })
        }
        process();
    }, [])

    const [lightMode, setLightMode] = React.useState(localStorage.getItem("theme") === "light")
    React.useEffect(() => {
        const bgColor = lightMode ? colors.white : colors.darkGray;
        document.documentElement.style.backgroundColor = bgColor;
    }, [lightMode]);

    const desktopView = window.innerWidth > 810
    const ultraWide = window.innerWidth >= 1920
    const bgColor = lightMode ? colors.white : colors.darkGray
    const fontColor = lightMode ? colors.darkGray : colors.white
    var divHeight = window.innerHeight
    let card = document.getElementById("card");
    if (card) {
        divHeight = card.clientHeight + (desktopView ? 340 : 720)
    }


    let ps = document.getElementsByTagName("p")
    if (ps) {
        for (let i = 0; i < ps.length; i++) {
            if (ps[i].innerHTML == "\n" || ps[i].innerHTML == "<br>") ps[i].style.fontSize = "0px"
        }
    }

    function toggleTheme() {
        const newTheme = lightMode ? "dark" : "light";
        localStorage.setItem("theme", newTheme);
        setLightMode(!lightMode);
    }

    return (
        <>
            {done ? (!authorized ? <PublicNavbar toggleTheme={toggleTheme} /> : <UserNavbar toggleTheme={toggleTheme} />) : <></>}
            <div className="wrapper animateme" style={{
                backgroundColor: bgColor,
            }}>
                <Alert color="danger" isOpen={error}
                    style={{
                        position: "absolute",
                        minWidth: desktopView ? "810px" : "100%",
                        zIndex: 1032,
                        top: "0px",
                        textAlign: "center",
                        left: desktopView ? window.innerWidth / 2 - 405 : 0,
                        backgroundColor: "#D02F30",
                        textAlign: "center"
                    }}>
                    <Container>
                        <div className="alert-icon" style={{
                            marginTop: desktopView ? "auto" : 0
                        }}>
                            <i className="now-ui-icons ui-1_bell-53"></i>
                        </div>
                        Bu adlı məqalə mövcüd deyil!
                        <button
                            type="button"
                            className="close" style={{ float: 'right', backgroundColor: 'transparent', border: 'none' }}
                            onClick={() => setError(false)}
                        >
                            <span aria-hidden="true">
                                <i className="now-ui-icons ui-1_simple-remove"></i>
                            </span>
                        </button>
                    </Container>
                </Alert>
                <div className="main animateme" style={{
                    maxWidth: desktopView && window.innerWidth > 1350 ? ultraWide ?
                        "1920px" : "1350px" : "100vw",
                    marginLeft: desktopView && window.innerWidth > 1350 ? ultraWide ?
                        "calc(50vw - 960px)" : "calc(50vw - 675px)" : "0vw",
                    backgroundColor: bgColor,
                    height: divHeight > window.innerHeight ? divHeight + "px" : "100vh",
                    paddingTop: desktopView ? "76px" : "55px"
                }}>
                    <div id="left" style={{
                        width: ultraWide ? "calc(100% - 700px)" : "100%",
                        float: "left"
                    }}>
                        {term === '' ? <Loading page='article' /> :
                            <ArticleFullCard term={term} author={author} pubDate={pubDate} definition={definition} page={'article'} />
                        }
                    </div>
                    {desktopView ? ultraWide ?
                        <>
                            <div id="right" style={{
                                float: "left",
                                marginTop: "4vh"
                            }}>
                                <h3
                                    className={"animateme"}
                                    style={{
                                        color: fontColor,
                                        marginBottom: "20px",
                                        marginTop: "20px",
                                        textAlign: "center"
                                    }}>İlk hərfə görə axtar:
                                </h3>
                                <Keyboard term={''} mode={lightMode} />
                            </div>
                        </> :
                        <></>
                        :
                        <div id="right" style={{
                            width: "100%",
                            float: "right",
                            marginTop: "4vh"
                        }}>
                            <h3
                                className={"animateme"}
                                style={{
                                    color: fontColor,
                                    marginBottom: "20px",
                                    marginTop: "20px",
                                    textAlign: "center"
                                }}>İlk hərfə görə axtar:
                            </h3>
                            <Keyboard term={''} mode={lightMode} />
                        </div>
                    }
                </div>
                <MainFooter style={{ position: "fixed", bottom: 0, height: "3vh" }} />
            </div>
        </>
    )
}

export default Article;
