/*eslint-disable*/
import React from "react";
import { colors } from 'assets/js/theme';

// reactstrap components
import {
    Container,
    Label,
    FormGroup,
    Input,
} from "reactstrap";

function DarkFooter() {
    return (
        <></>
        // <footer className="footer" data-background-color=colors.black>
        //     <Container>
        //         <nav>
        //             <ul>
        //                 <li>
        //                     <a
        //                         href="/about"
        //                         target="_blank"
        //                     >
        //                         Bizim Haqqımızda
        //                     </a>
        //                 </li>
        //                 <li>
        //                     <a
        //                         href="https://www.twitter.com/cyberdictionary"
        //                         target="_blank"
        //                     >
        //                         Bloqumuz
        //                     </a>
        //                 </li>
        //             </ul>

        //         </nav>
        //         <div className="copyright" id="copyright">
        //             © {new Date().getFullYear()}, KiberLüğət{" "}
        //         </div>
        //     </Container>
        // </footer>
    );
}

export default DarkFooter;
