import React from "react";
import { colors } from 'assets/js/theme';

// sections for this page
import {
    CardHeader,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";

function CardHeaderComponent({ page, mode, modes, texts, setMode }) {
    const desktopView = window.innerWidth > 810
    const lightMode = localStorage.getItem("theme") === "light"
    const butColor = lightMode ? colors.darkGray : colors.lightGray
    const fontColor = lightMode ? colors.lightGray :colors.darkGray
    const [buttonFontColor, setButtonFontColor] = React.useState("#888888")
    const [buttonFontColor1, setButtonFontColor1] = React.useState("#888888")
    return (
        <CardHeader>
            <Nav className="justify-content-center animateme" role="tablist" tabs>
                <NavItem className="animateme" 
                    style={page === "profile" ? { width: "100%" } : 
                        desktopView ? { width: "50%" } : { width: "100%" }}
                >
                    <NavLink
                        className={`${mode === modes[0] ? "active" : ""} animateme`}
                        onClick={(e) => {
                            e.preventDefault();
                            setMode(modes[0]);
                        }}
                        style={{
                            backgroundColor: mode === modes[0] ? butColor : "transparent",
                            cursor: "pointer",
                            color: mode === modes[0] ? fontColor : buttonFontColor,
                            width: "100%",
                            fontSize: window.innerWidth > 1000 ? 20 : 15,
                            textAlign: "center"
                        }}
                        onMouseEnter={(e) => {
                            setButtonFontColor(lightMode ? colors.darkGray : colors.white)
                        }}
                        onMouseLeave={(e) => {
                            setButtonFontColor("#888888")
                        }}
                    >
                        {window.innerWidth > 1200 || (!desktopView && window.innerWidth > 350) ?
                            page !== "profile" ?
                                <i className="fa fa-user-plus" style={{
                                    fontSize: 20,
                                    marginRight: "30px"
                                }}></i> : <></> : <></>
                        }
                        {texts[0]}
                    </NavLink>
                </NavItem>
                <NavItem className="animateme" 
                    style={page === "profile" ? { width: "100%" } : 
                        desktopView ? { width: "50%" } : { width: "100%" }}
                >
                    <NavLink
                        className={`${mode === modes[1] ? "active" : ""} animateme`}
                        onClick={(e) => {
                            e.preventDefault();
                            setMode(modes[1]);
                        }}
                        style={{
                            backgroundColor: mode === modes[1] ? butColor : "transparent",
                            cursor: "pointer",
                            color: mode === modes[1] ? fontColor : buttonFontColor1,
                            width: "100%",
                            fontSize: window.innerWidth > 1000 ? 20 : 15,
                            textAlign: "center"
                        }}
                        onMouseEnter={(e) => {
                            setButtonFontColor1(lightMode ? colors.darkGray : colors.white)
                        }}
                        onMouseLeave={(e) => {
                            setButtonFontColor1("#888888")
                        }}
                    >
                        {window.innerWidth > 1200 || (!desktopView && window.innerWidth > 350) ?
                            page !== "profile" ?
                                <i className="fa fa-sign-in-alt" style={{
                                    fontSize: 20,
                                    marginRight: "30px"
                                }}></i> : <></> : <></>
                        }
                        {texts[1]}
                    </NavLink>
                </NavItem>
            </Nav>
        </CardHeader>
    )

}

export default CardHeaderComponent;