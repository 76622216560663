export const colors = {
    darkGray: '#151515',
    lightGray: '#F2F2F2',
    almostDarkGray: "#5c5c5c",
    white: '#FFFFFF',
    black: '#000000',
    gray: '#CCCCCC',
    red: '#FF0000',
    green: '#00FF00',
    blue: '#0000FF',
    yellow: '#FFFF00'
};