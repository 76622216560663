import { useEffect } from 'react';
import { colors } from 'assets/js/theme';

const BackgroundColorSetter = () => {
    useEffect(() => {
        const bgColor = localStorage.getItem("theme") === "light" ? colors.white : colors.darkGray;
        document.documentElement.style.backgroundColor = bgColor;
        document.documentElement.classList.add('animateme')
    }, []);

    return null;
};

export default BackgroundColorSetter;