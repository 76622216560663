import React from "react";
import { colors } from 'assets/js/theme';
import { useNavigate } from 'react-router-dom';

// sections for this page
import {
    Button,
} from "reactstrap"

export default function CourseButton() {
    const navigate = useNavigate()
    return (
        <Button
            className="btn-round button warning animateme"
            color="warning"
            outline
            type="button"
            style={{
                width: "80%",
                marginLeft: "10%",
                margin: "0 auto"
            }}
            onClick={() => { navigate('/course') }}
        >
            Kibertəhlükəsizlik Öyrən
        </Button>
    )
}