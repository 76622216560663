import React from "react";
import { colors } from 'assets/js/theme';

export default function LoadingPage() {
    const width = window.innerWidth
    const desktopView = width > 810
    const lightMode = localStorage.getItem("theme") === "light"
    const bgColor = lightMode ? colors.white : colors.darkGray
    return (
        <div className="wrapper animateme" style={{
            backgroundColor: bgColor,
        }}>
            <div className="main animateme" style={{
                backgroundColor: bgColor,
                height: desktopView ? window.innerHeight + "px" : window.innerHeight + 150 + "px"
            }}>
                <img src={
                    lightMode ?
                        require("assets/img/gifs/lightmode.svg").default :
                        require("assets/img/gifs/darkmode.svg").default
                } style={{
                    width: desktopView ? "40vw" : "50vw",
                    marginLeft: desktopView ? "30vw" : "25vw",
                    marginTop: desktopView
                                ? `calc((${window.innerHeight}px - 40vw)/2)` 
                                : `calc((${window.innerHeight}px - 50vw)/2)`
                }}/>
            </div>
        </div>
    )
}