import React from "react";
import { colors } from 'assets/js/theme';

// sections for this page
import {
    PaginationLink
} from "reactstrap";

const PaginationTable = ({ currentPage, setPage, totalPageCount }) => {
  const desktopView = window.innerWidth > 810
  let lightMode = localStorage.getItem("theme") === "light"
  const borderColor = lightMode ? colors.darkGray : colors.lightGray
  const fontColor = lightMode ? colors.darkGray : colors.white

  const createPaginationCell = (page) => (
    <td key={page} style={{
      width: desktopView ? "14.28%" : '20%'
    }}>
      <PaginationLink className={`animateme ${page === currentPage ? 
              lightMode ? 'lActive' : 'dActive' :
              lightMode ? 'lPassiveKeyPagination' : 'dPassiveKeyPagination' }`}
          style={{
              border: `1px solid ${borderColor}`,
              borderRadius: 10,
              fontSize: 20,
              width: "80%",
              marginLeft: '10%'
          }}
          onClick={(e) => {
            setPage(page)
            window.scrollTo({
              top: -1,
              left: -1,
              behavior: 'instant',
            })
            e.currentTarget.blur();
          }}
      >
          {page}
      </PaginationLink>      
    </td>
  );

  const paginationCells = [];

  // Previous Page Button
  if (currentPage > 1) {
    paginationCells.push(
      <td key="prev" style={{width: desktopView ? "14.28%" : '20%'}}>
        <PaginationLink className={`animateme`}
            aria-label="Previous"
            onClick={(e) => {
                setPage(currentPage - 1)
                window.scrollTo({
                    top: -1,
                    left: -1,
                    behavior: 'smooth',
                })
                e.currentTarget.blur();
            }}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',    
                paddingTop: "3px",
                width: '100%'
            }}
        >
            <span aria-hidden={true}>
                <i
                    aria-hidden={true}
                    className="fa fa-angle-double-left animateme"
                    style={{ fontSize: 20, color: fontColor }}
                ></i>
            </span>
        </PaginationLink>
      </td>
    );
  } else {
    paginationCells.push(<td key="empty-prev" style={{width: desktopView ? "14.28%" : '20%'}}></td>);
  }
  
  // Page -2
  if (desktopView){
    if (currentPage > 2) {
      paginationCells.push(createPaginationCell(currentPage - 2));
    } else {
      paginationCells.push(<td key="empty-1" style={{width: desktopView ? "14.28%" : '20%'}}></td>);
    }
  }

  // Page -1
  if (currentPage > 1) {
    paginationCells.push(createPaginationCell(currentPage - 1));
  } else {
    paginationCells.push(<td key="empty-2" style={{width: desktopView ? "14.28%" : '20%'}}></td>);
  }

  // Current Page
  paginationCells.push(createPaginationCell(currentPage));

  // Page +1
  if (currentPage < totalPageCount) {
    paginationCells.push(createPaginationCell(currentPage + 1));
  } else {
    paginationCells.push(<td key="empty-3" style={{width: desktopView ? "14.28%" : '20%'}}></td>);
  }

  // Page +2
  if (desktopView){
    if (currentPage < totalPageCount - 1) {
      paginationCells.push(createPaginationCell(currentPage + 2));
    } else {
      paginationCells.push(<td key="empty-4" style={{width: desktopView ? "14.28%" : '20%'}}></td>);
    }
  }

  // Next Page Button
  if (currentPage < totalPageCount) {
    paginationCells.push(
      <td key="next" style={{width: desktopView ? "14.28%" : '20%'}}>
        <PaginationLink className={`animateme`}
            aria-label="Next"
            onClick={(e) => {
                setPage(currentPage + 1)
                window.scrollTo({
                    top: -1,
                    left: -1,
                    behavior: 'smooth',
                })
                e.currentTarget.blur();
            }}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',    
                paddingTop: "3px",
                width: '100%'
            }}
        >
            <span aria-hidden={true} style={{alignContent: "right"}}>
                <i
                    aria-hidden={true}
                    className="fa fa-angle-double-right animateme"
                    style={{ fontSize: 20, color: fontColor }}
                ></i>
            </span>
        </PaginationLink>
      </td>
    );
  } else {
    paginationCells.push(<td key="empty-prev" style={{width: desktopView ? "14.28%" : '20%'}}></td>);
  }

  return (
    <table style={{width: "84%", marginLeft: '8%', marginBottom: 20}} id="pagination-table">
      <tbody>
        <tr style={{width: "100%"}}>{paginationCells}</tr>
      </tbody>
    </table>
  );
};

export default PaginationTable;
