import React from "react"
import { colors } from 'assets/js/theme';
import queryString from "query-string";
import { useNavigate, useLocation, Navigate } from 'react-router-dom';

// core components
import MainFooter from "components/Footers/MainFooter.js";
import AuthenticationNavbar from "components/Navbars/AuthenticationNavbar.js";
import RegisterForm from "components/Forms/RegisterForm";
import LoginForm from "components/Forms/LoginForm";
import CardHeaderComponent from "components/Cards/CardHeaderComponent.js"
import LoadingPage from "views/LoadingPage";

// backend components
import {
    verifyUser,
    getUser
} from "shared/request"

// sections for this page
import {
    Card,
    CardBody,
    TabContent,
    Alert,
    Container,
} from "reactstrap"


function Authentication() {
    const [mode, setMode] = React.useState("")
    const [verifySuccess, setVerifySuccess] = React.useState(false)
    const [verifyError, setVerifyError] = React.useState(false)
    const navigate = useNavigate()
    const [user, setUser] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    let location = useLocation();
    React.useEffect(() => {
        window.scrollTo({
            top: -1,
            left: -1,
            behavior: 'smooth',
        });
        
        if (localStorage.getItem("theme") !== "dark" && localStorage.getItem("theme") !== "light") {
            localStorage.setItem("theme", "light")
        }
        const process = async () => {
            
            if (localStorage.getItem('authorization')) {
                await getUser()
                    .then((res) => {
                        navigate('/profile')
                    })
                    .catch((err) => {
                        localStorage.removeItem('authorization')
                        window.location.reload()
                    })
            }
            if (location.state) {
                if (mode !== "login" || mode !== "register") {
                    setMode(location.state.mode)
                }
            }
            else {
                setMode("login")
            }
    
            if (queryString.parse(location.search).token) {
                await verifyUser(queryString.parse(location.search).token)
                    .then(res => {
                        setVerifyError(false)
                        setVerifySuccess(true)
                        setTimeout(() => {
                            setVerifySuccess(false)
                        }, 5000)
                    })
                    .catch(error => {
                        if (error.response) {
                            setVerifySuccess(false)
                            setVerifyError(true)
                            setTimeout(() => {
                                setVerifyError(false)
                            }, 5000)
                        }
                    })
            }
        }
        const checkUser = async () => {
            try {
                const userData = await getUser()
                setUser(userData);
            } catch (err) {
                setUser(false);
            } finally {
                setIsLoading(false);
            }
        };
        process();
        checkUser();
    }, [])
    
    const [lightMode, setLightMode] = React.useState(localStorage.getItem("theme") === "light")
    React.useEffect(() => {
        const bgColor = lightMode ? colors.white : colors.darkGray;
        document.documentElement.style.backgroundColor = bgColor;
    }, [lightMode]);
    

    const desktopView = window.innerWidth > 810
    const bgColor = lightMode ? colors.white : colors.darkGray
    const fontColor = lightMode ? colors.darkGray : colors.white
    const borderColor = lightMode ? colors.darkGray : colors.gray
    function toggleTheme() {
        const newTheme = lightMode ? "dark" : "light";
        localStorage.setItem("theme", newTheme);
        setLightMode(!lightMode);
    }

    function setTheMode(mode) {
        setMode(mode)
    }

    if (isLoading) return <LoadingPage />;
    if (user) return <Navigate to='/profile' />;
    return (
        <>
            <div className="wrapper animateme" style={{
                backgroundColor: bgColor,
            }}>
                <div className="main animateme" style={{
                    backgroundColor: bgColor,
                    maxWidth: desktopView && window.innerWidth > 1250 ? "1250px" : "100vw",
                    marginLeft: desktopView && window.innerWidth > 1250 ? "calc(50vw - 625px)" : "0vw",
                    height: desktopView ? window.innerHeight + "px" : window.innerHeight + 150 + "px",
                    paddingTop: desktopView ? "76px" : "55px"
                }}>
                    <Alert color="success" isOpen={verifySuccess && !verifyError}
                        style={{
                            position: "absolute",
                            minWidth: window.innerWidth > 810 ? "810px" : "100%",
                            zIndex: 1031,
                            top: "0px",
                            left: window.innerWidth > 810 ? window.innerWidth * 0.5 - 405 : 0,
                            backgroundColor: "#28A745",
                            textAlign: "center"
                        }}>
                        <Container>
                            <div className="alert-icon" style={{
                                marginTop: desktopView ? "auto" : 0
                            }}>
                                <i className="now-ui-icons ui-2_like"></i>
                            </div>
                            Siz uğurla hesabınızı təsdiqlədiniz!
                            <button
                                type="button"
                                className="close" style={{ float: 'right', backgroundColor: 'transparent', border: 'none' }}
                                onClick={() => setVerifySuccess(false)}
                            >
                                <span aria-hidden="true">
                                    <i className="now-ui-icons ui-1_simple-remove"></i>
                                </span>
                            </button>
                        </Container>
                    </Alert>
                    <Alert color="danger" isOpen={verifyError && !verifySuccess}
                        style={{
                            position: "absolute",
                            minWidth: window.innerWidth > 810 ? "810px" : "100%",
                            zIndex: 1031,
                            top: "0px",
                            left: window.innerWidth > 810 ? window.innerWidth * 0.5 - 405 : 0,
                            backgroundColor: "#FF3736",
                            textAlign: "center"
                        }}>
                        <Container>
                            <div className="alert-icon">
                                <i className="now-ui-icons ui-1_bell-53"></i>
                            </div>
                            Təsdiq açarı səhvdir və ya hesab artıq təsdiqlənib!
                            <button
                                type="button"
                                className="close" style={{ float: 'right', backgroundColor: 'transparent', border: 'none' }}
                                onClick={() => setVerifyError(false)}
                            >
                                <span aria-hidden="true">
                                    <i className="now-ui-icons ui-1_simple-remove"></i>
                                </span>
                            </button>
                        </Container>
                    </Alert>
                    <AuthenticationNavbar toggleTheme={toggleTheme} />
                    <Card
                        className={"animateme"}
                        style={{
                            marginTop: "10vh",
                            maxWidth: desktopView ? "50%" : "80vw",
                            marginLeft: desktopView ? "25%" : "10vw",
                            backgroundColor: lightMode ? colors.lightGray : colors.darkGray,
                            boxShadow: "none",
                            border: `1px solid ${borderColor}`,
                            borderRadius: "25px"
                        }}
                        id={"card"}>
                        <CardHeaderComponent
                            page="authentication"
                            mode={mode}
                            modes={["login", "register"]}
                            texts={["Daxil Ol", "Qeydiyyatdan Keç"]}
                            setMode={setTheMode}
                        />
                        <CardBody>
                            <TabContent
                                className="text-center animateme"
                                activeTab={mode}
                                style={{ color: fontColor }}
                            >
                                <LoginForm mode={lightMode} />
                                <RegisterForm />
                            </TabContent>
                        </CardBody>
                    </Card>
                </div>
                <MainFooter style={{ position: "fixed", bottom: 0, maxHeight: "3vh", minHeight: "3vh" }} id="footer" />
            </div>
        </>
    )
}

export default Authentication