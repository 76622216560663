import React from 'react';
import { colors } from 'assets/js/theme';
// sections for this page
import {
    Alert,
    Container
} from "reactstrap";

export default function Notification({ suggestion, state, id, term, remove}) {
    const desktopView = window.innerWidth > 810
    return (
        <Alert color={`${state ? 'success' : 'danger'}`} id={`notification${id}`} isOpen={true}
            style={{
                width: "90%",
                marginLeft: '5%',
                textAlign: "center",
                backgroundColor: state ? "#28A745" : "#DC3545",
            }}>
            <Container>
                <div className="alert-icon" style={{
                    marginTop: desktopView ? "auto" : 0
                }}>
                    <i className={`now-ui-icons ${state ? 'ui-2_like' : 'ui-1_bell-53'}`}></i>
                </div>
                {suggestion ? `Təklif etdiyiniz "${term}" adlı məqalə ${state ? 'dərc olundu' : 'dərc olunmadı'}!` :
                    `"${term}" adlı məqaləyə etdiyiniz dəyişikliklər ${state ? 'təsdiqləndi' : 'təsdiqlənmədi'}!`}
                <button
                    type="button"
                    className="close" style={{ float: 'right', backgroundColor: 'transparent', border: 'none' }}
                    onClick={() => {
                        remove(id)
                    }}
                >
                    <span aria-hidden="true">
                        <i className="now-ui-icons ui-1_simple-remove"></i>
                    </span>
                </button>
            </Container>
        </Alert>
    )
}