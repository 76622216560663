import React from 'react';
import { colors } from 'assets/js/theme';
import { useNavigate } from 'react-router-dom';

function ArticleCard({ article, mode}) {
    const navigate = useNavigate()
    const defWidth = "80%"
    const defMargin = "10%"
    const desktopView = window.innerWidth > 810
    const [lightMode, setLightMode] = React.useState(mode)
    const bgColor = lightMode ? colors.lightGray :colors.darkGray
    const fontColor = lightMode ? colors.darkGray : colors.lightGray
    const borderColor = lightMode ? colors.darkGray : colors.gray
    const defFontSize = desktopView ? 70 : 50;
    const [butColor, setButColor] = React.useState(lightMode ? colors.darkGray : colors.gray)
    var definition = article.short_definition ? article.short_definition : article.short_description;
    if (lightMode !== mode) {
        setLightMode(mode)
        setButColor(mode ? colors.darkGray : colors.gray)
    }

    return (
        <>
            <div className={"animateme articleDiv"} style={{
                width: defWidth,
                marginLeft: defMargin,
                backgroundColor: bgColor,
                color: fontColor,
                borderTop: `1px solid ${borderColor}`,
                borderLeft: `1px solid ${borderColor}`,
                borderRight: `1px solid ${borderColor}`,
                borderRadius: "30px 30px 0px 0px",
                marginBottom: "none"
            }}
                id={"top"}>
                <h3
                    className={"animateme"}
                    style={{
                        width: "100%",
                        paddingLeft: "5%",
                        paddingRight: "5%",
                        color: fontColor,
                        marginTop: "10px",
                        paddingBottom: "3px",
                        fontSize: defFontSize / 2,
                        textAlign: "center",
                        borderBottom: `1px solid ${borderColor}`,
                        marginBottom: "0px"
                    }}
                >{article.term}</h3>
                <h5
                    style={{
                        marginLeft: "5%",
                        marginRight: "5%",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        marginBottom: "0px"
                    }}>{definition}
                </h5>
            </div >
            <div
                className={`animateme ${lightMode ? 'lActive' : 'dActive'}`}
                style={{
                    width: defWidth,
                    marginLeft: defMargin,
                    border: `1px solid ${borderColor}`,
                    borderRadius: "0px 0px 30px 30px",
                    height: "50px",
                    marginBottom: "30px",
                    textAlign: "center",
                    cursor: "pointer",
                    fontSize: "1.7em",
                    paddingTop: "5px"
                }}
                onClick={() => {
                    navigate(`/article/${article.term.replace(/\s+/g, '_')}`)
                }}
            >
                Oxumağa Davam
            </div>
        </>

    )
}

export default ArticleCard;