import React from "react"
import { colors } from 'assets/js/theme';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import queryString from "query-string";
import validator from 'validator'

// core components
import AuthenticationNavbar from "components/Navbars/AuthenticationNavbar.js";
import LoadingPage from "views/LoadingPage";

// backend components
import {
    resetPassword,
    getUser,
} from "shared/request"

// sections for this page
import {
    Card,
    Alert,
    Container,
    InputGroup,
    Input,
    InputGroupText,
    Button
} from "reactstrap"


function Reset() {
    let location = useLocation();
    console.log(location)
    const [user, setUser] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    React.useEffect(() => {
        window.scrollTo({
            top: -1,
            left: -1,
            behavior: 'smooth',
        });
        
        if (localStorage.getItem("theme") !== "dark" && localStorage.getItem("theme") !== "light") {
            localStorage.setItem("theme", "light")
        }
        const process = async () => {
            if (localStorage.getItem('authorization')) {
                await getUser()
                    .then((res) => {
                        navigate(
                            '/profile'
                        )
                    })
            }
        }
        const checkUser = async () => {
            try {
                const userData = await getUser()
                setUser(userData);
            } catch (err) {
                setUser(false);
            } finally {
                setIsLoading(false);
            }
        };
        process();
        checkUser();
    }, [])

    const [lightMode, setLightMode] = React.useState(localStorage.getItem("theme") === "light")
    React.useEffect(() => {
        const bgColor = lightMode ? colors.white : colors.darkGray;
        document.documentElement.style.backgroundColor = bgColor;
    }, [lightMode]);

    const navigate = useNavigate()
    const desktopView = window.innerWidth > 810
    const bgColor = lightMode ? colors.white : colors.darkGray
    const fontColor = lightMode ? colors.darkGray : colors.white
    const borderColor = lightMode ? colors.darkGray : colors.gray
    const defFontSize = desktopView ? 100 : 70;
    const [password, setPassword] = React.useState("")
    const [confirmPassword, setConfirmPassword] = React.useState("")
    const [resError, setResError] = React.useState(false)
    const [resErrorText, setResErrorText] = React.useState("")
    const [resSuccess, setResSuccess] = React.useState(false)

    function toggleTheme() {
        const newTheme = lightMode ? "dark" : "light";
        localStorage.setItem("theme", newTheme);
        setLightMode(!lightMode);
    }

    function resetError(errortext) {
        setResError(true)
        setResErrorText(errortext)
        setTimeout(() => {
            setResError(false)
        }, 2000)
    }

    async function reset() {
        console.log(queryString.parse(location.search).token)
        if (password !== confirmPassword) {
            resetError('Daxil edilən şifrələr eyni deyil!')
        }
        else if (!validator.isStrongPassword(password, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) resetError('Şifrə ən azı 8 hərfdən, o cümlədən 1 kiçik hərf, 1 böyük hərf, 1 rəqəm və 1 simvoldan ibarət olmalıdır!')
        else {
            await resetPassword({
                password: password,
                passwordConfirmation: confirmPassword,
                reset_token: queryString.parse(location.search).token
            }).then((res) => {
                setResSuccess(true)
                setTimeout(() => {
                    navigate('/authentication')
                }, 3000)
            }).catch(error => {
                if (error.response) {
                    if (error.response.data.error === "Passwords do not match!") {
                        resetError("Daxil edilən şifrələr eyni deyil!")
                    }
                    else if (error.response.data.error === "Token is invalid or has expired!") {
                        resetError("Açar yararsızdır. Yenidən şifrəni dəyişdirin!")
                    }
                }
            })
        }
    }

    if (isLoading) return <LoadingPage />;
    if (user) return <Navigate to='/profile' />;
    return (
        <>
            <AuthenticationNavbar toggleTheme={toggleTheme} />
            <div className="wrapper animateme" style={{
                backgroundColor: bgColor,
            }}>
                <div className="main animateme" style={{
                    backgroundColor: bgColor,
                    maxWidth: desktopView && window.innerWidth > 1250 ? "1250px" : "100vw",
                    marginLeft: desktopView && window.innerWidth > 1250 ? "calc(50vw - 625px)" : "0vw",
                    height: desktopView ? window.innerHeight + "px" : window.innerHeight + 150 + "px",
                    paddingTop: desktopView ? "76px" : "55px"
                }}>
                    <Card
                        className={"animateme"}
                        style={{
                            marginTop: "10vh",
                            maxWidth: desktopView ? "50%" : "80vw",
                            marginLeft: desktopView ? "25%" : "10vw",
                            backgroundColor: lightMode ? colors.lightGray : colors.darkGray,
                            boxShadow: "none",
                            border: `1px solid ${borderColor}`,
                            borderRadius: "25px"
                        }}
                        id={"card"}>
                        <h1
                            className={"animateme"}
                            style={{
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                paddingTop: "1vh",
                                paddingBottom: "1vh",
                                color: fontColor,
                                fontSize: desktopView ? 60 : 50 / 1.7,
                                marginLeft: "10%",
                                overflow: "auto",
                                maxWidth: "80%",
                                textAlign: "center"
                            }}>Şifrə Bərpası</h1>
                        <Alert color="success" isOpen={resSuccess && !resError}
                            style={{
                                backgroundColor: "#28A745",
                                textAlign: "center"
                            }}>
                            <Container>
                                <div className="alert-icon" style={{
                                    marginTop: desktopView ? "auto" : 0
                                }}>
                                    <i className="now-ui-icons ui-2_like"></i>
                                </div>
                                Siz uğurla şifrənizi dəyişdirdiniz!
                                <button
                                    type="button"
                                    className="close" style={{ float: 'right', backgroundColor: 'transparent', border: 'none' }}
                                    onClick={() => setResSuccess(false)}
                                >
                                    <span aria-hidden="true">
                                        <i className="now-ui-icons ui-1_simple-remove"></i>
                                    </span>
                                </button>
                            </Container>
                        </Alert>
                        <Alert color="danger" isOpen={resError && !resSuccess}
                            style={{
                                backgroundColor: "#FF3736",
                                textAlign: "center"
                            }}>
                            <Container>
                                <div className="alert-icon">
                                    <i className="now-ui-icons ui-1_bell-53"></i>
                                </div>
                                {resErrorText}
                                <button
                                    type="button"
                                    className="close" style={{ float: 'right', backgroundColor: 'transparent', border: 'none' }}
                                    onClick={() => setResError(false)}
                                >
                                    <span aria-hidden="true">
                                        <i className="now-ui-icons ui-1_simple-remove"></i>
                                    </span>
                                </button>
                            </Container>
                        </Alert>
                        <InputGroup
                            style={{
                                paddingBottom: "20px",
                                width: "95%",
                                marginLeft: "2.5%"
                            }}
                        >
                            <Input
                                placeholder="Yeni şifrə..."
                                type="password"
                                style={{
                                    backgroundColor: "transparent",
                                    color: fontColor,
                                    borderBottomLeftRadius: 15,
                                    borderTopLeftRadius: 15,
                                    borderColor: password === "" && resError ? colors.red : borderColor,
                                    width: "40%",
                                    fontSize: window.innerWidth > 1000 || !desktopView ? defFontSize / 4 : defFontSize / 4 - 10,
                                }}
                                onChange={(el) => { setPassword(el.target.value) }}
                                onKeyDown={(el) => {
                                    if (el.keyCode === 13) {
                                        if (password === "" || confirmPassword === "") resetError("Zəhmət olmasa bütün xanaları doldurun!")
                                        else reset()
                                    }
                                }}
                            ></Input>
                            <InputGroupText className="animateme" style={{
                                    backgroundColor: "transparent",
                                    color: password === "" && resError ? colors.red : fontColor,
                                    borderColor: password === "" && resError ? colors.red : borderColor,
                                    borderTopRightRadius: 15,
                                    borderBottomRightRadius: 15
                                }}>
                                    {password === "" && resError ? <i className="now-ui-icons ui-1_simple-remove"></i> : <></>}
                            </InputGroupText>
                        </InputGroup>
                        <InputGroup
                            style={{
                                paddingBottom: "20px",
                                width: "95%",
                                marginLeft: "2.5%"
                            }}
                        >
                            <Input
                                placeholder="Təkrar yeni şifrə..."
                                type="password"
                                style={{
                                    backgroundColor: "transparent",
                                    color: fontColor,
                                    borderBottomLeftRadius: 15,
                                    borderTopLeftRadius: 15,
                                    borderColor: confirmPassword === "" && resError ? colors.red : borderColor,
                                    width: "40%",
                                    fontSize: window.innerWidth > 1000 || !desktopView ? defFontSize / 4 : defFontSize / 4 - 10,
                                }}
                                onChange={(el) => { setConfirmPassword(el.target.value) }}
                                onKeyDown={(el) => {
                                    if (el.keyCode === 13) {
                                        if (password === "" || confirmPassword === "") resetError("Zəhmət olmasa bütün xanaları doldurun!")
                                        else reset()
                                    }
                                }}
                            ></Input>
                            <InputGroupText className="animateme" style={{
                                    backgroundColor: "transparent",
                                    color: confirmPassword === "" && resError ? colors.red : fontColor,
                                    borderColor: confirmPassword === "" && resError ? colors.red : borderColor,
                                    borderTopRightRadius: 15,
                                    borderBottomRightRadius: 15
                                }}>
                                    {confirmPassword === "" && resError ? <i className="now-ui-icons ui-1_simple-remove"></i> : <></>}
                            </InputGroupText>
                        </InputGroup>
                        <Button
                            className="btn-round button success animateme"
                            color="success"
                            outline
                            type="button"
                            style={{
                                width: desktopView ? "80%" : "95%",
                                marginLeft: desktopView ? "10%" : "2.5%",
                                fontSize: window.innerWidth > 500 ? 24 : 16,
                                marginBottom: "20px",
                            }}
                            onClick={() => {
                                if (password === "" || confirmPassword === "") resetError("Zəhmət olmasa bütün xanaları doldurun!")
                                else reset()
                            }}
                        >
                            Təsdiqlə
                        </Button>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default Reset;