import React from "react";
import { colors } from 'assets/js/theme';
import { useNavigate } from 'react-router-dom';

import {
    Input,
    InputGroupText,
    InputGroup,
    NavItem
} from "reactstrap";

export default function SearchInput({ rightFocus, setRightFocus, isHomePage }) {
    const navigate = useNavigate()
    const lightMode = localStorage.getItem("theme") === "light"
    const desktopView = window.innerWidth > 1300
    const fontColor = lightMode ? colors.darkGray : colors.white
    const [searchQuery, setSearchQuery] = React.useState('')
    const [addColor, setAddColor] = React.useState("transparent")
    const [addFont, setAddFont] = React.useState("#007bff")
    return (
        <InputGroup
            className={rightFocus ? "input-group-focus" : ""}
            style={ isHomePage ? {
                width: "80%",
                marginLeft: "10%",
                marginBottom: 0
            } : {}}
        >
            <Input
                placeholder="Axtar..."
                className="animateme"
                type="search"
                onFocus={() => setRightFocus(true)}
                onBlur={() => setRightFocus(false)}
                style={{
                    backgroundColor: "transparent",
                    color: fontColor,
                    borderColor: "#007bff",
                    width: isHomePage ? "40%" : "12.5vw",
                    fontSize: isHomePage ? 
                        desktopView ? 24 : 12 : 
                        desktopView ? 20 : "inherit",
                    minWidth: 200,
                    paddingLeft: isHomePage ? 30 : '',
                    lineHeight: isHomePage ? '' : '1.35rem' 
                }}
                onChange={(el) => { setSearchQuery(el.target.value) }}
                onKeyDown={(el) => {
                    if (el.keyCode === 13 && searchQuery !== '') {
                        navigate(`/search/?query=${searchQuery}`)
                        window.location.reload()
                    }
                }}
            ></Input>
            <InputGroupText className="animateme"
                    style={{
                        cursor: "pointer",
                        backgroundColor: addColor,
                        color: addFont,
                        borderColor: "#007bff",
                        border: "1px solid #007bff",
                        fontSize: isHomePage ? 
                            desktopView ? 30 : 18 :
                            desktopView ? 20 : "inherit",
                    }}
                    onClick={() => {
                        if (searchQuery !== '') {
                            navigate(`/search/?query=${searchQuery}`)
                            window.location.reload()
                        }
                    }}
                    onMouseEnter={() => { setAddColor("#007bff"); setAddFont(colors.white) }}
                    onMouseLeave={() => { setAddColor("transparent"); setAddFont("#007bff") }}
                >
                    { isHomePage ? 
                        <i className="now-ui-icons ui-1_zoom-bold"
                        style={desktopView ? {
                            paddingRight: 30,
                            paddingLeft: 60,
                            position: "relative",
                            right: 20
                        } :
                            {
                                paddingRight: 15,
                                paddingLeft: 30,
                                position: "relative",
                                right: 5
                            }
                        }></i> :
                        <i className="now-ui-icons ui-1_zoom-bold"
                            style={{
                                paddingRight: 15,
                                paddingLeft: 30,
                                position: "relative",
                                right: 5
                            }}></i>
                    }
            </InputGroupText>
        </InputGroup>
    )
}