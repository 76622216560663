import React from "react";
import { colors } from 'assets/js/theme';
import { useNavigate } from 'react-router-dom';

// backend components
import {
    signOut
} from "shared/request"

import {
    NavItem,
    Button
} from "reactstrap";

export default function CombinedButtons({setLogSuccess}) {
    const navigate = useNavigate()
    async function logout() {
        await signOut().then((res) => {
            localStorage.removeItem("authorization")
            setLogSuccess(true)
            setTimeout(() => {
                navigate('/home')
            }, 1000)
        })
    }
    return (
        <NavItem>
            <Button
                className="btn animateme button success animateme"
                color="success"
                outline
                type="button"
                style={{
                    width: `calc(50% - 1px)`,
                    borderRadius: "30px 0px 0px 30px",
                    margin: 0,
                    marginBottom: "10px",
                }}
                onClick={() => {
                    navigate(`/profile`)
                }}
            >
                Hesaba Keç
            </Button>
            <Button
                className="btn animateme button success animateme"
                color="success"
                outline
                type="button"
                style={{
                    width: `calc(50% - 1px)`,
                    borderRadius: "0px 30px 30px 0px",
                    margin: 0,
                    marginBottom: "10px",
                    borderLeft: "none",
                }}
                onClick={() => { logout() }}
            >
                Hesabdan Çıx
            </Button>
        </NavItem>
    )
}