import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

//! styles for this kit
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
import "assets/css/custom.css"
// import 'mdb-react-ui-kit/dist/css/mdb.min.css';
// import "@fortawesome/fontawesome-free/css/all.min.css";

//! user pages
import Home from "./views/Home"
import Profile from "./views/Profile"
import Search from "./views/Search"
import Article from "./views/Article"
import Authentication from "./views/Authentication"
import Post from "./views/Post"
import Reset from "./views/Reset"
import Edit from "./views/Edit"
import Course from './views/Course'

//! background color setter
import BackgroundColorSetter from "components/Misc/BackgroundColorSetter";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <BackgroundColorSetter />
    <Routes>
      <Route path="/" element={<Navigate to="/home" replace />} />
      <Route path="/home" element={<Home />} />
      <Route path="/search/:query" element={<Search />} />
      <Route path="/search" element={<Search />} />
      <Route path="/article/:term" element={<Article />} />
      <Route path="/article" element={<Article />} />
      <Route path="/authentication/:token" element={<Authentication />} />
      <Route path="/authentication" element={<Authentication />} />
      <Route path='/reset' element={<Reset />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/edit/:term" element={<Edit />} />
      <Route path="/post" element={<Post />} />
      <Route path="/course" element={<Course />} />
    </Routes>
  </BrowserRouter>
);

