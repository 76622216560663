import React from 'react';
import { colors } from 'assets/js/theme';import { Markup } from 'interweave';

export default function ArticleFullCard({ term, author, pubDate, definition, page}) {
    const article = page === 'article'
    const monthNames = ["Yan", "Fev", "Mart", "Apr", "May", "İyun",
        "İyul", "Avq", "Sen", "Okt", "Noy", "Dek"
    ];
    const defWidth = "80%"
    const defMargin = "10%"
    const desktopView = window.innerWidth > 810
    const lightMode = localStorage.getItem("theme") === "light"
    const borderColor = lightMode ? colors.darkGray : colors.gray
    const cardBgColor = lightMode ? colors.lightGray : colors.darkGray
    const cardFontColor = lightMode ? colors.darkGray : colors.lightGray
    const bgColor = lightMode ? colors.white : colors.darkGray
    const defFontSize = desktopView ? 70 : 50;

    var addRule = (function (style) {
        var sheet = document.head.appendChild(style).sheet;
        return function (selector, css) {
            var propText = typeof css === "string" ? css : Object.keys(css).map(function (p) {
                return p + ":" + (p === "content" ? "'" + css[p] + "'" : css[p]);
            }).join(";");
            sheet.insertRule(selector + "{" + propText + "}", sheet.cssRules.length);
        };
    })(document.createElement("style"));

    addRule('#card *:not(p)', {
        transition: 'color 0s ease 0s !important'
    })

    addRule("h1:not(.titleh1)", {
        "font-size": defFontSize / 2 + "px !important",
        "margin-bottom": "10px !important"
    })

    addRule("h2", {
        "font-size": defFontSize / 3 + "px !important",
        "margin-bottom": "10px !important"
    })

    addRule("h3:not(.notme)", {
        "font-size": defFontSize / 4 + "px !important",
        "margin-bottom": "10px !important"
    })

    addRule("h3.animateme", {
        "font-size": "1.825em !important"
    })

    addRule("p", {
        color: lightMode ? "black !important" : "white !important",
        transition: 'all 0.3s ease !important'
    })

    addRule("span", {
        "font-size": defFontSize / 4 + "px"
    })

    addRule("blockquote", {
        "border-color": lightMode ? colors.gray : "#333"
    })

    addRule("li a:not(.animateme):not(.nav-link)", {
        color: lightMode ? "#007bff !important" : "#f96332 !important"
    })


    return (
        <>
            <h1
                className={"animateme titleh1"}
                style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    paddingTop: "3vh",
                    paddingBottom: "2vh",
                    color: cardFontColor,
                    fontSize: window.innerWidth > 1350 ? 70 : !desktopView ? 25 : defFontSize - 20,
                    overflowY: "auto",
                    maxWidth: "100%",
                    textAlign: "center"
                }}>{term}
            </h1>
            <div style={{
                marginLeft: article ? defMargin : 0,
                width: article ? defWidth : '100%',
                minHeight: desktopView ? "50px" : "100px"
            }}>
                <div
                    className={"animateme"}
                    style={{
                        width: desktopView ? "50%" : "100%",
                        backgroundColor: borderColor,
                        color: bgColor,
                        float: "left",
                        border: `1px solid ${borderColor}`,
                        borderRadius: desktopView ? "30px 0px 0px 0px" : "30px 30px 0px 0px",
                        height: "50px",
                        textAlign: "center",
                        fontSize: "1.7em",
                        paddingTop: "5px"
                    }}
                >
                    {author}
                </div>
                <div
                    className={"animateme"}
                    style={{
                        width: desktopView ? '50%' : "100%",
                        backgroundColor: borderColor,
                        color: bgColor,
                        float: "right",
                        border: `1px solid ${borderColor}`,
                        paddingLeft: 4,
                        borderRadius: desktopView ? "0px 30px 0px 0px" : "0px",
                        height: "50px",
                        textAlign: "center",
                        fontSize: "1.7em",
                        paddingTop: "5px"
                    }}
                >
                    {pubDate ? pubDate.getDate() + " " +
                        monthNames[pubDate.getMonth()] + " " +
                        pubDate.getFullYear() + " " +
                        pubDate.getHours() + ":" +
                        (pubDate.getMinutes() < 10 ? "0" + pubDate.getMinutes() : pubDate.getMinutes()) : ""}
                </div>
            </div>
            <div
                className={"animateme"}
                id={"card"}
                style={{
                    marginLeft: article ? defMargin : 0,
                    width: article ? defWidth : '100%',
                    backgroundColor: cardBgColor,
                    color: cardFontColor,
                    border: `1px solid ${borderColor}`,
                    borderRadius: "0 0 30px 30px",
                    minHeight: article ? "40vh" : `calc(100vh - 290px - 
                        ${!desktopView ? '150px' : '152px'})`,
                    marginBottom: "30px",
                }}>
                <div style={{
                    fontSize: desktopView ? "auto" : 12,
                    marginTop: "20px",
                    marginLeft: "5%",
                    marginRight: "5%",
                    marginBottom: "30px",
                    overflowX: "auto"
                }} >
                    <Markup content={definition} />
                </div>
            </div>
        </>
    )
}
