import React from "react"
import { colors } from 'assets/js/theme';
import { useNavigate } from 'react-router-dom';

// request components
import {
    loginUser,
    forgetPassword,
    loginAdmin
} from "shared/request.js"

// sections for this page
import {
    TabPane,
    Button,
    Input,
    InputGroupText,
    InputGroup,
    Alert,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Container,
} from "reactstrap"


function LoginForm({ mode }) {
    const lightMode = mode
    const [sendMailColor, setSendMailColor] = React.useState(lightMode ? colors.darkGray : colors.white)
    React.useEffect(() => {
        setSendMailColor(lightMode ? colors.darkGray : colors.white)
    }, [lightMode])
    const navigate = useNavigate()
    const desktopView = window.innerWidth > 810
    const fontColor = lightMode ? colors.darkGray : colors.white
    const borderColor = lightMode ? colors.darkGray : colors.gray
    const defFontSize = desktopView ? 100 : 70;
    const [rightFocus, setRightFocus] = React.useState(false);
    const [logUsername, setLogUsername] = React.useState("")
    const [logPassword, setLogPassword] = React.useState("")
    const [logError, setLogError] = React.useState(false)
    const [logErrorText, setLogErrorText] = React.useState("")
    const [logSuccess, setLogSuccess] = React.useState(false)
    const [passColor, setPassColor] = React.useState("transparent")
    const [passFont, setPassFont] = React.useState("#dc3545")
    const [forgotEmail, setForgotEmail] = React.useState("")
    const [theForgotError, setTheForgotError] = React.useState(false)
    const [forgotSuccess, setForgotSuccess] = React.useState(false)
    const [forgotErrorText, setForgotErrorText] = React.useState("")
    const [sendMailBg, setSendMailBg] = React.useState("transparent")
    // const [unsuccessful, setUnsuccessful] = React.useState(false)
    function loginError(errorMessage) {
        setTheForgotError(false)
        setLogError(true)
        setLogErrorText(errorMessage)
        setTimeout(() => {
            setLogError(false)
        }, 2000)
    }

    function forgotError(errorMessage) {
        setLogError(false)
        setTheForgotError(true)
        setForgotErrorText(errorMessage)
        setTimeout(() => {
            setTheForgotError(false)
        }, 2000)
    }

    var addRule = (function (style) {
        var sheet = document.head.appendChild(style).sheet;
        return function (selector, css) {
            var propText = typeof css === "string" ? css : Object.keys(css).map(function (p) {
                return p + ":" + (p === "content" ? "'" + css[p] + "'" : css[p]);
            }).join(";");
            sheet.insertRule(selector + "{" + propText + "}", sheet.cssRules.length);
        };
    })(document.createElement("style"));

    addRule("li.dropdown", {
        "list-style": "none"
    })

    async function login() {

        let successful = true
        await loginAdmin({ username: logUsername, password: logPassword })
            .then((res) => {
                const token = res.data.token;
                localStorage.setItem("authorization", token)
                setLogSuccess(true)
                setTimeout(() => {
                    navigate(`/home`)
                }, 2000)
            })
            .catch(error => {
                if (error.response) {
                    successful = false
                    console.log(error.response)
                }
                else {
                    console.log('unknown error')
                }
            })


        await loginUser({ username: logUsername, password: logPassword })
            .then((res) => {
                const token = res.data.token;
                localStorage.setItem("authorization", token)
                setLogSuccess(true)
                setTimeout(() => {
                    navigate(`/home`)
                }, 2000)
            })
            .catch(error => {
                if (error.response && !successful) {
                    if (error.response.data.error === "Username or password is not correct!") {
                        loginError("İstifadəçi adı və ya şifrə səhvdir!")
                    }
                    else if (error.response.data.error === "Please verify your account!") {
                        loginError("Zəhmət olmasa, hesabınızı təsdiqləyin!")
                    }
                }
            })
    }

    async function forgot() {
        await forgetPassword({ email: forgotEmail }).then((res) => {
            setForgotSuccess(true)
            setTimeout(() => {
                setForgotSuccess(false)
            }, 10000)
        })
            .catch(error => {
                if (error.response) {
                    if (error.response.data.error === "User with that email does not exist!") {
                        forgotError("Bu e-poçt ünvanı ilə hesab mövcud deyil!")
                    }
                    else if (error.response.data.error === "Active reset token already exists for that account!") {
                        forgotError("Bu e-poçt ünvanında aktiv açar mövcuddur!")
                    }
                }
            })
    }

    return (
        <TabPane tabId="login">
            <Alert color="success" isOpen={logSuccess && !theForgotError && !logError && !forgotSuccess}>
                <Container>
                    <div className="alert-icon">
                        <i className="now-ui-icons ui-2_like"></i>
                    </div>
                    Siz uğurla hesabınıza daxil oldunuz!
                    <button
                        type="button"
                        className="close" style={{ float: 'right', backgroundColor: 'transparent', border: 'none' }}
                        onClick={() => setLogSuccess(false)}
                    >
                        <span aria-hidden="true">
                            <i className="now-ui-icons ui-1_simple-remove"></i>
                        </span>
                    </button>
                </Container>
            </Alert>
            <Alert color="success" isOpen={forgotSuccess && !logSuccess && !theForgotError && !logError}>
                <Container>
                    <div className="alert-icon">
                        <i className="now-ui-icons ui-2_like"></i>
                    </div>
                    Növbəti addımlar üçün e-poçt ünvanınızı yoxlayın!
                    <button
                        type="button"
                        className="close" style={{ float: 'right', backgroundColor: 'transparent', border: 'none' }}
                        onClick={() => setForgotSuccess(false)}
                    >
                        <span aria-hidden="true">
                            <i className="now-ui-icons ui-1_simple-remove"></i>
                        </span>
                    </button>
                </Container>
            </Alert>
            <Alert color="danger" isOpen={theForgotError && !logSuccess && !logError && !forgotSuccess}>
                <Container>
                    <div className="alert-icon">
                        <i className="now-ui-icons ui-1_bell-53"></i>
                    </div>
                    {forgotErrorText}
                    <button
                        type="button"
                        className="close" style={{ float: 'right', backgroundColor: 'transparent', border: 'none' }}
                        onClick={() => setLogError(false)}
                    >
                        <span aria-hidden="true">
                            <i className="now-ui-icons ui-1_simple-remove"></i>
                        </span>
                    </button>
                </Container>
            </Alert>
            <Alert color="danger" isOpen={logError && !logSuccess && !theForgotError && !forgotSuccess}>
                <Container>
                    <div className="alert-icon">
                        <i className="now-ui-icons ui-1_bell-53"></i>
                    </div>
                    {logErrorText}
                    <button
                        type="button"
                        className="close" style={{ float: 'right', backgroundColor: 'transparent', border: 'none' }}
                        onClick={() => setLogError(false)}
                    >
                        <span aria-hidden="true">
                            <i className="now-ui-icons ui-1_simple-remove"></i>
                        </span>
                    </button>
                </Container>
            </Alert>
            <InputGroup
                className={rightFocus ? "input-group-focus" : ""}
                style={{
                    paddingBottom: "20px"
                }}
            >
                <Input
                    placeholder="İstifadəçi adı..."
                    type="text"
                    onFocus={() => setRightFocus(true)}
                    onBlur={() => setRightFocus(false)}
                    style={{
                        backgroundColor: "transparent",
                        color: fontColor,
                        borderBottomLeftRadius: 15,
                        borderTopLeftRadius: 15,
                        borderColor: logUsername === "" && logError ? colors.red : borderColor,
                        width: "40%",
                        fontSize: window.innerWidth > 1000 || !desktopView ? defFontSize / 4 : defFontSize / 4 - 10,
                    }}
                    onChange={(el) => { setLogUsername(el.target.value) }}
                    onKeyDown={(el) => {
                        if (el.keyCode === 13) {
                            if (logUsername === "" || logPassword === "") loginError("Zəhmət olmasa bütün xanaları doldurun!")
                            else login()
                        }
                    }}
                >
                </Input>
                <InputGroupText className="animateme" style={{
                        backgroundColor: "transparent",
                        color: logUsername === "" && logError ? colors.red : fontColor,
                        borderColor: logUsername === "" && logError ? colors.red : borderColor,
                        borderTopRightRadius: 15,
                        borderBottomRightRadius: 15
                    }}>
                        {logUsername === "" && logError ? <i className="now-ui-icons ui-1_simple-remove"></i> : <></>}
                </InputGroupText>
            </InputGroup>
            <InputGroup
                className={rightFocus ? "input-group-focus" : ""}
                style={{
                    paddingBottom: "20px"
                }}
            >
                <Input
                    placeholder="Şifrə..."
                    type="password"
                    onFocus={() => setRightFocus(true)}
                    onBlur={() => setRightFocus(false)}
                    style={{
                        backgroundColor: "transparent",
                        color: fontColor,
                        borderBottomLeftRadius: 15,
                        borderTopLeftRadius: 15,
                        borderColor: logPassword === "" && logError ? colors.red : borderColor,
                        width: "40%",
                        fontSize: window.innerWidth > 1000 || !desktopView ? defFontSize / 4 : defFontSize / 4 - 10,
                    }}
                    onChange={(el) => { setLogPassword(el.target.value) }}
                    onKeyDown={(el) => {
                        if (el.keyCode === 13) {
                            if (logUsername === "" || logPassword === "") loginError("Zəhmət olmasa bütün xanaları doldurun!")
                            else login()
                        }
                    }}
                >
                </Input>
                <InputGroupText className="animateme" style={{
                        backgroundColor: "transparent",
                        color: logPassword === "" && logError ? colors.red : fontColor,
                        borderColor: logPassword === "" && logError ? colors.red : borderColor,
                        borderTopRightRadius: 15,
                        borderBottomRightRadius: 15
                    }}>
                        {logPassword === "" && logError ? <i className="now-ui-icons ui-1_simple-remove"></i> : <></>}
                </InputGroupText>
            </InputGroup>
            <Button
                className="btn-round button success animateme"
                color="success"
                outline
                type="button"
                style={{
                    width: desktopView ? "80%" : "100%",
                    fontSize: window.innerWidth > 500 ? 24 : 16,
                }}
                onClick={() => {
                    if (logUsername === "" || logPassword === "") loginError("Zəhmət olmasa bütün xanaları doldurun!")
                    else login()
                }}
            >
                Daxil Ol
            </Button>
            <UncontrolledDropdown nav className={"animateme"} style={{
                marginTop: "20px"
            }}>
                <DropdownToggle
                    className={"animateme"}
                    caret
                    nav
                    onClick={(e) => e.preventDefault()}
                    style={{
                        fontSize: window.innerWidth > 500 ? 24 : 16,
                        border: `1px solid #dc3545`,
                        borderRadius: "30px",
                        color: passFont,
                        padding: "10px 22px",
                        backgroundColor: passColor,
                        textTransform: "none",
                        width: desktopView ? "80%" : "100%",
                        marginLeft: desktopView ? "10%" : 0,
                        cursor: "pointer"
                    }}
                    onMouseEnter={() => { setPassColor("#dc3545"); setPassFont(colors.white) }}
                    onMouseLeave={() => { setPassColor("transparent"); setPassFont("#dc3545") }}
                >
                    Şifrəmi Unutdum
                </DropdownToggle>
                <DropdownMenu right
                    className={"animateme"}
                    style={{
                        backgroundColor: lightMode ? colors.lightGray : colors.darkGray,
                        marginTop: 10,
                        border: `1px solid ${borderColor}`,
                        borderRadius: "20px",
                        width: "100%"
                    }}>
                    <InputGroup style={{
                        width: "95%",
                        marginTop: "10px",
                        marginLeft: "2.5%"
                    }}>
                        <Input
                            placeholder="E-poçt Ünvanı..."
                            type="email"
                            className={"animateme"}
                            style={{
                                backgroundColor: "transparent",
                                color: fontColor,
                                borderBottomLeftRadius: 15,
                                borderTopLeftRadius: 15,
                                borderRight: "none",
                                borderColor: theForgotError ? colors.red : borderColor,
                                fontSize: window.innerWidth > 1000 || !desktopView ? defFontSize / 4 : defFontSize / 4 - 10,
                            }}
                            onChange={(el) => { setForgotEmail(el.target.value) }}
                            onKeyDown={(el) => {
                                if (el.keyCode === 13) {
                                    if (forgotEmail === "") forgotError("E-poçt ünvanı daxil edilməyib!")
                                    else forgot()
                                }
                            }}
                            onClick={(e) => e.preventDefault()}

                        >
                        </Input>
                        <InputGroupText className="animateme" style={{
                                backgroundColor: theForgotError ? "transparent" : sendMailBg,
                                color: theForgotError ? colors.red : sendMailColor,
                                borderColor: theForgotError ? colors.red : borderColor,
                                borderTopRightRadius: 15,
                                borderBottomRightRadius: 15,
                                paddingLeft: "30px",
                                paddingRight: "25px",
                                cursor: theForgotError ? "auto" : "pointer",
                                borderLeft: (theForgotError ? "0px solid " : "1px solid ") + borderColor,
                            }}
                                onMouseEnter={() => { setSendMailBg(borderColor); setSendMailColor(colors.white) }}
                                onMouseLeave={() => { setSendMailBg("transparent"); setSendMailColor(fontColor) }}
                                onClick={() => {
                                    if (!theForgotError) {
                                        if (forgotEmail === "") forgotError("E-poçt ünvanı daxil edilməyib!")
                                        else forgot()
                                    }
                                }}
                                >
                                {theForgotError ? <i className="now-ui-icons ui-1_simple-remove"></i> :
                                    <>
                                        <i className="now-ui-icons ui-1_check"
                                            onClick={() => {
                                                if (forgotEmail === "") forgotError("E-poçt ünvanı daxil edilməyib!")
                                                else forgot()
                                            }}
                                        ></i>
                                    </>}
                        </InputGroupText>
                    </InputGroup>
                </DropdownMenu>
            </UncontrolledDropdown >
        </TabPane >
    )
}

export default LoginForm