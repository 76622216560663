import React from "react";
import { colors } from "assets/js/theme";

// core components
import MainFooter from "components/Footers/MainFooter.js"
import UserCard from "components/Cards/Profile/UserCard.js";
import ChangePasswordForm from "components/Forms/ChangePasswordForm.js"
import CardHeaderComponent from "components/Cards/CardHeaderComponent.js"
import Notification from "components/Notifications/Notification";
import Identicon from "components/SVG/Identicon";
import Loading from "components/SVG/Loading";
import PaginationTable from "components/Pagination/PaginationTable";
import MobileHeader from "components/Profiles/ProfileItems/UserProfile/MobileHeader";

// backend components
import {
    getUser,
    getMyArticles,
    getMyNotifications,
    deleteNotification
} from "shared/request"

// sections for this page
import {
    Card,
    CardBody,
    TabContent,
    TabPane,
    Button
} from "reactstrap";


export default function UserProfile() {
    const [done, setDone] = React.useState(false)
    const desktopView = window.innerWidth > 810
    const [page, setPage] = React.useState(1)
    const [page1, setPage1] = React.useState(1)
    const [suggestions, setSuggestions] = React.useState([])
    const [notifications, setNotifications] = React.useState([])
    const [posts, setPosts] = React.useState([])
    const [postsPageAmount, setPostsPageAmount] = React.useState(1)
    const [suggestionsPageAmount, setSuggestionsPageAmount] = React.useState(1)
    const [username, setUsername] = React.useState('')
    const [height, setHeight] = React.useState(0)
    const [show, setShow] = React.useState(false)
    const curPosts = posts.slice((page - 1) * 10, page * 10)
    const curSuggestions = suggestions.slice((page1 - 1) * 10, page1 * 10)
    const [mode, setMode] = React.useState("posted")
    const defWidth = "80%"
    const defMargin = "10%"
    const lightMode = localStorage.getItem("theme") === "light"
    const borderColor = lightMode ? colors.darkGray : colors.lightGray
    const bgColor = lightMode ? colors.white : colors.darkGray
    const fontColor = lightMode ? colors.darkGray : colors.lightGray
    const defFontSize = desktopView ? 70 : 50;
    const cardBgColor = lightMode ? colors.lightGray : colors.darkGray

    React.useEffect(() => {
        window.scrollTo({
            top: -1,
            left: -1,
            behavior: 'smooth',
        })
        if (localStorage.getItem("theme") !== "dark" && localStorage.getItem("theme") !== "light") {
            localStorage.setItem("theme", "light")
        }
        const process = async () => {
            await getUser()
                .then((result) => {
                    if (result.data.success) {
                        setUsername(result.data.result.username);
                    };
                });
    
            await getMyArticles()
                .then(res => {
                    var articles = res.data.result.articles;
                    articles.forEach(article => {
                        article["status"] = "posted";
                    })
                    var suggestions1 = res.data.result.suggestions;
                    suggestions1.forEach(suggestion => {
                        suggestion["status"] = "pending";
                    })
                    setSuggestionsPageAmount(Math.floor(suggestions1.length / 11) + 1)
                    setPostsPageAmount(Math.floor(articles.length / 11) + 1)
                    setPosts(articles)
                    setSuggestions(suggestions1)
                    setDone(true);
                });
    
            await getMyNotifications()
                .then(res => {
                    setNotifications(res.data.result.notifications)
                })
        }
        process();
    }, [])

    React.useEffect((deps = [mode]) => {
        var totalHeight = 50
        let cardBody = document.getElementsByClassName('card-body')[0]
        if (cardBody)
            totalHeight += cardBody.clientHeight
        setHeight(totalHeight)
    })

    async function removeNoty(id) {
        setNotifications(notifications.slice(0, id).concat(notifications.slice(id + 1)))
        await deleteNotification(id)
    }

    return (
        <>
            <div className="wrapper animateme" style={{
                backgroundColor: bgColor,
            }}>
                <div className='animateme' id='cover' style={{
                    height: show ? "calc(100vh - 100px)" : 0,
                    overflow: 'hidden',
                    width: desktopView ? '800px' : "90vw",
                    position: 'fixed',
                    top: "80px",
                    left: desktopView ? 'calc(50vw - 400px)' : '5vw',
                    backgroundColor: cardBgColor,
                    color: fontColor,
                    border: `${show ? '1' : '0'}px solid ${borderColor}`,
                    borderRadius: 30,
                    zIndex: "1040",
                    padding: show ? '15px' : 0,
                }}>
                    <div style={{ width: '100%', paddingBottom: 20 }}>
                        <Button style={{
                            marginLeft: 20,
                            marginRight: 20,
                            borderRadius: 30,
                            backgroundColor: fontColor,
                            padding: !desktopView ? '7px 17px' : '',
                            cursor: 'pointer'
                        }} className={'animateme'} onClick={() => setShow(false)}>
                            <i className={"fas fa-times button animateme"} style={{
                                color: bgColor,
                                cursor: 'pointer'
                            }} onClick={() => setShow(false)}></i>
                        </Button>
                    </div>
                    <div style={{ maxHeight: '75vh', overflowY: 'auto' }}>
                        {notifications.map((noty, index) =>
                            <Notification
                                suggestion={
                                    noty.status === 'posted' ||
                                    noty.status === 'removed'
                                }
                                state={
                                    noty.status === 'posted' ||
                                    noty.status === 'approved'
                                }
                                id={index}
                                term={noty.article}
                                remove={removeNoty}
                            />
                        )}
                    </div>
                </div>
                <div className="main animateme" style={{
                    maxWidth: desktopView && window.innerWidth > 1250 ? "1250px" : "100vw",
                    marginLeft: desktopView && window.innerWidth > 1250 ? "calc(50vw - 625px)" : "0vw",
                    backgroundColor: bgColor,
                    minHeight: "100vh",
                    height: desktopView ? (posts.length < 2 && suggestions.length < 2 ? "100vh" :
                        height + 410 + "px") : (height > window.innerHeight - 970 ?
                            1121 + height : "100vh"),
                    paddingTop: desktopView ? "76px" : "55px"
                }}>
                    <div id="left" style={{
                        width: desktopView ? "50%" : "100%",
                        float: "left"
                    }}>
                        {!done ? <Loading page='profile' /> :
                            <>
                                {done && !desktopView ?
                                    <MobileHeader setShow={setShow}
                                        username={username}
                                        notificationsCount={notifications.length}
                                        totalCount={suggestions.length + posts.length}
                                    />
                                    :
                                    <></>
                                }
                                <h3 style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    paddingTop: desktopView ? "3vh" : "0",
                                    color: fontColor,
                                    fontSize: defFontSize / 2,
                                    marginLeft: defMargin,
                                    overflow: "auto",
                                    maxWidth: defWidth,
                                    textAlign: desktopView ? "left" : "center"
                                }}
                                    className={"animateme"}
                                > {suggestions.length + posts.length > 0 ? `Sizin məqalələriniz:` : `Təəssüf ki, sizin məqaləniz yoxdur!`}
                                </h3>
                                <Card className={"animateme"}
                                    style={{
                                        width: "80%",
                                        marginLeft: "10%",
                                        backgroundColor: lightMode ? colors.lightGray : colors.darkGray,
                                        boxShadow: "none",
                                        border: `1px solid ${borderColor}`,
                                        borderRadius: "25px"
                                    }}
                                    id={"card"}>
                                    <CardHeaderComponent
                                        page="profile"
                                        mode={mode}
                                        modes={["posted", "suggested"]}
                                        texts={["Dərc Edilmiş", "Təklif Edilmiş"]}
                                        setMode={setMode}
                                    />
                                    <CardBody>
                                        <TabContent
                                            className="text-center animateme"
                                            activeTab={mode}
                                        >
                                            <TabPane tabId="posted">
                                                {curPosts.map(result => <UserCard article={result} mode={lightMode} />)}
                                                <PaginationTable currentPage={page} 
                                                    setPage={setPage} 
                                                    totalPageCount={postsPageAmount} 
                                                />
                                            </TabPane>
                                            <TabPane tabId="suggested">
                                                {curSuggestions.map(result => <UserCard article={result} mode={lightMode} />)}
                                                <PaginationTable currentPage={page1} 
                                                    setPage={setPage1} 
                                                    totalPageCount={suggestionsPageAmount} 
                                                />
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </>
                        }
                    </div>
                    <div id="right" style={{
                        width: desktopView ? "50%" : "100%",
                        float: "right",
                        marginTop: "4vh"
                    }}>
                        {done ? desktopView ?
                            <>
                                <div className="animateme"
                                    style={{
                                        border: `1px solid ${borderColor}`,
                                        borderRadius: 30,
                                        backgroundColor: cardBgColor,
                                        width: "90%",
                                        marginLeft: "5%",
                                    }}>
                                    <div className="animateme"
                                        style={{
                                            backgroundColor: borderColor, 
                                            height: 200,
                                            borderRadius: "30px 30px 0px 0px",
                                        }}>
                                        <div className="animateme"
                                            style={{
                                                width: "40%",
                                                float: "left",
                                                borderRadius: "30px 0px 0px 0px",
                                                backgroundColor: borderColor,
                                                height: 189,
                                                paddingTop: 37
                                            }}>
                                            <Identicon username={username} />
                                        </div>
                                        <div className='animateme'
                                            style={{
                                                width: "60%",
                                                float: "right",
                                                borderRadius: "0px 30px 0px 0px",
                                                backgroundColor: borderColor
                                            }}>
                                            <h3
                                                className={"animateme notme"}
                                                style={{
                                                    color: bgColor,
                                                    textAlign: "center",
                                                    width: "100%",
                                                    marginBottom: "0px",
                                                    paddingTop: "15px",
                                                    marginTop: "0px",
                                                    paddingBottom: "15px",
                                                    textTransform: "uppercase",
                                                    fontFamily: 'Montserrat sans-serif',
                                                    fontWeight: 400
                                                }}>
                                                    {username}
                                            </h3>
                                            <h4
                                                className={"animateme"}
                                                style={{
                                                    color: bgColor,
                                                    marginBottom: "20px",
                                                    paddingBottom: "0px",
                                                    marginTop: "0px",
                                                    textAlign: "center"
                                                }}>Məqalələrin Sayı: {suggestions.length + posts.length}
                                            </h4>
                                            <Button
                                                className={`btn-round button ${notifications.length > 0 ? 'success' : 'danger'} animateme`}
                                                color={`${notifications.length > 0 ? 'success' : 'danger'}`}
                                                outline
                                                type="button"
                                                style={{
                                                    width: "80%",
                                                    marginLeft: "10%",
                                                }}
                                                onClick={(e) => {
                                                    notifications.length > 0 ? setShow(true) : e.preventDefault()
                                                }}
                                            >
                                                {`Bildirişlər (${notifications.length})`}
                                            </Button>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            width: "100%",
                                        }} className='animateme'>
                                        <h3
                                            className={"animateme"}
                                            style={{
                                                width: "100%",
                                                color: fontColor,
                                                marginBottom: 20,
                                                marginTop: 40,
                                                textAlign: "center"
                                            }}>Şifrəni Dəyiş
                                        </h3>
                                        <ChangePasswordForm user='USER'/>
                                    </div>
                                </div>
                            </>
                            :
                            <div style={{
                                border: `1px solid ${borderColor}`,
                                borderRadius: "30px",
                                backgroundColor: cardBgColor,
                                width: "80%",
                                marginLeft: "10%",
                                paddingBottom: "10px",
                                marginBottom: 50
                            }} className="animateme">
                                <h3
                                    className={"animateme"}
                                    style={{
                                        color: fontColor,
                                        marginBottom: 20,
                                        marginTop: 20,
                                        textAlign: "center"
                                    }}>Şifrəni Dəyiş
                                </h3>
                                <ChangePasswordForm user='USER'/>
                            </div> : <></>
                        }
                    </div>
                </div>
                <MainFooter style={{ position: "fixed", bottom: 0, height: "3vh" }} />
            </div>
        </>
    )
}