import React from "react";
import { colors } from 'assets/js/theme';
import Identicon from "components/SVG/Identicon";

import {
    Button
} from "reactstrap";

export default function MobileHeader({ username, users, articles }) {
    const lightMode = localStorage.getItem("theme") === "light"
    const borderColor = lightMode ? colors.darkGray : colors.lightGray
    const bgColor = lightMode ? colors.white : colors.darkGray
    return (
        <div className="animateme" style={{
            borderRadius: "30px",
            backgroundColor: borderColor,
            width: "80%",
            margin: '35px 0 50px 10%',
            paddingBottom: 10
        }}>
            <div
                className='animateme'
                style={{
                    height: "115px",
                    paddingTop: 20
                }}>
                <Identicon username={username} />
            </div>
            <h3
                className={"animateme notme"}
                style={{
                    color: colors.white,
                    margin: 0,
                    padding: "15px 0",
                    textAlign: "center",
                    fontFamily: 'Montserrat sans-serif',
                    fontWeight: 400,
                    color: bgColor,
                }}>@{username.toUpperCase()}
            </h3>
            <h4
                className={"animateme"}
                style={{
                    color: colors.white,
                    margin: 0,
                    paddingBottom: 10,
                    textAlign: "center",
                    color: bgColor,
                }}>{`
                    Ümumi İstifadəçi: ${users.number_of_users}`} <br></br> {`
                    Yeni İstifadəçi: ${users.number_of_today_registered}`} <br></br> {`
                    Ümumi Məqalə: ${articles.number_of_articles}`} <br></br> {`
                    Yeni Məqalə: ${articles.number_of_today_published}
                `}
            </h4>
        </div>
    )
}