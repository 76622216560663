import React from "react";
import { colors } from 'assets/js/theme';
import { useNavigate } from 'react-router-dom';

import {
    NavItem,
    Button
} from "reactstrap";

export default function LoginButton() {
    const navigate = useNavigate()
    const desktopView = window.innerWidth > 1300
    return (
        <NavItem>
            <Button
                className="btn-round button success animateme"
                color="success"
                outline
                type="button"
                style={{
                    margin: "0px",
                    fontSize: desktopView ? 20 : 'auto',
                    width: desktopView ? 'auto' : '100%',
                    marginBottom: desktopView ? 'auto' : 10 
                }}
                onClick={() => {
                    navigate('/authentication')
                }}
            >
                Daxil Ol
            </Button>
        </NavItem>
    )
}