import React from "react";
import { colors } from 'assets/js/theme';
import { useLocation, useNavigate } from 'react-router-dom';

// core components
// import ProfilePageHeader from "components/Headers/ProfilePageHeader";
import MainFooter from "components/Footers/MainFooter.js";
import ArticleCard from "components/Cards/ArticleCard.js"
import Keyboard from "components/Keys/Keyboard";
import PublicNavbar from "components/Navbars/PublicNavbar.js";
import UserNavbar from "components/Navbars/UserNavbar.js";
import PaginationTable from "components/Pagination/PaginationTable";

// backend components
import {
    getUser,
    searchArticle,
    searchLetterArticles,
    searchDigitArticles
} from "shared/request"
import Loading from "components/SVG/Loading";


function Search() {
    const [authorized, setAuthorized] = React.useState(false)
    const [done, setDone] = React.useState(false)
    const [height, setHeight] = React.useState(0)
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('query');
    const navigate = useNavigate()
    React.useEffect( () => {
        window.scrollTo({
            top: -1,
            left: -1,
            behavior: 'smooth',
        })
        if (localStorage.getItem("theme") !== "dark" && localStorage.getItem("theme") !== "light") {
            localStorage.setItem("theme", "light")
        }
        
        // if (window.location.protocol === 'http:') {
            //     window.location.href =
            //         window.location.href.replace(
                //             'http:', 'https:');
                // }
        const process = async () => {
            await getUser()
                .then((result) => {
                    if (result.data.success) { setAuthorized(true); setDone(true) };
                }).catch((err) => {
                    setAuthorized(false)
                    setDone(true)
                });
            if (query === "0-9") {
                await searchDigitArticles()
                    .then((res) => {
                        setPageAmount(Math.floor(res.data.result.length / 11) + 1)
                        setResults(res.data.result)
                        setSearchQuery(query)
                    })
            }
            else if (/^([A-Za-z]{1})$/.test(query)) {
                await searchLetterArticles(query)
                    .then((res) => {
                        setPageAmount(Math.floor(res.data.result.length / 11) + 1)
                        setResults(res.data.result)
                        setSearchQuery(query)
                    })
            }
            else if (query) {
                await searchArticle(query)
                    .then((res) => {
                        setPageAmount(Math.floor(res.data.result.length / 11) + 1)
                        setResults(res.data.result)
                        setSearchQuery(query)
                    })
            }
            else if (!query) { 
                navigate(`/home`)
            }
        }
        process();

        var articleDivs = document.getElementsByClassName("articleDiv")
        if (articleDivs.length > 0) {
            var height1 = 0
            for (let i = 0; i < articleDivs.length; i++) {
                height1 += (articleDivs[i].clientHeight + 80)
                setHeight(height1)
            }
        }
    }, [])

    const [lightMode, setLightMode] = React.useState(localStorage.getItem("theme") === "light")
    React.useEffect(() => {
        const bgColor = lightMode ? colors.white : colors.darkGray;
        document.documentElement.style.backgroundColor = bgColor;
    }, [lightMode]);
    
    const key = (query === "0-9" ||
        /^([A-Z]{1})$/.test(query)) ? query : ''
    
    const defWidth = "80%"
    const defMargin = "10%"
    const width = window.innerWidth;
    const desktopView = width > 810
    const bgColor = lightMode ? colors.white : colors.darkGray
    const fontColor = lightMode ? colors.darkGray : colors.white
    const defFontSize = desktopView ? 70 : 50;
    const [results, setResults] = React.useState([])
    const [searchQuery, setSearchQuery] = React.useState('')
    const [pageAmount, setPageAmount] = React.useState(1)
    const [page, setPage] = React.useState(1)
    function toggleTheme() {
        const newTheme = lightMode ? "dark" : "light";
        localStorage.setItem("theme", newTheme);
        setLightMode(!lightMode);
    }

    return (
        <>
            {done ? (!authorized ? <PublicNavbar toggleTheme={toggleTheme} /> : <UserNavbar toggleTheme={toggleTheme} />) : <></>}
            <div className="wrapper animateme" style={{
                backgroundColor: bgColor,
            }}>
                <div className="main animateme" style={{
                    maxWidth: desktopView && width > 1250 ? "1250px" : "100vw",
                    marginLeft: desktopView && width > 1250 ? "calc(50vw - 625px)" : "0vw",
                    backgroundColor: bgColor,
                    minHeight: desktopView ? (height < window.innerHeight - 220 ? "100vh" :
                        height + 240 + "px") : (height > window.innerHeight - 511 ?
                            560 + height : "100vh"),
                    paddingTop: desktopView ? "76px" : "55px"
                }}>
                    <div id="left" style={{
                        width: desktopView ? "50%" : "100%",
                        float: "left"
                    }}>
                        {searchQuery === '' ? <Loading page='search' /> :
                            <>
                                <h3 style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    paddingTop: "3vh",
                                    paddingBottom: "2vh",
                                    color: fontColor,
                                    fontSize: defFontSize / 3,
                                    marginLeft: defMargin,
                                    overflow: "auto",
                                    maxWidth: defWidth
                                }}
                                    className={"animateme"}
                                > {/^([A-Z]{1})$/.test(searchQuery) || searchQuery === "0-9" ?
                                    `Seçilən indeks: "${searchQuery}"` :
                                    `Sorğu üzrə ${results.length === 0 ? ' heç bir məlumat tapılmadı: ' : 'tapılıb: '} "${searchQuery}"`}
                                </h3>
                                {results.slice((page - 1) * 10, page * 10).map(result => <ArticleCard article={result} mode={lightMode} />)}
                                <PaginationTable currentPage={page} setPage={setPage} totalPageCount={pageAmount} />

                            </>
                        }
                    </div>
                    <div id="right" style={{
                        width: desktopView ? "50%" : "100%",
                        float: "right",
                        marginTop: "4vh",
                        paddingBottom: 100
                    }}>
                        <h3
                            className={"animateme"}
                            style={{
                                color: fontColor,
                                marginBottom: "20px",
                                marginTop: "20px",
                                textAlign: "center"
                            }}>İlk hərfə görə axtar:
                        </h3>
                        <Keyboard term={key} mode={lightMode} />
                    </div>
                </div>
                <MainFooter style={{ position: "fixed", bottom: 0, height: "3vh" }} />
            </div>
        </>
    )
}

export default Search;