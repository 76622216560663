import React from "react";
import { colors } from 'assets/js/theme';
import { useNavigate, Navigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// core components
import MainFooter from "components/Footers/MainFooter.js";
import PublicNavbar from "components/Navbars/PublicNavbar.js";
import UserNavbar from "components/Navbars/UserNavbar.js";
import LoadingPage from "views/LoadingPage";

// backend components
import {
    getUser,
    updateArticle,
    getArticle
} from "shared/request"

// sections for this page
import {
    Card,
    CardBody,
    Button,
    Input,
    InputGroupText,
    InputGroup,
    Alert,
    Container,
} from "reactstrap"

function Edit() {
    const [authorized, setAuthorized] = React.useState(false)
    const [done, setDone] = React.useState(false)
    const [id, setId] = React.useState("")
    const [term, setTerm] = React.useState('')
    const [shortDefinition, setShortDefinition] = React.useState('')
    const [longDefinition, setLongDefinition] = React.useState('');
    const [translation, setTranslation] = React.useState('')
    const [user, setUser] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const params = useParams(0)
    React.useEffect(() => {
        window.scrollTo({
            top: -1,
            left: -1,
            behavior: 'smooth',
        })
        
        if (localStorage.getItem("theme") !== "dark" && localStorage.getItem("theme") !== "light") {
            localStorage.setItem("theme", "light")
        }
        if (!params.term) {
            navigate('/home')
        }

        const checkUser = async () => {
            try {
                await getUser().then((result) => {
                    if (result.data.success) { 
                        setAuthorized(true); 
                        setDone(true); 
                        setUser(result.data.result);
                    }
                }).catch((err) => {
                    setAuthorized(false)
                    setDone(true)
                });
            } catch (err) {
                setUser(false);
            } finally {
                setIsLoading(false);
            }
        };
        checkUser();

        String.prototype.toTitleCase = function () {
            var i, j, str, lowers, uppers;
            str = this.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });

            lowers = ['A', 'An', 'The', 'And', 'But', 'Or', 'For', 'Nor', 'As', 'At',
                'By', 'For', 'From', 'In', 'Into', 'Near', 'Of', 'On', 'Onto', 'To', 'With'];
            for (i = 0, j = lowers.length; i < j; i++)
                str = str.replace(new RegExp('\\s' + lowers[i] + '\\s', 'g'),
                    function (txt) {
                        return txt.toLowerCase();
                    });
            uppers = ['Id', 'Tv'];
            for (i = 0, j = uppers.length; i < j; i++)
                str = str.replace(new RegExp('\\b' + uppers[i] + '\\b', 'g'),
                    uppers[i].toUpperCase());

            return str;
        }

    }, [])

    React.useEffect(() => {
        const process = async () => {
            await getArticle(params.term)
                .then((res) => {
                    if (res.data.result.author !== user.username) {
                        navigate('/home')
                    }
                    else {
                        setId(res.data.result._id)
                        setTerm(res.data.result.term.toTitleCase())
                        setLongDefinition(res.data.result.definition)
                        setTranslation(res.data.result.translation)
                        let term = document.getElementById("term")
                        if (term) {
                            term.value = res.data.result.term.toTitleCase();
                        }
                        let definition = document.getElementsByClassName("ql-editor")[0]
                        if (definition) definition.innerHTML = res.data.result.definition
                        let translation = document.getElementById("translation")
                        if (translation) translation.value = res.data.result.translation
                        let shortdef = document.getElementById("shortdef")
                        if (shortdef) {
                            var shdef = res.data.result.short_definition ?
                                res.data.result.short_definition : res.data.result.short_description
                            shortdef.value = shdef
                            setShortDefinition(shdef)
                        }
                    }
                })
        }
        if (done) process()
    }, [user])
    
    
    const [lightMode, setLightMode] = React.useState(localStorage.getItem("theme") === "light")
    React.useEffect(() => {
        const bgColor = lightMode ? colors.white : colors.darkGray;
        document.documentElement.style.backgroundColor = bgColor;
    }, [lightMode]);

    const navigate = useNavigate()
    const [rightFocus, setRightFocus] = React.useState(false);
    const [postSuccess, setPostSuccess] = React.useState(false)
    const [postError, setPostError] = React.useState(false)
    const [postErrorText, setPostErrorText] = React.useState(false)
    const desktopView = window.innerWidth > 810
    const defWidth = desktopView ? "80%" : "100%"
    const defMargin = desktopView ? "10%" : "0%"
    const bgColor = lightMode ? colors.white : colors.darkGray
    const fontColor = lightMode ? colors.darkGray : colors.white
    const borderColor = lightMode ? colors.darkGray : colors.gray
    const defFontSize = 100
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', { 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link', 'clean'],
        ],
    }

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet',
        'link',
    ]

    function postedError(errorMessage) {
        setPostError(true)
        setPostErrorText(errorMessage)
        setTimeout(() => {
            setPostError(false)
        }, 2000)
    }

    function toggleTheme() {
        const newTheme = lightMode ? "dark" : "light";
        localStorage.setItem("theme", newTheme);
        setLightMode(!lightMode);
    }

    let svgs = document.getElementsByTagName("svg")
    if (svgs.length > 0) {
        for (let i = 0; i < svgs.length; i++) {
            if (svgs[i].classList[0] !== "onlyme") {
                let children = svgs[i].children
                for (let j = 0; j < children.length; j++) {
                    children[j].style.stroke = fontColor
                    children[j].classList.add("animateme")
                    if (children[j].getAttribute("d")) {
                        if (children[j].getAttribute("d").substring(0, 7) === "M9.007," ||
                            children[j].getAttribute("d").substring(0, 7) === "M8.984,") {
                            children[j].style.fill = fontColor;
                        }
                    }
                }
            }
        }
    }

    var addRule = (function (style) {
        var sheet = document.head.appendChild(style).sheet;
        return function (selector, css) {
            var propText = typeof css === "string" ? css : Object.keys(css).map(function (p) {
                return p + ":" + (p === "content" ? "'" + css[p] + "'" : css[p]);
            }).join(";");
            sheet.insertRule(selector + "{" + propText + "}", sheet.cssRules.length);
        };
    })(document.createElement("style"));

    addRule(".ql-blank:before", {
        color: lightMode ? "rgb(44, 44, 44) !important" : "rgb(137, 137, 137) !important",
        opacity: lightMode ? "0.5" : "1",
        transition: "all 0.3s ease",
        "font-size": "17px",
        right: "18px !important"
    });

    addRule(".ql-picker-label:before", {
        color: lightMode ? "#151515 !important" : "white !important",
        transition: "all 0.3s ease",
    })

    addRule(".ql-picker-label", {
        "min-width": "110px !important",
    })

    addRule(".ql-picker-item:before", {
        color: lightMode ? "#151515 !important" : "white !important",
        transition: "all 0.3s ease",
    })

    let pickerOptions = document.getElementsByClassName("ql-picker-options")
    if (pickerOptions[0]) {
        pickerOptions[0].style.backgroundColor = lightMode ? colors.lightGray : colors.darkGray
        pickerOptions[0].style.borderRadius = "15px"
        pickerOptions[0].style.borderColor = borderColor;
    }

    async function post() {
        await updateArticle(id, {
            term: term,
            definition: longDefinition,
            translation: translation,
            short_description: shortDefinition,
            published_date: Date.now(),
            modified_date: Date.now(),
        }).then((res) => {
            setPostSuccess(true)
            setTimeout(() => {
                navigate(`/profile`)
            }, 4000)
        })
            .catch(error => {
                if (error.response) {
                    if (error.response.data.error === 'Forbidden!') {
                        postedError("Məqaləni redaktə etmək hüququnuz yoxdur!")
                    }
                    else if (error.response.data.error === 'jwt malformed') {
                        postedError("Məqaləni redaktə etmək hüququnuz yoxdur!")
                    }
                    else if (error.response.data.error === 'Internal Server Error') {
                        postedError("Məqaləni redaktə etmək hüququnuz yoxdur!")
                    }
                    else if (error.response.data.error === "Requested URL Not Found.") {
                        postedError("Bu adlı məqalə yoxdur!")
                    }
                }
            })
        return;
    }

    if (isLoading || !done) return <LoadingPage />;
    if (!user) return <Navigate to='/authentication' />;
    return (
        <>
            {done ? (!authorized ? <PublicNavbar toggleTheme={toggleTheme} /> : <UserNavbar toggleTheme={toggleTheme} />) : <></>}
            <div className="wrapper animateme" style={{
                backgroundColor: bgColor,
            }}>
                <div className="main animateme" style={{
                    maxWidth: desktopView && window.innerWidth > 1250 ? "1250px" : "100vw",
                    marginLeft: desktopView && window.innerWidth > 1250 ? "calc(50vw - 625px)" : "0vw",
                    backgroundColor: bgColor,
                    minHeight: desktopView ?
                        "auto"
                        : ((longDefinition.length > 250 ? longDefinition.length / 20 * 25 :
                            window.innerHeight * 0.4) + 630) + "px",
                    paddingTop: desktopView ? "76px" : "55px"
                }}>

                    <Card style={{
                        marginTop: "5vh",
                        maxWidth: desktopView && window.innerWidth > 1250 ? "1000px" : "90vw",
                        marginLeft: desktopView && window.innerWidth > 1250 ? "125px" : "5vw",
                        backgroundColor: lightMode ? colors.lightGray : colors.darkGray,
                        boxShadow: "none",
                        border: `1px solid ${borderColor}`,
                        borderRadius: "25px"
                    }}
                        className={"animateme"}
                    >
                        <CardBody
                            className={"animateme"}
                        >
                            <h1
                                className={"animateme"}
                                style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    paddingTop: "1vh",
                                    paddingBottom: "1vh",
                                    color: fontColor,
                                    fontSize: desktopView ? 60 : 50 / 1.7,
                                    marginLeft: defMargin,
                                    overflow: "auto",
                                    maxWidth: defWidth,
                                    textAlign: "center"
                                }}>Redaktə Etmək</h1>
                            <Alert color="success" isOpen={postSuccess}>
                                <Container>
                                    <div className="alert-icon">
                                        <i className="now-ui-icons ui-2_like"></i>
                                    </div>
                                    Məqaləniz uğurla redaktə olundu! Təsdiq olunduğunda sizə məlumat veriləcək!
                                    <button
                                        type="button"
                                        className="close" style={{ float: 'right', backgroundColor: 'transparent', border: 'none' }}
                                        onClick={() => setPostSuccess(false)}
                                    >
                                        <span aria-hidden="true">
                                            <i className="now-ui-icons ui-1_simple-remove"></i>
                                        </span>
                                    </button>
                                </Container>
                            </Alert>
                            <Alert color="danger" isOpen={postError && !postSuccess}>
                                <Container>
                                    <div className="alert-icon">
                                        <i className="now-ui-icons ui-1_bell-53"></i>
                                    </div>
                                    {postErrorText}
                                    <button
                                        type="button"
                                        className="close" style={{ float: 'right', backgroundColor: 'transparent', border: 'none' }}
                                        onClick={() => setPostError(false)}
                                    >
                                        <span aria-hidden="true">
                                            <i className="now-ui-icons ui-1_simple-remove"></i>
                                        </span>
                                    </button>
                                </Container>
                            </Alert>
                            <InputGroup
                                className={rightFocus ? "input-group-focus" : ""}
                                style={{
                                    paddingBottom: "20px"
                                }}
                            >
                                <Input
                                    placeholder="Məqalənin adı..."
                                    className={"animateme"}
                                    type="text"
                                    id="term"
                                    onFocus={() => setRightFocus(true)}
                                    onBlur={() => setRightFocus(false)}
                                    style={{
                                        backgroundColor: "transparent",
                                        color: fontColor,
                                        borderColor: term === "" && postError ? colors.red : borderColor,
                                        width: "40%",
                                        fontSize: desktopView ? defFontSize / 4 : defFontSize / 4 - 10,
                                        borderTopLeftRadius: 15,
                                        borderBottomLeftRadius: 15
                                    }}
                                    onChange={(el) => { setTerm(el.target.value) }}
                                    onKeyDown={(el) => {
                                        if (el.keyCode === 13) {
                                            if (term === "" ||
                                                shortDefinition === "" || longDefinition === "") postedError("Zəhmət olmasa bütün xanaları doldurun!")
                                            else post()
                                        }
                                    }}
                                >
                                </Input>
                                <InputGroupText className="animateme" style={{
                                    backgroundColor: "transparent",
                                    color: term === "" && postError ? colors.red : fontColor,
                                    borderColor: term === "" && postError ? colors.red : borderColor,
                                    borderTopRightRadius: 15,
                                    borderBottomRightRadius: 15
                                }}
                                >
                                    {term === "" && postError ? <i className="now-ui-icons ui-1_simple-remove"></i> : <></>}
                                </InputGroupText>
                            </InputGroup>
                            <InputGroup
                                className={rightFocus ? "input-group-focus" : ""}
                                style={{
                                    paddingBottom: "20px"
                                }}
                            >
                                <Input
                                    placeholder="Adının tərcüməsi..."
                                    className={"animateme"}
                                    type="text"
                                    id="translation"
                                    onFocus={() => setRightFocus(true)}
                                    onBlur={() => setRightFocus(false)}
                                    style={{
                                        backgroundColor: "transparent",
                                        color: fontColor,
                                        borderColor: borderColor,
                                        width: "40%",
                                        fontSize: desktopView ? defFontSize / 4 : defFontSize / 4 - 10,
                                        borderTopLeftRadius: 15,
                                        borderBottomLeftRadius: 15
                                    }}
                                    onChange={(el) => { setTranslation(el.target.value) }}
                                    onKeyDown={(el) => {
                                        if (el.keyCode === 13) {
                                            if (term === "" ||
                                                shortDefinition === "" || longDefinition === "") postedError("Zəhmət olmasa bütün xanaları doldurun!")
                                            else post()
                                        }
                                    }}
                                >
                                </Input>
                                <InputGroupText className="animateme" style={{
                                        backgroundColor: "transparent",
                                        color: fontColor,
                                        borderColor: borderColor,
                                        borderTopRightRadius: 15,
                                        borderBottomRightRadius: 15
                                    }}
                                    >
                                </InputGroupText>
                            </InputGroup>
                            <InputGroup
                                className={rightFocus ? "input-group-focus" : ""}
                                style={{
                                    paddingBottom: "20px"
                                }}
                            >
                                <Input
                                    placeholder="Məqalənin qısa təsviri (maksimum 250 simvol)..."
                                    className={"animateme"}
                                    type="text"
                                    id="shortdef"
                                    onFocus={() => setRightFocus(true)}
                                    onBlur={() => setRightFocus(false)}
                                    style={{
                                        backgroundColor: "transparent",
                                        color: fontColor,
                                        borderColor: shortDefinition === "" && postError ? colors.red : borderColor,
                                        width: "40%",
                                        fontSize: desktopView ? defFontSize / 4 : defFontSize / 4 - 10,
                                        borderTopLeftRadius: 15,
                                        borderBottomLeftRadius: 15
                                    }}
                                    maxLength="250"
                                    onChange={(el) => { setShortDefinition(el.target.value) }}
                                    onKeyDown={(el) => {
                                        if (el.keyCode === 13) {
                                            if (term === "" ||
                                                shortDefinition === "" || longDefinition === "") postedError("Zəhmət olmasa bütün xanaları doldurun!")
                                            else post()
                                        }
                                    }}
                                >
                                </Input>
                                <InputGroupText className="animateme" style={{
                                        backgroundColor: "transparent",
                                        color: shortDefinition === "" && postError ? colors.red : fontColor,
                                        borderColor: shortDefinition === "" && postError ? colors.red : borderColor,
                                        borderTopRightRadius: 15,
                                        borderBottomRightRadius: 15
                                    }}
                                    >
                                        {shortDefinition === "" && postError ? <i className="now-ui-icons ui-1_simple-remove"></i> : <></>}
                                </InputGroupText>
                            </InputGroup>
                            <InputGroup>
                                <ReactQuill theme="snow"
                                    placeholder={"Məqalənin tam təsviri..."}
                                    value={longDefinition}
                                    onChange={setLongDefinition}
                                    id="definition"
                                    modules={modules}
                                    formats={formats}
                                    className={"animatemeQuill"}
                                    maxLength={1000}
                                    style={{
                                        color: fontColor,
                                        fontFamily: "sans-serif",
                                        borderLeft: `1px solid ${longDefinition === "" && postError ? colors.red : borderColor}`,
                                        borderTop: `1px solid ${longDefinition === "" && postError ? colors.red : borderColor}`,
                                        borderBottom: `1px solid ${longDefinition === "" && postError ? colors.red : borderColor}`,
                                        maxWidth: "100%",
                                        width: longDefinition === "" && postError ? "calc(100% - 36px)" : "calc(100% - 19px)",
                                        height: "25vh",
                                        minHeight: "250px",
                                        borderTopLeftRadius: 15,
                                        borderBottomLeftRadius: 15,
                                        paddingBottom: 70,
                                        marginBottom: 25,
                                    }} />
                                <InputGroupText className="animateme" style={{
                                        backgroundColor: "transparent",
                                        color: longDefinition === "" && postError ? colors.red : fontColor,
                                        borderColor: longDefinition === "" && postError ? colors.red : borderColor,
                                        height: "25vh",
                                        minHeight: "250px",
                                        borderTopRightRadius: 15,
                                        borderBottomRightRadius: 15,
                                        borderLeft: "none"
                                    }}
                                    >
                                        {longDefinition === "" && postError ? <i className="now-ui-icons ui-1_simple-remove"></i> : <></>}
                                </InputGroupText>
                            </InputGroup>
                            <Button
                                className="btn-round button success animateme"
                                outline
                                color="success"
                                type="button"
                                style={{
                                    width: defWidth,
                                    fontSize: 24,
                                    marginLeft: defMargin
                                }}
                                onClick={() => {
                                    if (term === "" ||
                                        shortDefinition === "" || longDefinition === "") {
                                        postedError("Zəhmət olmasa, bütün xanaları doldurun!")
                                    }
                                    else post()
                                }}
                            >
                                Təsdiqlə
                            </Button>
                        </CardBody>
                    </Card>
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <MainFooter style={{ position: "fixed", bottom: 0, height: "3vh" }} />
            </div>
        </>
    )
}

export default Edit;