import React from "react";
import { colors } from 'assets/js/theme';
import { useNavigate } from 'react-router-dom';

// backend components
import {
    signOut
} from "shared/request"

// sections for this page
import {
    Alert,
    Container,
    Button
} from "reactstrap"

function UserButtons() {
    const navigate = useNavigate()
    const defWidth = "80%"
    const defMargin = "10%"
    const desktopView = window.innerWidth > 810
    const [logSuccess, setLogSuccess] = React.useState(false)

    async function logout() {
        await signOut().then((res) => {
            localStorage.removeItem("authorization")
            setLogSuccess(true)
            setTimeout(() => {
                window.location.reload()
            }, 2500)
        })
    }
    return (
        <>
            <Alert color="success" isOpen={logSuccess}
                style={{
                    position: "absolute",
                    minWidth: window.innerWidth > 810 ? "810px" : "100%",
                    zIndex: 1,
                    top: "0px",
                    textAlign: "center",
                    left: desktopView ? window.innerWidth > 1500 ? "345px" : "calc(50vw - 405px)" : 0,
                    backgroundColor: "#28A745",
                    textAlign: "center"
                }}>
                <Container>
                    <div className="alert-icon" style={{
                        marginTop: desktopView ? "auto" : 0
                    }}>
                        <i className="now-ui-icons ui-2_like"></i>
                    </div>
                    Siz uğurla hesabdan çıxdınız!
                    <button
                        type="button"
                        className="close" style={{ float: 'right', backgroundColor: 'transparent', border: 'none' }}
                        onClick={() => setLogSuccess(false)}
                    >
                        <span aria-hidden="true">
                            <i className="now-ui-icons ui-1_simple-remove"></i>
                        </span>
                    </button>
                </Container>
            </Alert>
            <Button
                className="btn-round button danger animateme"
                color="danger"
                outline
                type="button"
                style={{
                    width: defWidth,
                    marginLeft: defMargin,
                    marginBottom: 0,
                    marginTop: 0
                }}
                onClick={() => {
                    navigate(`/post`)
                }}
            >
                Lüğətə Əlavə Et
            </Button>
            <div style={{
                width: defWidth,
                marginLeft: defMargin
            }}>
                <Button
                    className="btn button success animateme"
                    color="success"
                    outline
                    type="button"
                    style={{
                        width: `calc(50% - 1px)`,
                        borderRadius: "30px 0px 0px 30px",
                        margin: 0
                    }}
                    onClick={() => {
                        navigate(`/profile`)
                    }}
                >
                    Hesaba Keç
                </Button>
                <Button
                    className="btn button success animateme"
                    color="success"
                    outline
                    type="button"
                    style={{
                        width: `calc(50% - 1px)`,
                        borderRadius: "0px 30px 30px 0px",
                        borderLeft: "none",
                        margin: 0
                    }}
                    onClick={() => { logout() }}
                >
                    Hesabdan Çıx
                </Button>
            </div>
        </>
    )
}

export default UserButtons