import React from "react";
import { colors } from 'assets/js/theme';

export default function Loading({ page }) {
    const width = window.innerWidth
    const desktopView = width > 810
    const ultraWide = width >= 1920
    const lightMode = localStorage.getItem("theme") === "light"
    return (
        <img src={
            lightMode ?
                require("assets/img/gifs/lightmode.svg").default :
                require("assets/img/gifs/darkmode.svg").default
        } style={
            page === "search" ? {
                width: desktopView ? "20vw" : "50vw",
                marginLeft: desktopView ? width > 1250 ? "calc(312.5px - 10vw)" : "10vw" : "25vw",
                marginTop: "10vh"
            }
                :
                page === "profile" ? {
                    width: desktopView ? "20vw" : "50vw",
                    marginLeft: desktopView ? width > 1250 ? "calc(312.5px - 10vw)" : "10vw" : "25vw",
                    marginTop: "10vh"
                }
                    :
                    page === "home" ? {
                        width: desktopView ? "10vw" : "30vw",
                        marginLeft: desktopView ? width > 1500 ? "calc(375px - 5vw)" : "calc(50% - 5vw)" : "35vw",
                    }
                        :
                        page === "article" ? {
                            width: desktopView ? "20vw" : "50vw",
                            marginLeft: desktopView ? width > 1350 ? ultraWide ?
                                "calc(610px - 10vw)" : "calc(337.5px - 10vw)" : "10vw" : "25vw",
                            marginTop: "10vh"
                        }
                            :
                            {
                                width: desktopView ? "20vw" : "50vw",
                                marginLeft: desktopView ? width > 1250 ? "calc(312.5px - 10vw)" : "10vw" : "25vw",
                                marginTop: "10vh"
                            }
        }
        />
    )
}