import React from "react"
import { colors } from 'assets/js/theme';
import { useNavigate } from 'react-router-dom';
import validator from 'validator'

// request components
import {
    registerUser,
} from "shared/request.js"

// sections for this page
import {
    TabPane,
    Button,
    Input,
    InputGroupText,
    InputGroup,
    Alert,
    Container,
} from "reactstrap"


function RegisterForm() {
    const navigate = useNavigate()
    const desktopView = window.innerWidth > 810
    const lightMode = localStorage.getItem("theme") === "light"
    const fontColor = lightMode ? colors.darkGray : colors.white
    const defFontSize = desktopView ? 100 : 70;
    const borderColor = lightMode ? colors.darkGray : colors.gray
    const [rightFocus, setRightFocus] = React.useState(false);
    const [username, setUsername] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [confirmPassword, setConfirmPassword] = React.useState("")
    const [regError, setRegError] = React.useState(false)
    const [regErrorText, setRegErrorText] = React.useState("")
    const [regSuccess, setRegSuccess] = React.useState(false)

    function registerError(errorMessage) {
        setRegError(true)
        setRegErrorText(errorMessage)
        setTimeout(() => {
            setRegError(false)
        }, 2000)
    }

    async function register() {
        if (!validator.isStrongPassword(password, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) registerError('Şifrə ən azı 8 hərfdən, o cümlədən 1 kiçik hərf, 1 böyük hərf, 1 rəqəm və 1 simvoldan ibarət olmalıdır!')
        else {
            if (password !== confirmPassword) registerError("Şifrələr uyğun deyil!")
            else {
                if (!validator.isEmail(email)) registerError("Daxil edilmiş e-poçt həqiqi deyil!")
                else {
                    await registerUser({
                        username: username,
                        password: password,
                        passwordConfirmation: confirmPassword,
                        email: email
                    })
                        .then((res) => {
                            setRegSuccess(true)
                            setTimeout(() => {
                                navigate('/authentication')
                                window.location.reload()
                            }, 5000)
                        })
                        .catch(error => {
                            console.log(error)
                            if (error.response.data.error === "Username already taken") {
                                registerError("İstifadəçi adı artıq mövcuddur!")
                            }
                            else if (error.response.data.error === "Email already taken") {
                                registerError("E-poçt ünvanı artıq istifadə olunub!")
                            }
                            else if (error.response.data.error === "Passwords do not match!") {
                                registerError("Şifrələr uyğun deyil!")
                            }
                        })
                }
            }
        }
    }
    return (

        <TabPane tabId="register">
            <Alert color="success" isOpen={regSuccess}>
                <Container>
                    <div className="alert-icon">
                        <i className="now-ui-icons ui-2_like"></i>
                    </div>
                    Siz uğurla qeydiyyatdan keçdiniz! Daxil olmaq üçün, e-poçt ünvanınıza gələn məktub ilə hesabınızı təsdiqləyin!
                    <button
                        type="button"
                        className="close" style={{ float: 'right', backgroundColor: 'transparent', border: 'none' }}
                        onClick={() => setRegSuccess(false)}
                    >
                        <span aria-hidden="true">
                            <i className="now-ui-icons ui-1_simple-remove"></i>
                        </span>
                    </button>
                </Container>
            </Alert>
            <Alert color="danger" isOpen={regError && !regSuccess}>
                <Container>
                    <div className="alert-icon">
                        <i className="now-ui-icons ui-1_bell-53"></i>
                    </div>
                    {regErrorText}
                    <button
                        type="button"
                        className="close" style={{ float: 'right', backgroundColor: 'transparent', border: 'none' }}
                        onClick={() => setRegError(false)}
                    >
                        <span aria-hidden="true">
                            <i className="now-ui-icons ui-1_simple-remove"></i>
                        </span>
                    </button>
                </Container>
            </Alert>
            <InputGroup
                className={rightFocus ? "input-group-focus" : ""}
                style={{
                    paddingBottom: "20px"
                }}
            >
                <Input
                    placeholder="İstifadəçi adı..."
                    type="text"
                    onFocus={() => setRightFocus(true)}
                    onBlur={() => setRightFocus(false)}
                    style={{
                        backgroundColor: "transparent",
                        color: fontColor,
                        borderBottomLeftRadius: 15,
                        borderTopLeftRadius: 15,
                        borderColor: username === "" && regError ? colors.red : borderColor,
                        width: "40%",
                        fontSize: window.innerWidth > 1000 || !desktopView ? defFontSize / 4 : defFontSize / 4 - 10,
                    }}
                    onChange={(el) => { setUsername(el.target.value) }}
                    onKeyDown={(el) => {
                        if (el.keyCode === 13) {
                            if (username === "" || password === "" ||
                                confirmPassword === "" || email === "") registerError("Zəhmət olmasa bütün xanaları doldurun!")
                            else register()
                        }
                    }}
                >
                </Input>
                <InputGroupText className="animateme" style={{
                        backgroundColor: "transparent",
                        color: username === "" && regError ? colors.red : fontColor,
                        borderColor: username === "" && regError ? colors.red : borderColor,
                        borderTopRightRadius: 15,
                        borderBottomRightRadius: 15
                    }}>
                        {username === "" && regError ? <i className="now-ui-icons ui-1_simple-remove"></i> : <></>}
                </InputGroupText>
            </InputGroup>
            <InputGroup
                className={rightFocus ? "input-group-focus" : ""}
                style={{
                    paddingBottom: "20px"
                }}
            >
                <Input
                    placeholder="E-poçt ünvanı..."
                    type="email"
                    onFocus={() => setRightFocus(true)}
                    onBlur={() => setRightFocus(false)}
                    style={{
                        backgroundColor: "transparent",
                        color: fontColor,
                        borderBottomLeftRadius: 15,
                        borderTopLeftRadius: 15,
                        borderColor: email === "" && regError ? colors.red : borderColor,
                        width: "40%",
                        fontSize: window.innerWidth > 1000 || !desktopView ? defFontSize / 4 : defFontSize / 4 - 10,
                    }}
                    onChange={(el) => { setEmail(el.target.value) }}
                    onKeyDown={(el) => {
                        if (el.keyCode === 13) {
                            if (username === "" || password === "" ||
                                confirmPassword === "" || email === "") registerError("Zəhmət olmasa bütün xanaları doldurun!")
                            else register()
                        }
                    }}
                >
                </Input>
                <InputGroupText className="animateme" style={{
                        backgroundColor: "transparent",
                        color: email === "" && regError ? colors.red : fontColor,
                        borderColor: email === "" && regError ? colors.red : borderColor,
                        borderTopRightRadius: 15,
                        borderBottomRightRadius: 15
                    }}>
                        {email === "" && regError ? <i className="now-ui-icons ui-1_simple-remove"></i> : <></>}
                </InputGroupText>
            </InputGroup>
            <InputGroup
                className={rightFocus ? "input-group-focus" : ""}
                style={{
                    paddingBottom: "20px"
                }}
            >
                <Input
                    placeholder="Şifrə..."
                    type="password"
                    onFocus={() => setRightFocus(true)}
                    onBlur={() => setRightFocus(false)}
                    style={{
                        backgroundColor: "transparent",
                        color: fontColor,
                        borderBottomLeftRadius: 15,
                        borderTopLeftRadius: 15,
                        borderColor: password === "" && regError ? colors.red : borderColor,
                        width: "40%",
                        fontSize: window.innerWidth > 1000 || !desktopView ? defFontSize / 4 : defFontSize / 4 - 10,
                    }}
                    onChange={(el) => { setPassword(el.target.value) }}
                    onKeyDown={(el) => {
                        if (el.keyCode === 13) {
                            if (username === "" || password === "" ||
                                confirmPassword === "" || email === "") registerError("Zəhmət olmasa bütün xanaları doldurun!")
                            else register()
                        }
                    }}
                >
                </Input>
                <InputGroupText className="animateme" style={{
                        backgroundColor: "transparent",
                        color: password === "" && regError ? colors.red : fontColor,
                        borderColor: password === "" && regError ? colors.red : borderColor,
                        borderTopRightRadius: 15,
                        borderBottomRightRadius: 15
                    }}>
                        {password === "" && regError ? <i className="now-ui-icons ui-1_simple-remove"></i> : <></>}
                </InputGroupText>
            </InputGroup>
            <InputGroup
                className={rightFocus ? "input-group-focus" : ""}
                style={{
                    paddingBottom: "20px"
                }}
            >
                <Input
                    placeholder="Təkrar şifrə..."
                    type="password"
                    onFocus={() => setRightFocus(true)}
                    onBlur={() => setRightFocus(false)}
                    style={{
                        backgroundColor: "transparent",
                        color: fontColor,
                        borderBottomLeftRadius: 15,
                        borderTopLeftRadius: 15,
                        borderColor: confirmPassword === "" && regError ? colors.red : borderColor,
                        width: "40%",
                        fontSize: window.innerWidth > 1000 || !desktopView ? defFontSize / 4 : defFontSize / 4 - 10,
                    }}
                    onChange={(el) => { setConfirmPassword(el.target.value) }}
                    onKeyDown={(el) => {
                        if (el.keyCode === 13) {
                            if (username === "" || password === "" ||
                                confirmPassword === "" || email === "") registerError("Zəhmət olmasa bütün xanaları doldurun!")
                            else register()
                        }
                    }}
                >
                </Input>
                <InputGroupText className="animateme" style={{
                        backgroundColor: "transparent",
                        color: confirmPassword === "" && regError ? colors.red : fontColor,
                        borderColor: confirmPassword === "" && regError ? colors.red : borderColor,
                        borderTopRightRadius: 15,
                        borderBottomRightRadius: 15
                    }}>
                        {confirmPassword === "" && regError ? <i className="now-ui-icons ui-1_simple-remove"></i> : <></>}
                </InputGroupText>
            </InputGroup>
            <Button
                className="btn-round button success animateme"
                color="success"
                outline
                type="button"
                style={{
                    width: desktopView ? "80%" : "100%",
                    fontSize: window.innerWidth > 500 ? 24 : 16,
                }}
                onClick={() => {
                    if (username === "" || password === "" ||
                        confirmPassword === "" || email === "") registerError("Zəhmət olmasa bütün xanaları doldurun!")
                    else register()
                }}
            >
                Qeydiyyatdan Keç
            </Button>
        </TabPane>
    )
}

export default RegisterForm