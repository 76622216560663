import React from 'react';
import { colors } from 'assets/js/theme';

// backend components
import {
    getArticleByID
} from "shared/request"

export default function AdminCard({ article, mode, setShow, setMode, 
        setOld, setNew, setId, setAuthor, 
        setDefinition, setPubDate, setTerm
    }) {
    const defWidth = "100%"
    const defMargin = "0%"
    const [lightMode, setLightMode] = React.useState(mode)
    const bgColor = lightMode ? colors.lightGray : colors.darkGray
    const fontColor = lightMode ? colors.darkGray : colors.lightGray
    const borderColor = lightMode ? colors.darkGray : colors.gray
    const [butClass, setButClass] = React.useState(lightMode ? "lBottom" : "dBottom")
    var definition = article.short_definition && article.status != "updated" ? 
        article.short_definition : article.short_description;
    if (lightMode !== mode) {
        setLightMode(mode)
        setButClass(mode ? "lBottom" : "dBottom")
    }

    function getText(n) {
        var rv = '';

        if (n.nodeType == 3) {
            rv = n.nodeValue;
        } else {
            for (var i = 0; i < n.childNodes.length; i++) {
                rv += getText(n.childNodes[i]);
            }
            var d = getComputedStyle(n).getPropertyValue('display');
            if (d.match(/^block/) || d.match(/list/) || n.tagName == 'BR') {
                rv += "\n";
            }
        }

        return rv;
    }

    async function view() {
        const update = article.status === "updated"
        setMode(update)
        setShow(true)
        setId(article._id)
        if (!update) {
            setAuthor(article.author)
            setDefinition(article.definition)
            setPubDate(new Date(article.published_date))
            setTerm(article.term)
        }
        else {
            const oldArticle = await getArticleByID(article.related_id).then(res => { return res.data.result })
            var tempOld = document.createElement('div')
            tempOld.innerHTML = oldArticle.definition
            var tempNew = document.createElement('div')
            tempNew.innerHTML = article.definition
            setOld(`
            Adı
            ${oldArticle.term}

            Tərcüməsi
            ${oldArticle.translation}

            Qısa təsviri
            ${oldArticle.short_description}

            Təsviri
            ${getText(tempOld)}
            `)

            setNew(`
            Adı
            ${article.term}

            Tərcüməsi
            ${article.translation}

            Qısa təsviri
            ${article.short_description}

            Təsviri
            ${getText(tempNew)}
            `)
        }
    }

    return (
        <>
            <div className='animateme' style={{
                width: defWidth,
                marginLeft: defMargin,
                backgroundColor: bgColor,
                color: fontColor,
                borderTop: `1px solid ${borderColor}`,
                borderLeft: `1px solid ${borderColor}`,
                borderRight: `1px solid ${borderColor}`,
                borderRadius: "30px 30px 0px 0px",
                marginBottom: "none"
            }}
                id={"top"}>
                <h3
                    className={"animateme notme"}
                    style={{
                        width: "100%",
                        paddingLeft: "5%",
                        paddingRight: "5%",
                        color: fontColor,
                        marginTop: "10px",
                        paddingBottom: "3px",
                        textAlign: "center",
                        borderBottom: `1px solid ${borderColor}`,
                        marginBottom: 0
                    }}
                >{article.term}</h3>
                <h5 className={"animateme notme"}
                    style={{
                        marginLeft: "5%",
                        marginRight: "5%",
                        paddingBottom: "10px",
                        paddingTop: "10px",
                        marginBottom: "0px",
                        textAlign: "left"
                    }}>{definition}
                </h5>
            </div >
            <div className='animateme' style={{
                marginLeft: defMargin,
                width: defWidth,
                minHeight: "50px",
                marginBottom: "30px",
                border: `1px solid ${fontColor}`,
                borderRadius: "0px 0px 30px 30px",
            }}>
                <div
                    className={`animateme ${butClass}`}
                    onClick={() => { view() }}
                >
                    Daha Ətraflı
                </div>
            </div>
        </>
    )
}
