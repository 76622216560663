import React from "react";
import { colors } from 'assets/js/theme';

import TitleElement from "components/Navbars/NavbarItems/TitleElement";
import { NavbarToggler } from "reactstrap";

export default function NavbarTranslate({ toggleTheme, setNavbarColor, setBorderColor, collapsed, setCollapsed}) {
    const lightMode = localStorage.getItem("theme") === "light"
    const desktopView = window.innerWidth > 1300
    React.useEffect(() => {
        function onScroll() {
          const scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
          const isScrolled = scrollPosition > 0;
          setNavbarColor(isScrolled || collapsed ? "" : "navbar-transparent");
          setBorderColor((isScrolled || collapsed) ? (!lightMode ? colors.lightGray : colors.darkGray) : "transparent");
        }
      
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
      });    

    const checkColor = (isNavbarOpen) => {
        const scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
        const isScrolled = scrollPosition > 0;
      
        if (isNavbarOpen || isScrolled) {
            setNavbarColor("");
            setBorderColor(!lightMode ? colors.lightGray : colors.darkGray);
        } else {
            setNavbarColor("navbar-transparent");
            setBorderColor("transparent");
        }
    };
    return (
        <div style={
            !desktopView ? { width: "100%", textAlign: "center" } : 
                {marginLeft: "7vw"}}>
            <TitleElement toggleTheme={toggleTheme}/>
            <NavbarToggler
                onClick={() => {
                    document.documentElement.classList.toggle("nav-open");
                    checkColor(!collapsed);
                    setCollapsed(!collapsed);
                }}
                style={{
                    float: "right",
                    padding: 0,
                    paddingTop: 2.25,
                    paddingLeft: 0.25,
                    marginTop: !desktopView ? -30 : 'auto',
                    border: !desktopView ? '0px' : 'inherit'
                }}
                aria-expanded={collapsed}
            >
                <i className={`now-ui-icons ui-1_simple-${collapsed ? "delete" : "add"}`}
                        style={{ color: lightMode ? colors.black : colors.white }}></i>
            </NavbarToggler>
        </div>
    )
}