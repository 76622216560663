import React from "react"
import { colors } from 'assets/js/theme';
import { useNavigate } from 'react-router-dom';
import validator from 'validator'

// request components
import {
    changePassword,
    changeAdminPassword
} from "shared/request.js"

// sections for this page
import {
    Button,
    Input,
    InputGroupText,
    InputGroup,
    Alert,
    Container,
} from "reactstrap"


function ChangePasswordForm({ user }) {
    const navigate = useNavigate()
    const desktopView = window.innerWidth > 810
    const lightMode = localStorage.getItem("theme") === "light"
    const fontColor = lightMode ? colors.darkGray : colors.white
    const borderColor = lightMode ? colors.darkGray : colors.lightGray
    const defFontSize = desktopView ? 100 : 70;
    const [rightFocus, setRightFocus] = React.useState(false);
    const [oldPassword, setOldPassword] = React.useState("")
    const [newPassword, setNewPassword] = React.useState("")
    const [passConfirmation, setPassConfirmation] = React.useState("")
    const [logError, setLogError] = React.useState(false)
    const [logErrorText, setLogErrorText] = React.useState("")
    const [logSuccess, setLogSuccess] = React.useState(false)

    function loginError(errorMessage) {
        setLogError(true)
        setLogErrorText(errorMessage)
        setTimeout(() => {
            setLogError(false)
        }, 2000)
    }

    async function change() {
        if (newPassword !== passConfirmation) {
            loginError("Yeni şifrələr eyni deyil!")
        }
        else if (!validator.isStrongPassword(newPassword, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) loginError('Şifrə ən azı 8 hərfdən, o cümlədən 1 kiçik hərf, 1 böyük hərf, 1 rəqəm və 1 simvoldan ibarət olmalıdır!')
        else {
            console.log(user)
            await (user === 'ADMIN' ? changeAdminPassword : changePassword)({
                old_password: oldPassword,
                new_password: newPassword,
                new_passwordConfirmation: passConfirmation
            }).then((res) => {
                setLogSuccess(true)
                localStorage.removeItem('authorization')
                setTimeout(() => {
                    navigate('/authentication')
                    window.location.reload()
                }, 3000)
            }).catch((error) => {
                if (error.response) {
                    if (error.response.data.error === 'Token is invalid or has expired!') {
                        loginError("Şifrəni dəyişdirmək hüququnuz yoxdur!")
                        setTimeout(() => {
                            navigate('/authentication')
                        }, 3000)
                    }
                    else if (error.response.data.error === 'jwt malformed') {
                        loginError("Şifrəni dəyişdirmək hüququnuz yoxdur!")
                        setTimeout(() => {
                            navigate('/authentication')
                        }, 3000)
                    }
                    else if (error.response.data.error === 'Old password is incorrect!') {
                        loginError("Köhnə şifrə səhvdir!")
                    }
                }
            })
        }
    }

    return (
        <div style={{ width: "80%", marginLeft: "10%" }}>
            <Alert color="success" isOpen={logSuccess}>
                <Container>
                    <div className="alert-icon">
                        <i className="now-ui-icons ui-2_like"></i>
                    </div>
                    Siz uğurla şifrənizi dəyişdirdiniz!
                    <button
                        type="button"
                        className="close" style={{ float: 'right', backgroundColor: 'transparent', border: 'none' }}
                        onClick={() => setLogSuccess(false)}
                    >
                        <span aria-hidden="true">
                            <i className="now-ui-icons ui-1_simple-remove"></i>
                        </span>
                    </button>
                </Container>
            </Alert>
            <Alert color="danger" isOpen={logError && !logSuccess}>
                <Container>
                    <div className="alert-icon">
                        <i className="now-ui-icons ui-1_bell-53"></i>
                    </div>
                    {logErrorText}
                    <button
                        type="button"
                        className="close" style={{ float: 'right', backgroundColor: 'transparent', border: 'none' }}
                        onClick={() => setLogError(false)}
                    >
                        <span aria-hidden="true">
                            <i className="now-ui-icons ui-1_simple-remove"></i>
                        </span>
                    </button>
                </Container>
            </Alert>
            <InputGroup
                className={rightFocus ? "input-group-focus" : ""}
                style={{
                    paddingBottom: "20px"
                }}
            >
                <Input
                    placeholder="Köhnə şifrə..."
                    type="password"
                    onFocus={() => setRightFocus(true)}
                    onBlur={() => setRightFocus(false)}
                    style={{
                        backgroundColor: "transparent",
                        color: fontColor,
                        borderBottomLeftRadius: 15,
                        borderTopLeftRadius: 15,
                        borderColor: oldPassword === "" && logError ? "red" : borderColor,
                        width: "40%",
                        fontSize: window.innerWidth > 1000 || !desktopView ? defFontSize / 4 : defFontSize / 4 - 10,
                    }}
                    onChange={(el) => { setOldPassword(el.target.value) }}
                    onKeyDown={(el) => {
                        if (el.keyCode === 13) {
                            if (oldPassword === "" || newPassword === "" ||
                                passConfirmation === "") loginError("Zəhmət olmasa bütün xanaları doldurun!")
                            else change()
                        }
                    }}
                >
                </Input>
                <InputGroupText className="animateme" style={{
                        backgroundColor: "transparent",
                        color: oldPassword === "" && logError ? "red" : fontColor,
                        borderColor: oldPassword === "" && logError ? "red" : borderColor,
                        borderTopRightRadius: 15,
                        borderBottomRightRadius: 15
                    }}>
                        {oldPassword === "" && logError ? <i className="now-ui-icons ui-1_simple-remove"></i> : <></>}
                </InputGroupText>
            </InputGroup>
            <InputGroup
                className={rightFocus ? "input-group-focus" : ""}
                style={{
                    paddingBottom: "20px"
                }}
            >
                <Input
                    placeholder="Yeni şifrə..."
                    type="password"
                    onFocus={() => setRightFocus(true)}
                    onBlur={() => setRightFocus(false)}
                    style={{
                        backgroundColor: "transparent",
                        color: fontColor,
                        borderBottomLeftRadius: 15,
                        borderTopLeftRadius: 15,
                        borderColor: newPassword === "" && logError ? "red" : borderColor,
                        width: "40%",
                        fontSize: window.innerWidth > 1000 || !desktopView ? defFontSize / 4 : defFontSize / 4 - 10,
                    }}
                    onChange={(el) => { setNewPassword(el.target.value) }}
                    onKeyDown={(el) => {
                        if (el.keyCode === 13) {
                            if (oldPassword === "" || newPassword === "" ||
                                passConfirmation === "") loginError("Zəhmət olmasa bütün xanaları doldurun!")
                            else change()
                        }
                    }}
                >
                </Input>
                <InputGroupText className="animateme" style={{
                        backgroundColor: "transparent",
                        color: newPassword === "" && logError ? "red" : fontColor,
                        borderColor: newPassword === "" && logError ? "red" : borderColor,
                        borderTopRightRadius: 15,
                        borderBottomRightRadius: 15
                    }}>
                        {newPassword === "" && logError ? <i className="now-ui-icons ui-1_simple-remove"></i> : <></>}
                </InputGroupText>
            </InputGroup>
            <InputGroup
                className={rightFocus ? "input-group-focus" : ""}
                style={{
                    paddingBottom: "20px"
                }}
            >
                <Input
                    placeholder="Təkrar yeni şifrə..."
                    type="password"
                    onFocus={() => setRightFocus(true)}
                    onBlur={() => setRightFocus(false)}
                    style={{
                        backgroundColor: "transparent",
                        color: fontColor,
                        borderBottomLeftRadius: 15,
                        borderTopLeftRadius: 15,
                        borderColor: passConfirmation === "" && logError ? "red" : borderColor,
                        width: "40%",
                        fontSize: window.innerWidth > 1000 || !desktopView ? defFontSize / 4 : defFontSize / 4 - 10,
                    }}
                    onChange={(el) => { setPassConfirmation(el.target.value) }}
                    onKeyDown={(el) => {
                        if (el.keyCode === 13) {
                            if (oldPassword === "" || newPassword === "" ||
                                passConfirmation === "") loginError("Zəhmət olmasa bütün xanaları doldurun!")
                            else change()
                        }
                    }}
                >
                </Input>
                <InputGroupText className="animateme" style={{
                        backgroundColor: "transparent",
                        color: passConfirmation === "" && logError ? "red" : fontColor,
                        borderColor: passConfirmation === "" && logError ? "red" : borderColor,
                        borderTopRightRadius: 15,
                        borderBottomRightRadius: 15
                    }}>
                        {passConfirmation === "" && logError ? <i className="now-ui-icons ui-1_simple-remove"></i> : <></>}
                </InputGroupText>
            </InputGroup>
            <Button
                className="btn-round button success animateme"
                color="success"
                outline
                type="button"
                style={{
                    width: desktopView ? "80%" : "100%",
                    marginLeft: desktopView ? "10%" : 0,
                }}
                onClick={() => {
                    if (oldPassword === "" || newPassword === "" ||
                        passConfirmation === "") loginError("Zəhmət olmasa bütün xanaları doldurun!")
                    else change()
                }}
            >
                Təsdiqlə
            </Button>
        </div>
    )
}

export default ChangePasswordForm;