import React from "react";
import { colors } from 'assets/js/theme';
import { useNavigate } from 'react-router-dom';


import { Button } from 'reactstrap';

import Key from "./Key.js"

function Keyboard({ mode, term }) {
    const navigate = useNavigate()
    const [lightMode, setLightMode] = React.useState(mode)
    const [activeKeyClass, setActiveKeyClass] = React.useState(lightMode ? 'lActiveKey' : 'dActiveKey')
    const [passiveKeyClass, setPassiveKeyClass] = React.useState(lightMode ? 'lPassiveKey' : 'dPassiveKey')
    if (lightMode !== mode) {
        setLightMode(mode)
        setActiveKeyClass(mode ? 'lActiveKey' : 'dActiveKey')
        setPassiveKeyClass(mode ? 'lPassiveKey' : 'dPassiveKey')
    }

    return (
        <div className="keyboard-container">
            <div className="key-row">
                <Key letter="A" term={term} mode={lightMode} />
                <Key letter="B" term={term} mode={lightMode} />
                <Key letter="C" term={term} mode={lightMode} />
                <Key letter="D" term={term} mode={lightMode} />
                <Key letter="E" term={term} mode={lightMode} />
                <Key letter="F" term={term} mode={lightMode} />
                <Key letter="G" term={term} mode={lightMode} />
            </div>
            <div className="key-row">
                <Key letter="H" term={term} mode={lightMode} />
                <Key letter="I" term={term} mode={lightMode} />
                <Key letter="J" term={term} mode={lightMode} />
                <Key letter="K" term={term} mode={lightMode} />
                <Key letter="L" term={term} mode={lightMode} />
                <Key letter="M" term={term} mode={lightMode} />
                <Key letter="N" term={term} mode={lightMode} />
            </div>
            <div className="key-row">
                <Key letter="O" term={term} mode={lightMode} />
                <Key letter="P" term={term} mode={lightMode} />
                <Key letter="Q" term={term} mode={lightMode} />
                <Key letter="R" term={term} mode={lightMode} />
                <Key letter="S" term={term} mode={lightMode} />
                <Key letter="T" term={term} mode={lightMode} />
                <Key letter="U" term={term} mode={lightMode} />
            </div>
            <div className="key-row">
                <Key letter="V" term={term} mode={lightMode} />
                <Key letter="W" term={term} mode={lightMode} />
                <Key letter="X" term={term} mode={lightMode} />
                <Key letter="Y" term={term} mode={lightMode} />
                <Key letter="Z" term={term} mode={lightMode} />
                <Button type="button"
                    className={`animateme ${term === "0-9" ? activeKeyClass : passiveKeyClass}`}
                    style={{
                        margin: "auto",
                        fontWeight: "bold",
                        padding: "11px 11px",
                        fontSize: 20,
                        width: '27%'
                    }}
                    onClick={() => {
                        navigate('/search/?query=0-9')
                        window.location.reload()
                        window.scrollTo({
                            top: -1,
                            left: -1,
                            behavior: 'smooth',
                        })
                    }}
                >0-9</Button>
            </div>
        </div>          
    )
}

export default Keyboard;