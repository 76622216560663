import React from "react";
import { colors } from 'assets/js/theme';
import { useNavigate } from 'react-router-dom';
import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/avatars-identicon-sprites';
import SVG from 'react-inlinesvg';

// backend components
import {
    signOut
} from "shared/request"

import {
    NavItem,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap";

export default function ProfileButton({ username, setLogSuccess }) {
    const navigate = useNavigate()
    const lightMode = localStorage.getItem("theme") === "light";
    const [logColor, setLogColor] = React.useState("transparent")
    const [logFont, setLogFont] = React.useState("#28a745")
    const bgColor = lightMode ? colors.white : colors.darkGray
    const borderColor = lightMode ? colors.darkGray : colors.gray
    const fontColor = lightMode ? colors.darkGray : colors.white
    const [ddColor1, setddColor1] = React.useState('#6c757d')
    const [ddColor3, setddColor3] = React.useState('#6c757d')
    var addRule = (function (style) {
        var sheet = document.head.appendChild(style).sheet;
        return function (selector, css) {
            var propText = typeof css === "string" ? css : Object.keys(css).map(function (p) {
                return p + ":" + (p === "content" ? "'" + css[p] + "'" : css[p]);
            }).join(";");
            sheet.insertRule(selector + "{" + propText + "}", sheet.cssRules.length);
        };
    })(document.createElement("style"));

    addRule('svg.onlyme > path, svg.onlyme2 > rect', {
        fill: logFont,
        transition: "all 0.3s ease"
    })

    async function logout() {
        await signOut().then((res) => {
            localStorage.removeItem("authorization")
            setLogSuccess(true)
            setTimeout(() => {
                navigate('/home')
            }, 1000)
        })
    }

    return (
        <NavItem>
            <UncontrolledDropdown nav className={"animateme"}>
                <DropdownToggle
                    className={"animateme"}
                    caret
                    color="default"
                    nav
                    onClick={(e) => e.preventDefault()}
                    style={{
                        fontSize: 20,
                        border: `1px solid #28a745`,
                        borderRadius: "30px",
                        color: logFont,
                        padding: "10px 22px",
                        backgroundColor: logColor,
                        textTransform: "none"
                    }}
                    onMouseEnter={() => { setLogColor("#28a745"); setLogFont(colors.white) }}
                    onMouseLeave={() => { setLogColor("transparent"); setLogFont("#28a745") }}
                >
                    {username.toLowerCase() === "gyulism" ?
                        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                            className={"onlyme2"} style={{ marginRight: 20 }}>
                            <rect fill="#ff0000" x="12" y="0.07299" width="4" height="4" />
                            <rect fill="#ff0000" x="4" y="0.07299" width="4" height="4" />
                            <rect fill="#ff0000" x="8" y="15.85019" width="4" height="4" />
                            <rect height="8" width="20" y="4" x="0" fill="#ff0000" />
                            <rect height="4" width="12" y="11.92509" x="4" fill="#ff0000" />
                        </svg>
                        :
                        <SVG src={createAvatar(style, {
                            seed: username
                        })} style={{
                            width: 20,
                            height: 20,
                            marginRight: 20
                        }} className={"onlyme"} />
                    }
                    {username.toLowerCase()}
                </DropdownToggle>
                <DropdownMenu right
                    className={"animateme"}
                    style={{
                        backgroundColor: bgColor,
                        marginTop: 10,
                        border: `1px solid ${borderColor}`,
                        borderRadius: "20px",
                        width: "100%"
                    }}>
                    <DropdownItem
                        className={"animateme"}
                        onMouseEnter={() => { setddColor1(fontColor) }}
                        onMouseLeave={() => { setddColor1("#6c757d") }}
                        style={{ color: ddColor1, cursor: "pointer", backgroundColor: bgColor }}
                        onClick={() => {
                            navigate(`/profile`)
                        }}><h6>Hesaba Keç</h6></DropdownItem>
                    <DropdownItem
                        className={"animateme"}
                        onMouseEnter={() => { setddColor3(fontColor) }}
                        onMouseLeave={() => { setddColor3("#6c757d") }}
                        style={{ color: ddColor3, cursor: "pointer", backgroundColor: bgColor, marginBottom: 7 }}
                        onClick={() => {
                            logout()
                        }}> <h6>Hesabdan Çıx</h6></DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </NavItem>
    )
}