import React from "react";
import { colors } from 'assets/js/theme';
import Identicon from "components/SVG/Identicon";

import {
    Button
} from "reactstrap";

export default function MobileHeader({ setShow, username, notificationsCount, totalCount }) {
    const lightMode = localStorage.getItem("theme") === "light"
    const borderColor = lightMode ? colors.darkGray : colors.lightGray
    const bgColor = lightMode ? colors.white : colors.darkGray
    return (
        <div className="animateme" style={{
            borderRadius: "30px",
            backgroundColor: borderColor,
            width: "80%",
            margin: '35px 0 50px 10%',
            paddingBottom: 10
        }}>
            <div
                className='animateme'
                style={{
                    height: "115px",
                    paddingTop: 20
                }}>
                <Identicon username={username} />
            </div>
            <h3
                className={"animateme notme"}
                style={{
                    color: colors.white,
                    margin: 0,
                    padding: "15px 0",
                    textAlign: "center",
                    fontFamily: 'Montserrat sans-serif',
                    fontWeight: 400,
                    color: bgColor,
                }}>@{username.toUpperCase()}
            </h3>
            <h4
                className={"animateme"}
                style={{
                    color: colors.white,
                    margin: 0,
                    paddingBottom: 10,
                    textAlign: "center",
                    color: bgColor,
                }}>Məqalələrin Sayı: {totalCount}
            </h4>
            <Button
                className={`btn-round button ${notificationsCount > 0 ? 'success' : 'danger'} animateme`}
                color={`${notificationsCount > 0 ? 'success' : 'danger'}`}
                outline
                type="button"
                style={{
                    width: "80%",
                    marginLeft: "10%",
                    fontSize: 20
                }}
                onClick={(e) => {
                    notificationsCount > 0 ? setShow(true) : e.preventDefault()
                }}
            >
                {`Bildirişlər (${notificationsCount})`}
            </Button>
        </div>
    )
}