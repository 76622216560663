import * as React from "react";

export const UKFlag = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    className="iconify iconify--twemoji"
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      fill="#00247D"
      d="M0 9.059V13h5.628zM4.664 31H13v-5.837zM23 25.164V31h8.335zM0 23v3.941L5.63 23zM31.337 5H23v5.837zM36 26.942V23h-5.631zM36 13V9.059L30.371 13zM13 5H4.664L13 10.837z"
    />
    <path
      fill="#CF1B2B"
      d="M25.14 23l9.712 6.801a3.977 3.977 0 0 0 .99-1.749L28.627 23H25.14zM13 23h-2.141l-9.711 6.8c.521.53 1.189.909 1.938 1.085L13 23.943V23zm10-10h2.141l9.711-6.8a3.988 3.988 0 0 0-1.937-1.085L23 12.057V13zm-12.141 0L1.148 6.2a3.994 3.994 0 0 0-.991 1.749L7.372 13h3.487z"
    />
    <path
      fill="#EEE"
      d="M36 21H21v10h2v-5.836L31.335 31H32a3.99 3.99 0 0 0 2.852-1.199L25.14 23h3.487l7.215 5.052c.093-.337.158-.686.158-1.052v-.058L30.369 23H36v-2zM0 21v2h5.63L0 26.941V27c0 1.091.439 2.078 1.148 2.8l9.711-6.8H13v.943l-9.914 6.941c.294.07.598.116.914.116h.664L13 25.163V31h2V21H0zM36 9a3.983 3.983 0 0 0-1.148-2.8L25.141 13H23v-.943l9.915-6.942A4.001 4.001 0 0 0 32 5h-.663L23 10.837V5h-2v10h15v-2h-5.629L36 9.059V9zM13 5v5.837L4.664 5H4a3.985 3.985 0 0 0-2.852 1.2l9.711 6.8H7.372L.157 7.949A3.968 3.968 0 0 0 0 9v.059L5.628 13H0v2h15V5h-2z"
    />
    <path fill="#CF1B2B" d="M21 15V5h-6v10H0v6h15v10h6V21h15v-6z" />
  </svg>
);

export const AzFlag = ({ size }) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      role="img"
      className="iconify iconify--twemoji"
      preserveAspectRatio="xMidYMid meet"
    >
      <path fill="#E00034" d="M0 13h36v10H0z" />
      <path fill="#0098C3" d="M32 5H4a4 4 0 0 0-4 4v4h36V9a4 4 0 0 0-4-4z" />
      <g fill="#FFF">
        <path d="M17.844 21.333a3.333 3.333 0 1 1 2.475-5.565a4 4 0 1 0 .001 4.464a3.325 3.325 0 0 1-2.476 1.101z" />
        <path d="M23.667 17.998l-1.196-.424l.544-1.146l-1.146.545l-.426-1.195l-.424 1.196l-.003-.002l-1.144-.542l.546 1.146l-1.196.426l1.196.424l-.544 1.146l1.141-.543l.005-.002l.426 1.195l.424-1.196l1.147.544l-.546-1.146z" />
      </g>
      <path fill="#00AE65" d="M4 31h28a4 4 0 0 0 4-4v-4H0v4a4 4 0 0 0 4 4z" />
    </svg>
);

export const Magnifier = () => (
    <svg
      width="25px"
      height="25px"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      role="img"
      className="iconify iconify--twemoji"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#9AAAB4"
        d="M13.503 19.693l2.828 2.828l-4.95 4.95l-2.828-2.829z"
      />
      <path
        fill="#66757F"
        d="M1.257 29.11l5.88-5.879a2 2 0 0 1 2.828 0l2.828 2.828a2 2 0 0 1 0 2.828l-5.879 5.879a4 4 0 1 1-5.657-5.656z"
      />
      <circle fill="#8899A6" cx={22.355} cy={13.669} r={13.5} />
      <circle fill="#BBDDF5" cx={22.355} cy={13.669} r={9.5} />
    </svg>
);

export const ScreamingFace = () => (
    <svg
      width="25px"
      height="25px"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      role="img"
      className="iconify iconify--twemoji"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#FFCC4D"
        d="M18 0C8.06 0 0 8.06 0 18c0 6.051 2.996 11.392 7.574 14.655C7.678 26.217 5.337 19 1.404 19c-.464 0-.84.066-1.153.183C.554 18.426 1.211 18 2.44 18c4.27 0 6.666 8.506 6.092 15.288A17.89 17.89 0 0 0 18 36c3.479 0 6.716-1.004 9.47-2.713C26.893 26.48 29.288 18 33.561 18c1.233 0 1.892.426 2.192 1.183c-.314-.117-.691-.183-1.157-.183c-3.938 0-6.278 7.199-6.17 13.655C33.005 29.392 36 24.051 36 18c0-9.94-8.059-18-18-18z"
      />
      <path
        fill="#BDDDF4"
        d="M18 0C8.06 0 0 8.06 0 18c0 1.192.123 2.356.344 3.484c.234-.863.6-1.691 1.058-2.484c-.463 0-.838.066-1.15.183c.264-.659.822-1.048 1.768-1.142C5.012 13.862 11.037 11 18 11c6.964 0 12.988 2.861 15.98 7.04c.95.094 1.51.482 1.772 1.143c-.312-.117-.689-.183-1.153-.183a10.49 10.49 0 0 1 1.058 2.484C35.878 20.356 36 19.192 36 18c0-9.94-8.059-18-18-18z"
      />
      <path
        fill="#F5F8FA"
        d="M7.347 11.91c-.946 3.176.107 6.293 2.353 6.962c2.246.67 4.834-1.362 5.779-4.538c.946-3.175-.106-6.293-2.351-6.962c-2.246-.669-4.834 1.363-5.781 4.538zm21.305 0c.946 3.176-.107 6.293-2.352 6.962c-2.246.67-4.834-1.362-5.779-4.538c-.946-3.175.107-6.293 2.351-6.962c2.245-.669 4.833 1.363 5.78 4.538z"
      />
      <path
        fill="#664500"
        d="M18 18c-2.757 0-5 2.243-5 5v6c0 2.757 2.243 5 5 5s5-2.243 5-5v-6c0-2.757-2.243-5-5-5z"
      />
      <path
        fill="#FCAB40"
        d="M1.404 19c-.464 0-.84.066-1.153.183c.072-.179.167-.336.281-.476C-1.33 20.998 2.849 28.54.818 36h6.46c1.19-6.96-1.235-17-5.874-17zm34.349.183c-.314-.117-.691-.183-1.157-.183c-4.641 0-7.065 10.002-5.873 17h6.46c-1.906-7.045 1.656-14.089.57-16.817z"
      />
    </svg>
);

export const Pin = () => (
    <svg
      width="25px"
      height="25px"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      role="img"
      className="iconify iconify--twemoji"
      preserveAspectRatio="xMidYMid meet"
    >
      <ellipse fill="#292F33" cx={18} cy={34.5} rx={4} ry={1.5} />
      <path
        fill="#99AAB5"
        d="M14.339 10.725S16.894 34.998 18.001 35c1.106.001 3.66-24.275 3.66-24.275h-7.322z"
      />
      <circle fill="#DD2E44" cx={18} cy={8} r={8} />
    </svg>
);

export const ThinkingFace = () => (
    <svg
      width="55px"
      height="55px"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      role="img"
      className="iconify iconify--twemoji"
      preserveAspectRatio="xMidYMid meet"
    >
      <circle fill="#FFCB4C" cx={18} cy={17.018} r={17} />
      <path
        fill="#65471B"
        d="M14.524 21.036a.914.914 0 0 1-.312-.464a.799.799 0 0 1 .59-1.021c4.528-1.021 7.577 1.363 7.706 1.465c.384.306.459.845.173 1.205c-.286.358-.828.401-1.211.097c-.11-.084-2.523-1.923-6.182-1.098a.91.91 0 0 1-.764-.184z"
      />
      <ellipse fill="#65471B" cx={13.119} cy={11.174} rx={2.125} ry={2.656} />
      <ellipse fill="#65471B" cx={24.375} cy={12.236} rx={2.125} ry={2.656} />
      <path
        fill="#F19020"
        d="M17.276 35.149s1.265-.411 1.429-1.352c.173-.972-.624-1.167-.624-1.167s1.041-.208 1.172-1.376c.123-1.101-.861-1.363-.861-1.363s.97-.4 1.016-1.539c.038-.959-.995-1.428-.995-1.428s5.038-1.221 5.556-1.341c.516-.12 1.32-.615 1.069-1.694c-.249-1.08-1.204-1.118-1.697-1.003c-.494.115-6.744 1.566-8.9 2.068l-1.439.334c-.54.127-.785-.11-.404-.512c.508-.536.833-1.129.946-2.113c.119-1.035-.232-2.313-.433-2.809c-.374-.921-1.005-1.649-1.734-1.899c-1.137-.39-1.945.321-1.542 1.561c.604 1.854.208 3.375-.833 4.293c-2.449 2.157-3.588 3.695-2.83 6.973c.828 3.575 4.377 5.876 7.952 5.048l3.152-.681z"
      />
      <path
        fill="#65471B"
        d="M9.296 6.351a.925.925 0 0 1-.391-.399a.8.8 0 0 1 .393-1.112c4.266-1.831 7.699-.043 7.843.034c.433.231.608.747.391 1.154c-.216.405-.74.546-1.173.318c-.123-.063-2.832-1.432-6.278.047a.915.915 0 0 1-.785-.042zm12.135 3.75a.924.924 0 0 1-.362-.424a.8.8 0 0 1 .468-1.084c4.381-1.536 7.685.48 7.823.567c.415.26.555.787.312 1.178c-.242.39-.776.495-1.191.238c-.12-.072-2.727-1.621-6.267-.379a.924.924 0 0 1-.783-.096z"
      />
    </svg>
);

export const PartyPoppers = () => (
    <svg
      width="25px"
      height="25px"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      role="img"
      className="iconify iconify--twemoji"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#DD2E44"
        d="M11.626 7.488a1.413 1.413 0 0 0-.268.395l-.008-.008L.134 33.141l.011.011c-.208.403.14 1.223.853 1.937c.713.713 1.533 1.061 1.936.853l.01.01L28.21 24.735l-.008-.009c.147-.07.282-.155.395-.269c1.562-1.562-.971-6.627-5.656-11.313c-4.687-4.686-9.752-7.218-11.315-5.656z"
      />
      <path
        fill="#EA596E"
        d="M13 12L.416 32.506l-.282.635l.011.011c-.208.403.14 1.223.853 1.937c.232.232.473.408.709.557L17 17l-4-5z"
      />
      <path
        fill="#A0041E"
        d="M23.012 13.066c4.67 4.672 7.263 9.652 5.789 11.124c-1.473 1.474-6.453-1.118-11.126-5.788c-4.671-4.672-7.263-9.654-5.79-11.127c1.474-1.473 6.454 1.119 11.127 5.791z"
      />
      <path
        fill="#AA8DD8"
        d="M18.59 13.609a.99.99 0 0 1-.734.215c-.868-.094-1.598-.396-2.109-.873c-.541-.505-.808-1.183-.735-1.862c.128-1.192 1.324-2.286 3.363-2.066c.793.085 1.147-.17 1.159-.292c.014-.121-.277-.446-1.07-.532c-.868-.094-1.598-.396-2.11-.873c-.541-.505-.809-1.183-.735-1.862c.13-1.192 1.325-2.286 3.362-2.065c.578.062.883-.057 1.012-.134c.103-.063.144-.123.148-.158c.012-.121-.275-.446-1.07-.532a.998.998 0 0 1-.886-1.102a.997.997 0 0 1 1.101-.886c2.037.219 2.973 1.542 2.844 2.735c-.13 1.194-1.325 2.286-3.364 2.067c-.578-.063-.88.057-1.01.134c-.103.062-.145.123-.149.157c-.013.122.276.446 1.071.532c2.037.22 2.973 1.542 2.844 2.735c-.129 1.192-1.324 2.286-3.362 2.065c-.578-.062-.882.058-1.012.134c-.104.064-.144.124-.148.158c-.013.121.276.446 1.07.532a1 1 0 0 1 .52 1.773z"
      />
      <path
        fill="#77B255"
        d="M30.661 22.857c1.973-.557 3.334.323 3.658 1.478c.324 1.154-.378 2.615-2.35 3.17c-.77.216-1.001.584-.97.701c.034.118.425.312 1.193.095c1.972-.555 3.333.325 3.657 1.479c.326 1.155-.378 2.614-2.351 3.17c-.769.216-1.001.585-.967.702c.033.117.423.311 1.192.095a1 1 0 1 1 .54 1.925c-1.971.555-3.333-.323-3.659-1.479c-.324-1.154.379-2.613 2.353-3.169c.77-.217 1.001-.584.967-.702c-.032-.117-.422-.312-1.19-.096c-1.974.556-3.334-.322-3.659-1.479c-.325-1.154.378-2.613 2.351-3.17c.768-.215.999-.585.967-.701c-.034-.118-.423-.312-1.192-.096a1 1 0 1 1-.54-1.923z"
      />
      <path
        fill="#AA8DD8"
        d="M23.001 20.16a1.001 1.001 0 0 1-.626-1.781c.218-.175 5.418-4.259 12.767-3.208a1 1 0 1 1-.283 1.979c-6.493-.922-11.187 2.754-11.233 2.791a.999.999 0 0 1-.625.219z"
      />
      <path
        fill="#77B255"
        d="M5.754 16a1 1 0 0 1-.958-1.287c1.133-3.773 2.16-9.794.898-11.364c-.141-.178-.354-.353-.842-.316c-.938.072-.849 2.051-.848 2.071a1 1 0 1 1-1.994.149c-.103-1.379.326-4.035 2.692-4.214c1.056-.08 1.933.287 2.552 1.057c2.371 2.951-.036 11.506-.542 13.192a1 1 0 0 1-.958.712z"
      />
      <circle fill="#5C913B" cx={25.5} cy={9.5} r={1.5} />
      <circle fill="#9266CC" cx={2} cy={18} r={2} />
      <circle fill="#5C913B" cx={32.5} cy={19.5} r={1.5} />
      <circle fill="#5C913B" cx={23.5} cy={31.5} r={1.5} />
      <circle fill="#FFCC4D" cx={28} cy={4} r={2} />
      <circle fill="#FFCC4D" cx={32.5} cy={8.5} r={1.5} />
      <circle fill="#FFCC4D" cx={29.5} cy={12.5} r={1.5} />
      <circle fill="#FFCC4D" cx={7.5} cy={23.5} r={1.5} />
    </svg>
);

export const GoogleMeet = ({ size }) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M24,21.45V25a2.0059,2.0059,0,0,1-2,2H9V21h9V16Z" fill="#00ac47" />
      <polygon fill="#31a950" points="24 11 24 21.45 18 16 18 11 24 11" />
      <polygon fill="#ea4435" points="9 5 9 11 3 11 9 5" />
      <rect fill="#4285f4" height={11} width={6} x={3} y={11} />
      <path
        d="M24,7v4h-.5L18,16V11H9V5H22A2.0059,2.0059,0,0,1,24,7Z"
        fill="#ffba00"
      />
      <path d="M9,21v6H5a2.0059,2.0059,0,0,1-2-2V21Z" fill="#0066da" />
      <path
        d="M29,8.26V23.74a.9989.9989,0,0,1-1.67.74L24,21.45,18,16l5.5-5,.5-.45,3.33-3.03A.9989.9989,0,0,1,29,8.26Z"
        fill="#00ac47"
      />
      <polygon fill="#188038" points="24 10.55 24 21.45 18 16 23.5 11 24 10.55" />
    </svg>
);